$.fn.toTop = function (parametre) {
	var $obj = $(this)
	var params = $.extend({
		speed : 800,
		minWidth : 1024,
		elemBotAlign : null
	}, parametre);
	var bodyTop = $(window).scrollTop();
	var windowH = $(window).height();
	var windowW = $(window).width();
	var objW = $obj.width();
	var objH = $obj.height();
	var botObj = $(params.elemBotAlign);
	var objDetach = $obj.detach();
	$('body').append(objDetach);
	$obj.hide();
	function mainAction() {
		windowW = $(window).width();
		objW = $obj.width();
		bodyTop = $(window).scrollTop();
		if (params.minWidth != 0) {
			if (windowW > params.minWidth + objW) {
				if (bodyTop > 0) {
					$obj.fadeIn();
				} else {
					$obj.fadeOut();
				}
			} else {
				$obj.css({ display : 'block' });
			}
		} else {
			if (bodyTop > 0) {
				$obj.fadeIn();
			} else {
				$obj.fadeOut();
			}
		}
		if (botObj != null && botObj.length == 1) {
			var botObjPos = botObj.offset();
			if (botObjPos.top < windowH + bodyTop) {
				$obj.css({ position : 'absolute', top : botObjPos.top - objH, bottom : '' });
			} else {
				$obj.css({ position : 'fixed', top : '', bottom : 0 });
			}
		}
	}
	mainAction();
	$(window).bind({
		scroll : function () {
			mainAction();
		},
		resize : function () {
			mainAction();
		}
	});
	$(document).ajaxComplete(function () {
		mainAction();
	});
	$obj.click(function () {
		var speed = params.speed;
		var pageH = $(window).scrollTop();
		var timer = pageH / (speed / 100);
		$('body,html').animate({scrollTop : 0}, timer);
	});
};
