/* FormCheck Class qui permet de valider le contenu d'un formulaire
 * Exemple utilisation wshop-back/view/store/edit/store_edit.php

 * Mettre data-required sur le input/textarea/select pour rendre le champs obligatoire
 * Mettre data-rexexp="[formatFormCheck]" et choisir un formatFormCheck (key) pour le définir (exemple : data-rexexp="alpha")

 * Pour les checkbox (obligatoire) il faut les englober avec <div class="checkbox-container"></div>
 * Possibilite de mettre plusieurs formulaires. Les initialiser avec le code suivant :
 *
var form_store;
$(document).ready(function() {
    form_store = FormCheck;
    form_store.init('#storeForm');
});
*/

var formatFormCheck = [];
formatFormCheck['alpha'] = '^[a-zA-Z\u00C0-\u017F\' |-]+$';
formatFormCheck['num'] = '^[0-9]+$';
formatFormCheck['num_ext'] = '^[0-9_-]+$'; // Le numero mais avec des caractere supplementaire
formatFormCheck['telephone_fr'] = '^[0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2} [0-9]{2}$';
formatFormCheck['code_postal_fr'] = '^[0-9]{5}$';
formatFormCheck['email'] = '^[a-z0-9]+([_|\.|-]{1}[a-z0-9]+)*@[a-z0-9]+([_|\.|-]{1}[a-z0-9]+)*[\.]{1}[a-z]{2,6}$';
formatFormCheck['HH:MM'] = '^(2[0-3]|1[0-9]|0[0-9]):([0-5][0-9])$';
formatFormCheck['DD/MM/YYYY'] = '^(3[0-1]|2[0-9]|1[0-9]|0[1-9])/(1[0-2]|0[1-9])/([0-9]{4})$';
formatFormCheck['relay'] = '^[0-9]{6}$';


// Pour verifier le formulaire
var FormCheck = {

    options: {
        'alert-box': '' // Le renseigner avec la class (ou l'id) de la box d'erreur (exemple : '.lightbox_error_rapport')
    },

    init: function(form_id, options) {

        var self = this;

        this.form_id = form_id;
        // Attribution des options pour les options de l'objet
        jQuery.extend(this.options, options);

        $(this.form_id+' input[type=text], '+this.form_id+' textarea, ' + this.form_id+' input[type=email], '+this.form_id+' select').each(function(){
            var element = $(this);
            if(element.data('required') == true || element.data('regexp') != undefined){
                element.on({
                    blur: function() {
                        self.checkField(element);
                    },
                    keyup: function() {
                        self.checkField(element);
                    }
                });

                if($(form_id).hasClass('errorForm')) {
                    self.checkField(element);
                }
            }
        });

        // Les radios/checkbox (par defaut des que la personne click on desactive l'erreur)
        $(this.form_id+' input[type=radio], '+this.form_id+' input[type=checkbox]').each(function(){
            var element = $(this);
            if(element.data('required') == true || element.data('regexp') != undefined){
                element.on({
                    click: function() {
                        self.checkFieldRadioCheckbox(element);
                    }
                });
            }
        });
    },

    closeBoxError: function () {
        $('.lightbox_error_rapport').slideUp();
        $('#shad').hide();
    },

    // Par defaut des que la personne click on desactive l'erreur. Il y aura une verification au submit
    checkFieldRadioCheckbox: function (element) {
        element.parents('.checkbox-container').removeClass('inputErr');
        element.parents('.checkbox-container').siblings('.img_error').remove();

        return true;
    },

    checkField: function (element) {
        var dataReg = $(element).data('regexp');
        if (element.val().trim().length == 0 && element.data('required') == true) {
            element.addClass('inputErr');
            element.after('<img style="margin: 2px 0 0 10px" src="'+path_relative_root+'img/picto_panneau.png" alt="" class="img_error">');

            return false;
        } else if (element.data('regexp') != undefined && element.val().trim().length > 0){
            if (dataReg != undefined) {
                if (dataReg == 'telephone_autocomplete') {
                    /*
                     * Doit toujours avoir un champs en hidden (qui transmet la bonne valeur au php)
                     * $(objElement).intlTelInput("getNumberType") == 0 pour un fixe et $(objElement).intlTelInput("getNumberType") == 1 pour un mobile
                     */
                    if ( element.intlTelInput("isValidNumber") === false ) {
                        element.addClass('inputErr');
                        element.after('<img style="margin: 2px 0 0 10px" src="'+path_relative_root+'img/picto_panneau.png" alt="" class="img_error">');

                        return false;
                    } else {
                        $('#'+element.attr('id')+'_hidden').val(element.intlTelInput("getNumber"));
                    }
                } else {
                    var reg_exp = new RegExp(formatFormCheck[dataReg],'gi');
                    // verification du formatage
                    if (!reg_exp.test(element.val())) {
                        element.addClass('inputErr');
                        element.after('<img style="margin: 2px 0 0 10px" src="'+path_relative_root+'img/picto_panneau.png" alt="" class="img_error">');

                        return false;
                    }
                }
            }
        } else if (dataReg != undefined) {
            if (dataReg == 'telephone_autocomplete') {
                $('#'+element.attr('id')+'_hidden').val('');
            }
        }
        element.removeClass('inputErr');
        element.siblings('.img_error').remove();

        return true;
    },

    submitCheck: function (form, evt) {

        var self = this;
        var error_txt = [];

        var checkG = true;
        $(this.form_id+' input[type=text], '+this.form_id+' textarea, ' + this.form_id+' input[type=email], '+this.form_id+' select').each(function() {
            if ($(this).data('required') == true) {
                check = self.checkField($(this));
                if ( typeof $(this).data('error') != 'undefined' && !check )
                    error_txt.push($(this).data('error'));
                if (checkG)
                    checkG = check;
            }
        });

        $(this.form_id+' input[type=file]').each(function() {
            if ($(this).data('required') == true) {
                if($(this).val()==='') {
                    $(this).addClass('inputErr');
                    $(this).after('<img style="margin: 2px 0 0 10px" src="'+path_relative_root+'img/picto_panneau.png" alt="" class="img_error">');
                } else {
                    $(this).removeClass('inputErr');
                    $(this).parent().siblings('.img_error').remove();
                }
            }
        });

        var groupCheck = [];
        // Il y a un probleme pour le $.each quand on met des key en string donc on vas inscrementer les key en fonction du name precedent et le name courant
        var name_cur = name_prev = '';
        var i = -1;
        // La verification des bouton radio se fait uniquement dans le submit
        $(this.form_id+' input[type=radio], '+this.form_id+' input[type=checkbox]').each(function() {
            if ($(this).data('required') == true) {
                name_cur = $(this).attr('name');

                // Si le nom courant est different du precedent alors on inscremente
                if (name_cur != name_prev) {
                    i++ ;
                    name_prev = name_cur;
                    groupCheck[i] = {'checked': [], 'parent': []};
                }
                // On prend comme cle le name des radio/chekbox pour les mettre en groupe
                if ($(this).prop('checked')) {
                    groupCheck[i].checked = true;
                    groupCheck[i].parent = $(this).parents('.checkbox-container');
                }
                else if (groupCheck[i].checked != true) { // On regarde si dans le groupe il n'y a pas deja une checkbox de coche
                    groupCheck[i].checked = false;
                    groupCheck[i].parent = $(this).parents('.checkbox-container');
                }
            }
        });


        $.each(groupCheck, function(index, value) {

            // On parcourt tous les groupes de checkbox
            if (value.checked == false) {
                checkG = false;
                value.parent.addClass('inputErr');
                value.parent.after('<img style="margin: 2px 0 0 10px" src="'+path_relative_root+'img/picto_panneau.png" alt="" class="img_error">');

            } else {
                value.parent.removeClass('inputErr');
                value.parent.siblings('.img_error').remove();
            }
        });

        if (checkG) {
            return true;
        }

        return false;
    }
}


var form;
$(function(){
    if ( $('#service').length > 0 ) {
        form = FormCheck;
        form.init('#service');
    }

    $(document).on('change', '#question_contact', function(){
        form = FormCheck;
        form.init('#service');
    });
});
