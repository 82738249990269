alert = (function() {
    "use strict";

    var shade, albox, alert_tmpl;
    var regex = new RegExp("\\s", "g");

    function init() {

        shade = document.getElementById("shad");
        albox = document.getElementById("abox");

        alert_tmpl = albox.innerHTML.trim();
        window.removeEventListener("load", init);
    }

    function closeAlertBox() {

        $(albox).removeClass("actif");
        $(shade).removeClass("actif");
    }

    function alert(str) {

        $(".txt_alert", albox).html(str);
        $(".closeBtn", albox).on("click", closeAlertBox);
        $(".close_pop", albox).on("click", closeAlertBox);
        $(shade).one("click", closeAlertBox);
        $(shade).addClass("actif");
        $(albox).addClass("actif");
    }

    window.addEventListener("load", init);

    return alert;
})();
