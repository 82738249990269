/**
 *  New functions (they are supposed to be at least a little bit cleaner than legacy code)
 */

/**
 * Simply check if "element" is in viewport
 */
function isInViewport(element) {
    if ($(element).length) {
        var elementTop = $(element).offset().top;
        var elementBottom = elementTop + $(element).outerHeight();
        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();
        return elementBottom > viewportTop && elementTop < viewportBottom;
    }
}

// Emulates placeholder behavior
// Also cleans trailing whitespaces in value
function checkPlaceholder(evt) {

    var dat = $(this).data('placeholder'),
        val = this.value.trim();

    if (evt.type === 'focusin' && val === dat) {

        this.value = '';
    } else if (evt.type = 'focusout') {

        if (val === '') {

            this.value = dat;
        } else {

            this.value = val;
        }
    }
}

// Below lies the legacy code (enter at your own risk)
function checkdate(d, m, y) {
    return m > 0 && m < 13 && y > 0 && y < 32768 && d > 0 && d <= (new Date(y, m, 0)).getDate();
}
var nav_UA = window.navigator.userAgent.split('/'),
    nav_ver = parseFloat(nav_UA.pop()),
    nav_name = nav_UA.pop();

var id_select = '';

// Affiche un message d'erreur
function ErreurMsg(idObj, msg) {
    var message = document.getElementById(idObj);
    message.style.color = 'red';
    message.innerHTML = msg;
}
// -----------------------------------------------------------------

// D�finit les classes initiales pour les elements d'un formulaire
function setInitialClassName(elementIdArray) {
    for (i = 0; i < elementIdArray.length; i++) {
        var elementInput = document.getElementById(elementIdArray[i]);
        var elementLabel = document.getElementById(elementIdArray[i] + 'Label');
        if (elementInput && elementInput.type != 'hidden') {
            elementInput.className = 'inputForm';
            if (elementLabel)
                elementLabel.className = 'labelForm';
        }
    }
}

// D�finit les classes initiales pour les elements de date d'un formulaire
function setInitialDateClassName(elementIdArray) {
    for (i = 0; i < elementIdArray.length; i++) {
        var elementInputDay = document.getElementById(elementIdArray[i] + 'J');
        var elementInputMonth = document.getElementById(elementIdArray[i] + 'M');
        var elementInputYear = document.getElementById(elementIdArray[i] + 'A');
        var elementLabel = document.getElementById(elementIdArray[i] + 'Label');
        if (elementInputDay && elementInputDay.type != 'hidden' &&
            elementInputMonth && elementInputMonth.type != 'hidden' &&
            elementInputYear && elementInputYear.type != 'hidden') {
            elementInputDay.className = 'inputForm';
            elementInputMonth.className = 'inputForm';
            elementInputYear.className = 'inputForm';
            if (elementLabel)
                elementLabel.className = 'labelForm';
        }
    }
}

// V�rification d'un bouton radio
function verif_radio(elementID, fieldname) {
    if (elementID == 'clientCiviliteM' || elementID == 'clientCiviliteMme' || elementID == 'clientCiviliteMlle')
        elementID = 'clientCivilite';
    var objElement = document.getElementsByName(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    var trouve = false;
    $(objElement).removeData('data-error-text').removeAttr('data-error-text');
    if (elementID) {
        objElement.className = 'inputForm';
        objLabel.className = 'labelForm';
        $("input[name='" + elementID + "']").each(function (idx) {
            if ($(this).is(':checked') == true) {
                objElement.className = 'inputForm';
                objLabel.className = 'labelForm';
                trouve = true;
            }
        });
        if (!trouve) {

            if (elementID == 'clientCivilite') {
                t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_error_civilite&arg2=' + fieldname);
            } else {
                t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_error_thanks&arg2=' + fieldname);
            }

            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        }
    }
    return true;
}

function verif_price(e, t) {
    var a = document.getElementById(e),
        r = document.getElementById(e + "Label"),
        l = (document.getElementById("mes_err_fieldset"), document.getElementById("erreur_msg"));
    if ($(a).removeData("data-error-text").removeAttr("data-error-text"), "" == a.value) return t_error = '<p class="title_toolltip">' + ajax_file("ajax_sprintf.php?arg1=" + t) + " :</p>" + ajax_file("ajax_sprintf.php?arg1=js_thanks_precise&arg2=" + t), $(a).data("data-error-text", t_error), l.innerHTML = t_error, a.className = "inputErr", r.className = "labelErr", !1;
    var n = validate_price(a.value);
    return 0 == n ? (t_error = '<p class="title_toolltip">' + ajax_file("ajax_sprintf.php?arg1=" + t) + " :</p>" + ajax_file("ajax_sprintf.php?arg1=js_thanks_precise&arg2=" + t), $(a).data("data-error-text", t_error), l.innerHTML = t_error, a.className = "inputErr", r.className = "labelErr", !1) : (a.className = "inputForm", r.className = "labelForm", !0)
}

function validate_price(e) {
    return /^[\d\.,]+$/.test(e)
}

// V�rificartion d'une case coch�
function verif_checkbox(elementName, fieldname) {
    var objElement = document.getElementsByName(elementName);
    var objLabel = document.getElementById(elementName.slice(0, -2) + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (elementName) {
        objElement.className = 'inputForm';
        objLabel.className = 'labelForm';
        for (i = 0; i < objElement.length; i++)
            if (objElement[i].checked == true) {
                objElement.className = 'inputForm';
                objLabel.className = 'labelForm';
                return true;
            }
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_thanks&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    }
    return true;
}

function verif_checkbox_index(elementName, fieldname, pIndex) {
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (document.prodForm.visuStatutDetail1) {
        if (document.prodForm.visuStatutDetail1[0].checked == true) {
            return true;
        }
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_photo_validate');
        return false;
    } else {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_photo_validate');
        return false;
    }
}

function verif_zip(elementZipID, elementCountryID, fieldname, nb) {
    var objZipElement = document.getElementById(elementZipID);
    var objCountryElement = document.getElementById(elementCountryID);
    var objLabel = document.getElementById(elementZipID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');

    var idPays = objZipElement.value.substr(0, 2);

    if (objCountryElement.value == "DO" && idPays != '97') {

        erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_error_zip_special_1&arg2=' + fieldname + '&arg3=97');
        $(objZipElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        return false;
    } else if (objCountryElement.value == "MO" && idPays != '98') {

        erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_error_zip_special_1&arg2=' + fieldname + '&arg3=98');
        $(objZipElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        return false;
    } else if (objCountryElement.value == "TO" && idPays != '97' && idPays != '98') {

        erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_error_zip_special_1&arg2=' + fieldname + '&arg3=97ou98');
        $(objZipElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        return false;
    } else if ((objCountryElement.value != "DO" && objCountryElement.value != "TO" && objCountryElement.value != "MO") && (idPays == '97' || (idPays == '98' && objCountryElement.value != "FR"))) {

        erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_error_zip_special_2&arg2=' + fieldname + '&arg3=' + idPays);
        $(objZipElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        return false;
    }

    $(objZipElement).removeClass('inputForm');
    $(objLabel).removeClass('labelErr');
    return true;
}

function verif_num(elementID, fieldname, nb) {
    var flg = 0;
    var num = "0123456789.,";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    var point = 0;
    var virgule = 0;

    if (objElement.value == "") {
        erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        $(objElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');

        return false;
    } else {
        if (objElement.value.length < nb) {
            erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_at_least_x_digits&arg2=color:red;&arg3=' + fieldname + '&arg4=' + nb);
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (num.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_wrong_input_num&arg2=' + fieldname);
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            flg = 0;
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (test == '.') {
                point = point + 1;
            }
            if (test == ',') {
                virgule = virgule + 1;
            }
        }
        var tot = point + virgule;
        if (point > 1 || virgule > 1 || tot > 1) {
            erreurText.innerHTML = erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_wrong_input_num&arg2=' + fieldname);
            $(objElement).addClass('inputErr');
            $(objLabel).addClass('labelErr');
            flg = 0;
            return false;
        }

    }
    $(objElement).removeClass('inputErr');
    $(objElement).removeClass('labelErr');
    return true;
}

function verif_portable(elementID, fieldname) {
    return verif_telephone(elementID, fieldname, true);
}

function verif_telephone(elementID, fieldname, is_mobile) {

    var objElementHidden = document.getElementById(elementID + 'Hidden');
    var objElement = document.getElementById(elementID);
    var erreurText = document.getElementById('erreur_msg');

    if (objElementHidden !== null) {

        if (elementID === 'telephone' && document.getElementById('mobile') === null) {

            var value = $(objElement).intlTelInput("getNumber");
            var type = $(objElement).intlTelInput("getNumberType");

            if (value.length == 12 && value.substr(1, 3) == '337' && is_mobile) {
                $('#clientMobileHidden').val(value);
            } else {
                if ($(objElement).intlTelInput("isValidNumber") !== false && (type === 0 || type === 3 || type === 6)) {
                    $(objElementHidden).val(value);
                } else if ($(objElement).intlTelInput("isValidNumber") !== false && type === 1) {
                    $('#mobileHidden').val(value);
                }

                if ($(objElement).intlTelInput("isValidNumber") === false) {
                    return false;
                }
            }

            return true;
        }
    }

    if ($.trim($(objElement).val()).length > 0) {
        var value = $(objElement).intlTelInput("getNumber");
        var type = $(objElement).intlTelInput("getNumberType");

        if (value.length == 12 && value.substr(1, 3) == '337') {
            $('#clientMobileHidden').val(value);
        } else {
            if ($(objElement).intlTelInput("isValidNumber") === false) {
                erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg2=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_error_phone&arg2=' + fieldname);
                return false;
            } else {
                if ($(objElement).intlTelInput("isValidNumber") !== false && (type === 0 || type === 3 || type === 6)) {
                    $(objElementHidden).val(value);
                } else if ($(objElement).intlTelInput("isValidNumber") !== false && type === 1) {
                    if (elementID === 'clientTel') {

                        $('#clientMobileHidden').val(value);
                    } else if (elementID == 'telephone') {

                        $('#mobileHidden').val(value);
                    } else if (elementID == 'billtelephone') {

                        $('#billmobileHidden').val(value);
                    } else if (elementID == 'candidatTel') {

                        $('#candidatTelHidden').val(value);
                    }
                }
            }
        }

        return true;
    }

    return false;
}

function verif_ref_interne(elementID, fieldname) {
    var flg = 0;
    var alphanum = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement.value == "") {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_thanks&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        objElement.focus();
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if ((((space_after + 1) == objElement.value.length) || ((space_before + 1) == 1)) && (objElement.value.length <= 9)) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=' + fieldname + '&arg3=9');
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        if (objElement.value.length < 9) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=9&arg3=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        if (objElement.value.length > 14) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_ref_14_chars&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);

            if (alphanum.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            flg = 0;
            return false;
        }
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verif_alphanum_codepromo(elementID, fieldname) {
    var flg = 0;
    var alphanum = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ\,\-_/\.<>()%:;";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement.value == "") {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_thanks&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        objElement.focus();
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if ((((space_after + 1) == objElement.value.length) || ((space_before + 1) == 1)) && (objElement.value.length <= 4)) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=' + fieldname + '&arg3=4');
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        if (objElement.value.length < 4) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=4&arg3=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);

            if (alphanum.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            flg = 0;
            return false;
        }
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verif_empty(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    $(objElement).removeData('data-error-text').removeAttr('data-error-text');

    if (objElement.value == "") {
        t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        erreurText.innerHTML = t_error;
        $(objElement).data('data-error-text', t_error);
        $(objElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        $(objElement).removeClass('inputForm');
        $(objLabel).removeClass('labelForm');
        return false;
    }
    $(objElement).addClass('inputForm');
    $(objLabel).addClass('labelForm');
    $(objElement).removeClass('inputErr');
    $(objLabel).removeClass('labelErr');
    return true;
}

function verif_alphanum(elementID, fieldname, isMin, length) {
    var flg = 0;
    var alphanum = decodeURIComponent("%C2%B0") + "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ\,\'\"\-_/\.<>\n\r()%:; " + decodeURIComponent("%C3%A2%C3%A0%C3%A4%C3%A9%C3%A8%C3%AB%C3%AA%C3%AC%C3%AF%C3%AE%C3%B6%C3%B4%C3%B2%C3%B9%C3%BC%C3%BB%C3%A7&%C3%84%C3%82%C3%80%C3%89%C3%88%C3%8B%C3%8A%C3%8C%C3%8F%C3%8E%C3%96%C3%94%C3%92%C3%99%C3%9C%C3%9B%C3%87");
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    $(objElement).removeData('data-error-text').removeAttr('data-error-text');
    if (objElement.value == "") {
        t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        $(objElement).data('data-error-text', t_error);
        erreurText.innerHTML = t_error;
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if ((((space_after + 1) == objElement.value.length) || ((space_before + 1) == 1)) && (objElement.value.length <= 3)) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=' + fieldname + '&arg3=3');
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        }
        if (isMin == false && objElement.value.length < 3) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=3&arg3=' + fieldname);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);

            if (alphanum.indexOf(test) < 0) {
                flg++;
            }
        }
        if (flg > 0) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=' + fieldname);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            flg = 0;
            return false;
        }
        if (isMin == true && objElement.value.length > length) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_too_long_alphanum&arg2=' + fieldname + '&arg3=' + length);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        }
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verif_alphanumadresse(elementID, fieldname, isMin, length) {
    var flg = 0;
    var alphanum = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ\,\'\"_/\.<>\n\r()%:; " + decodeURIComponent("%C3%A2%C3%A0%C3%A9%C3%A8%C3%AB%C3%AAi%C3%AF%C3%AE%C3%B6%C3%B4%C3%B9%C3%BC%C3%BB%C3%A7%26%C3%84%C3%82%C3%80%C3%89%C3%88%C3%8B%C3%8AI%C3%8F%C3%8E%C3%96%C3%94%C3%99%C3%9C%C3%9B%C3%87%3F!%E2%82%AC-");
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    $(objElement).removeData('data-error-text').removeAttr('data-error-text');
    if (objElement.value == "") {
        t_error = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        $(objElement).data('data-error-text', t_error);
        erreurText.innerHTML = t_error;
        objElement.className = 'inputErr';
        if (objLabel)
            objLabel.className = 'labelErr';
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if ((((space_after + 1) == objElement.value.length) || ((space_before + 1) == 1)) && (objElement.value.length <= 3)) {
            t_error = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=' + fieldname + '&arg3=3');
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            if (objLabel)
                objLabel.className = 'labelErr';
            return false;
        }
        if (isMin == false && objElement.value.length < 3) {
            t_error = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=3&arg3=' + fieldname);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            if (objLabel)
                objLabel.className = 'labelErr';
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);

            if (alphanum.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            t_error = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=' + fieldname);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            if (objLabel)
                objLabel.className = 'labelErr';
            flg = 0;
            return false;
        }
        if (isMin == true && objElement.value.length > length) {
            t_error = '<p class="title_toolltip">' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file(path_relative_root + 'ajax_sprintf.php?arg1=js_too_long_alphanum&arg2=' + fieldname + '&arg3=' + length);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        }
    }
    objElement.className = 'inputForm';
    if (objLabel)
        objLabel.className = 'labelForm';
    return true;
}

function verif_textarea(elementID, fieldname) {
    var flg = 0;
    var alphanum = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ,'\"\\n\r-_/.()%:; �����i�������";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement.value == "") {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_thanks&arg2=' + fieldname);
        objElement.className = 'textareaErr';
        objLabel.className = 'labelErr';
        objElement.focus();
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if ((((space_after + 1) == objElement.value.length) || ((space_before + 1) == 1)) && (objElement.value.length <= 3)) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=' + fieldname + '&arg3=3');
            objElement.className = 'textareaErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        if (objElement.value.length < 3) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=3&arg3=' + fieldname);
            objElement.className = 'textareaErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (alphanum.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_wrong_input_alphanum&arg2=' + fieldname);
            objElement.className = 'textareaErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            flg = 0;
            return false;
        }
    }
    objElement.className = 'textarea';
    objLabel.className = 'labelForm';
    return true;
}

function verif_textarea2(elementID, fieldname) {
    var flg = 0;
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement.value == "") {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_thanks&arg2=' + fieldname);
        objElement.className = 'textareaErr';
        objLabel.className = 'labelErr';
        return false;
    }
    if (objElement.value.length < 3) {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=3&arg3=' + fieldname);
        objElement.className = 'textareaErr';
        objLabel.className = 'labelErr';
        return false;
    }
    objElement.className = 'textarea';
    objLabel.className = 'labelForm';
    return true;
}

function verif_mail(elementID, fieldname, is_spe) {

    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    var mail = new RegExp("^[a-z0-9_-]+([.]?[a-z0-9_-]{1,})*@([a-z0-9_-]{2,}[.])+[a-z]{2,4}$", "gi");

    path = '';
    if (typeof (path_relative_root) != 'undefined')
        path += path_relative_root;

    if (mail.exec(objElement.value) == null) {
        if (typeof (is_spe) == 'undefined') {
            var translations = ajax_file(path + 'ajax_sprintf.php?arg0=%s|%s' +
                '&arg1=' + fieldname +
                '&arg2=js_wrong_input_mail'
            );
            translations = translations.split("|");
            type_champ = translations[0];
            type_message = translations[1];

            erreurText.innerHTML = '<p class="title_toolltip">' + type_champ + ' :</p>' + type_message;
        }
        $(objElement).addClass('inputErr');
        $(objLabel).addClass('labelErr');
        return false;

    }
    $(objElement).removeClass('inputErr');
    $(objLabel).removeClass('labelErr');
    return true;
}

function verif_alpha(elementID, fieldname) {
    var flg = 0;
    var alphanum = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ,'\"-_/.ééÈèËëÊêÔôÎîÛûÂâÏïÄÖöäÜüÀàÙùÇç&n " + decodeURIComponent("%C3%A0%C3%A9%C3%A8%C3%AB%C3%AAi%C3%AF%C3%AE%C3%B6%C3%B4%C3%B9%C3%BC%C3%BB%C3%A7%C3%A4%C3%84%C3%82%C3%80%C3%89%C3%88%C3%8B%C3%8AI%C3%8F%C3%8E%C3%96%C3%94%C3%99%C3%9C%C3%9B%C3%87%C2%A0 ");
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    $(objElement).removeData('data-error-text').removeAttr('data-error-text');
    if (objElement.value == "") {
        t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        $(objElement).data('data-error-text', t_error);
        erreurText.innerHTML = t_error;
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    } else {
        var space_after = objElement.value.lastIndexOf(" ");
        var space_before = objElement.value.indexOf(" ");
        if ((((space_after + 1) == objElement.value.length) || ((space_before + 1) == 1)) && (objElement.value.length <= 2)) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars_space&arg2=' + fieldname + '&arg3=2');
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        }
        if (objElement.value.length < 2) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=2&arg3=' + fieldname);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (alphanum.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_wrong_input_alpha&arg2=' + fieldname);
            $(objElement).data('data-error-text', t_error);
            erreurText.innerHTML = t_error;
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            flg = 0;
            return false;
        }
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verif_format_date(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement.value != '') {
        var regDateVente = new RegExp("^(\\d{2})/(\\d{2})/(\\d{2})$", "gi");
        if (regDateVente.exec(objElement.value) == null) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_date_1&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        }
    } else {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_date_2&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verif_format_date2(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement.value != "") {
        var regDateVente = new RegExp("^(\\d{2})/(\\d{2})/(\\d{4})$", "gi");
        if (regDateVente.exec(objElement.value) == null) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_date_1&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        }
    } else {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_date_2&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verif_format_date_select(elementID, fieldname) {
    var objElementJ = document.getElementById(elementID + 'J');
    var objElementM = document.getElementById(elementID + 'M');
    var objElementA = document.getElementById(elementID + 'A');
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElementJ.value == "" || objElementM.value == "" || objElementA.value == "") {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        objElementJ.className = 'inputErr';
        objElementM.className = 'inputErr';
        objElementA.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    }
    objElementJ.className = 'inputForm';
    objElementM.className = 'inputForm';
    objElementA.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verif_format_majorite(elementID, fieldname) {
    var objElementJ = document.getElementById(elementID + 'J');
    var objElementM = document.getElementById(elementID + 'M');
    var objElementA = document.getElementById(elementID + 'A');
    var date = new Date();
    var annee = date.getFullYear();
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElementA.value > (annee - 18)) {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_majorite');
        objElementJ.className = 'inputErr';
        objElementM.className = 'inputErr';
        objElementA.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    }
    objElementJ.className = 'inputForm';
    objElementM.className = 'inputForm';
    objElementA.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verif_selected(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement) {
        var objLabel = document.getElementById(elementID + 'Label');
        if (objElement.value == "") {
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_select&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        } else {
            objElement.className = 'inputForm';
            objLabel.className = 'labelForm';
            return true;
        }
    }
    return true;
}

function verif_selected_date(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    var objElementJ = document.getElementById(elementID + 'J');
    var objElementM = document.getElementById(elementID + 'M');
    var objElementA = document.getElementById(elementID + 'A');
    if (objElementJ && objElementM && objElementA) {
        var error = false;
        if (objElementJ.value == "") {
            error = true;
            $(objElementJ).addClass('inputErr');
        } else {
            $(objElementJ).removeClass('inputErr');
        }
        if (objElementM.value == "") {
            error = true;
            $(objElementM).addClass('inputErr');
        } else {
            $(objElementM).removeClass('inputErr');
        }
        if (objElementA.value == "") {
            error = true;
            $(objElementA).addClass('inputErr');
        } else {
            $(objElementA).addClass('inputErr');
        }
        if (!error) {
            $(objLabel).removeClass('labelErr');
            if ($(objElement).length > 0 && $(objElement).children('.img_error').length > 0) {
                $(objElement).children('.img_error').remove();
            }
            erreurText.innerHTML = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
            return true;
        } else {
            var imgFile = 'img/';
            var $newImgValid = $('<img />', {
                class: 'valid_form',
                id: 'iconValid' + elementID + 'A',
                src: path_relative_root + imgFile + 'picto_panneau.png'
            });
            $(objElementA).after($newImgValid);
            $(objLabel).addClass('labelErr');
            return false;
        }
    }
    return true;
}

function verif_selected2(elementID, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objElement) {
        var objLabel = document.getElementById(elementID + 'Label');
        if (objElement.value == "") {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_select&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            return false;
        } else {
            objElement.className = 'inputForm';
            objLabel.className = 'labelForm';
            return true;
        }
    } else {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_select&arg2=' + fieldname);
        return false;
    }
    return true;
}

function verif_categ(formID) {
    var objForm = document.getElementById(formID);
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    if (objForm.prodCadeau) {
        if (objForm.prodCadeau.checked == false && !objForm.prodCateg_0.selectedIndex) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_categ_1');
            return false;
        }
        if (objForm.prodCadeau.checked == false &&
            objForm.prodCateg_0.selectedIndex &&
            !objForm.prodSousCateg_0.selectedIndex) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_categ_1');
            return false;
        }
        if (objForm.prodCadeau.checked == false &&
            objForm.prodCateg_0.selectedIndex &&
            objForm.prodSousCateg_0.selectedIndex &&
            !objForm.prodObjet_0.selectedIndex) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_categ_1');
            return false;
        }
    } else {
        if (!objForm.prodCateg_0.selectedIndex) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_categ_2');
            return false;
        }
        $errTMP = false;
        for (x = 0; x <= 4; x++) {
            if (eval("objForm.prodCateg_" + x + ".selectedIndex"))
                if (!eval('objForm.prodObjet_' + x))
                    $errTMP = true;
                else
            if (!eval('objForm.prodObjet_' + x + '.selectedIndex'))
                $errTMP = true;
        }
        if ($errTMP == true) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_error_categ_3');
            return false;
        }
    }
    return true;
}

var indexImg = 0;

function nextImage(elementID, imgPath) {
    if (imgPath.length > 0) {
        indexImg++;
        if (indexImg >= imgPath.length)
            indexImg = 0;
        MM_swapImage(elementID, '', imgPath[indexImg], 1);
    }
}

function prevImage(elementID, imgPath) {
    if (imgPath.length > 0) {
        indexImg--;
        if (indexImg < 0)
            indexImg = imgPath.length - 1;
        MM_swapImage(elementID, '', imgPath[indexImg], 1);
    }
}

function NewWindow(mypage, myname, w, h, scroll) {
    LeftPosition = (screen.width) ? (screen.width - w) / 2 : 0;
    TopPosition = (screen.height) ? (screen.height - h) / 2 : 0;
    settings =
        'height=' + h + ',width=' + w + ',top=' + TopPosition + ',left=' + LeftPosition + ',scrollbars=' + scroll + ',resizable,';
    win = window.open(mypage, myname, settings);
}

function preg_replace(array_pattern, array_pattern_replace, my_string) {
    var new_string = String(my_string);
    for (i = 0; i < array_pattern.length; i++) {
        var reg_exp = RegExp(array_pattern[i], "gi");
        var val_to_replace = array_pattern_replace[i];
        new_string = new_string.replace(reg_exp, val_to_replace);
    }
    return new_string;
}

function verif_num_bandeau(elementID, fieldname) {
    var flg = 0;
    var num = "0123456789.,";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    var point = 0;
    var virgule = 0;
    if (objElement.value == "") {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        objElement.focus();
        return false;
    } else {
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (num.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_wrong_input_num_bandeau&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            flg = 0;
            return false;
        }
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verif_etage(elementID, fieldname, nb) {
    var flg = 0;
    var num = "0123456789rdcRDC.";
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurFieldset = document.getElementById('mes_err_fieldset');
    var erreurText = document.getElementById('erreur_msg');
    var point = 0;
    var virgule = 0;
    if (objElement.value == "") {
        erreurFieldset.style.display = 'block';
        erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_thanks_precise&arg2=' + fieldname);
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        objElement.focus();
        return false;
    } else {
        if (objElement.value.length > nb) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_at_least_n_chars&arg2=' + nb + '&arg3=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            return false;
        }
        for (var k = 0; k < objElement.value.length; k++) {
            var test = objElement.value.substring(k, k + 1);
            if (num.indexOf(test) < 0)
                flg++;
        }
        if (flg > 0) {
            erreurFieldset.style.display = 'block';
            erreurText.innerHTML = ajax_file('ajax_sprintf.php?arg1=js_wrong_input_num_rdc&arg2=' + fieldname);
            objElement.className = 'inputErr';
            objLabel.className = 'labelErr';
            objElement.focus();
            flg = 0;
            return false;
        }
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function verifSame(field1, field2, fieldname) {
    var objElement = document.getElementById(elementID);
    var objLabel = document.getElementById(elementID + 'Label');
    var erreurText = document.getElementById('erreur_msg');
    $(objElement).removeData('data-error-text').removeAttr('data-error-text');
    if (document.getElementById(field1).value != document.getElementById(field2).value) {
        var txt_lang = (fieldname == 'login_email_verif') ? 'js_same_email_confirm' : 'js_same_mdp_confirm';
        t_error = '<p class="title_toolltip">' + ajax_file('ajax_sprintf.php?arg1=' + fieldname) + ' :</p>' + ajax_file('ajax_sprintf.php?arg1=' + txt_lang + '&arg2=' + fieldname);
        $(objElement).data('data-error-text', t_error);
        erreurText.innerHTML = t_error;
        objElement.className = 'inputErr';
        objLabel.className = 'labelErr';
        return false;
    }
    objElement.className = 'inputForm';
    objLabel.className = 'labelForm';
    return true;
}

function isClosedDay(day, month) {
    var array_closed_array = new Array('1/1', '25/4', '1/5', '8/5', '2/6', '13/6', '14/7', '15/8', '1/11', '11/11', '25/12', '26/12');
    return array_closed_array.in_array(day + '/' + month);
}

(function () {

    'use strict';

    function in_array(p_val) {

        for (var i = 0, l = this.length; i < l; i++) {

            if (this[i] == p_val) {

                return true;
            }
        }

        return false;
    }

    Object.defineProperty(Array.prototype, 'in_array', {
        configurable: true,
        enumerable: false,
        writable: false,
        value: in_array
    });
}());

var firstElementVisu = 0;
function changeVisu(field, path, rollOut) {
    $('#' + field).attr('class', 'img_visu');
    $('body.product_page  #zoom_div .img_large').show();
    $('body.product_page  #zoom_div .video-responsive').hide();
    var new_img;

    field = document.getElementById(field);

        if (field && field.parentElement) {

            new_img = new Image();

            new_img.id = field.id;
            new_img.className = field.className;

            new_img.addEventListener('load', function () {

                this.alt = field.alt;

                this.setAttribute('width', this.naturalWidth);
                this.setAttribute('height', this.naturalHeight);

                if (field.parentElement) {
                    if (firstElementVisu != 0 && rollOut){
                        field.parentElement.appendChild(firstElementVisu);
                        firstElementVisu = 0;
                    }else{
                        firstElementVisu = field ;
                        field.parentElement.appendChild(this);
                    }
                        field.parentElement.removeChild(field);
                }
            });

            new_img.src = path;
        }
    // Simpler way doesn't seem to work well with lazyloader
}

function getPos(obj) {
    var pos = {
        x: obj.offsetLeft || 0,
        y: obj.offsetTop || 0
    };
    while (obj = obj.offsetParent) {
        pos.x += obj.offsetLeft || 0;
        pos.y += obj.offsetTop || 0;
    }
    return pos;
}

function closeInfobulle(id) {
    if (!jQuery.browser.msie) $("#" + id).fadeOut(400);
    else document.getElementById(id).style.display = 'none';
    document.getElementById(id).style.zIndex = '0';
}

var one_time = false;

function openInfobulle(id, type, elem) {

    window.clearTimeout(timer);

    $("#bloc_erreur").css('display', 'none');

    document.getElementById(id).style.display = 'block';

    if (typeof type !== 'undefined') {

        var margin_spe_left = 0;
        var margin_spe_top = 0;

        if (elem.nodeName == 'SELECT') {

            id = elem.id.substr(0, elem.id.length - 1);

            if (id == 'clientMailAuth' || id == 'clientPwdAuth' || id == 'mailLost') {

                var txt_spe = document.getElementById(id + 'ErrErr').innerHTML;

                margin_spe_left = 300;

                if (id == 'clientPwdAuth') {

                    margin_spe_top = 10;
                }
            } else if (type == 'banque') {

                var txt_spe = document.getElementById('infos_carte').innerHTML;
            } else if (type == 'alias_banque') {

                var txt_spe = document.getElementById('infos_alias').innerHTML;
            } else {

                var txt_spe = document.getElementById(id + 'Err').innerHTML;
            }
        } else {

            if (elem.id == 'clientMailAuth' || elem.id == 'clientPwdAuth' || elem.id == 'mailLost') {

                if (document.getElementById(elem.id + 'ErrErr')) {

                    var txt_spe = document.getElementById(elem.id + 'ErrErr').innerHTML;
                }

                if (elem.id == 'mailLost') {

                    margin_spe_left = -350;
                } else {

                    margin_spe_left = -340;
                }

                if (elem.id == 'clientPwdAuth') {

                    margin_spe_top = 41;
                } else {

                    margin_spe_top = 4;
                }
            } else if (type == 'banque') {

                var txt_spe = document.getElementById('infos_carte').innerHTML;
            } else if (type == 'alias_banque') {

                var txt_spe = document.getElementById('infos_alias').innerHTML;
            } else {

                var txt_spe = document.getElementById(elem.id + 'Err').innerHTML;
            }
        }

        document.getElementById('erreur_msg').innerHTML = txt_spe;

        pos = getPos(elem);

        document.getElementById('bloc_erreur').style.position = 'absolute';

        if (type == 'banque') {

            margin_spe_left -= 42;
            margin_spe_top += 30;
        } else if (type == 'alias_banque') {

            margin_spe_left -= 42;
            margin_spe_top += 15;
        }

        document.getElementById('bloc_erreur').style.top = pos.y - (document.getElementById('bloc_erreur').offsetHeight + margin_spe_top - 2) + 'px';

        pos_left_margin = getPos(document.getElementById('content'));
        left_add = 0;

        if (document.getElementById('wrapper_anniversaire_jour1')) {

            left_add = document.getElementById('bloc_erreur').offsetWidth;
            left_add -= 35;
        }

        document.getElementById('bloc_erreur').style.left = (pos.x + (elem.offsetWidth - 8) + margin_spe_left + 27 - left_add) + 'px';

        if (type == 'banque' || type == 'alias_banque') {

            document.getElementById('bloc_erreur').style.width = '280px';
        } else {

            document.getElementById('bloc_erreur').style.width = '280px';
        }

        document.getElementById('bloc_erreur').style.zIndex = '10000';
    }
}

function basculeAndAdd(elem, msg, type) {
    clearTimeout(timer);
    if (type == 'visible') {
        pos = getPos(elem);
        document.getElementById('erreur_msg').innerHTML = msg;
        if (!jQuery.browser.msie) $("#bloc_erreur").fadeIn(400);
        else document.getElementById('bloc_erreur').style.display = 'block';
        document.getElementById('bloc_erreur').style.position = 'absolute';
        var nav = navigator.appVersion;
        if (nav.indexOf('MSIE 7') >= 0) {
            pos_parent = getPos(document.getElementById('bloc_erreur').parentNode);
            if (document.getElementById('bloc_erreur').parentNode.id == 'home_wrapper') {
                document.getElementById('bloc_erreur').style.top = (pos.y) - (document.getElementById('bloc_erreur').offsetHeight) + 'px';
                document.getElementById('bloc_erreur').style.left = ((pos.x + 20)) + 'px';
            } else {
                document.getElementById('bloc_erreur').style.top = (pos.y - pos_parent.y) - (document.getElementById('bloc_erreur').offsetHeight) + 'px';
                document.getElementById('bloc_erreur').style.left = ((pos.x + 20) - pos_parent.x) + 'px';
            }
        } else {
            document.getElementById('bloc_erreur').style.top = pos.y - (document.getElementById('bloc_erreur').offsetHeight) + 'px';
            document.getElementById('bloc_erreur').style.left = (pos.x + 20) + 'px';
        }
        document.getElementById('bloc_erreur').style.zIndex = '1';
    } else {
        document.getElementById(type_spe).style.display = "none";
    }
}

function OverPopup(elem, msg, type, is_vp) {

    clearTimeout(timer);

    if (type == 'visible') {

        if (typeof is_vp !== 'undefined' && is_vp !== 'cmpid_adwords') {

            $("#bloc_erreur_vp").css('display', 'none');

            $("#bloc_erreur_vp").stop(false, true).fadeOut().fadeIn(400);
        } else {

            if (!document.getElementById('formAnniversaire') || (document.getElementById('formAnniversaire') && elementID != 'newsletterMailv' && elementID != 'newsletterMailInscrit')) {

                pos = getPos(elem);

                document.getElementById('erreur_msg').innerHTML = msg;

                document.getElementById('bloc_erreur').style.display = 'block';
                document.getElementById('bloc_erreur').style.position = 'absolute';

                var topHeight = parseFloat($('#main_wrapper #top_wrapper_concours, #top_wrapper,#main_wrapper_home #top_wrapper_concours, #top_wrapper, #popup_inscription_home').offset().top);

                if (topHeight == 0) {

                    topHeight = 4;
                }

                pos_left_margin = -13;

                var left_add = 17;

                if (document.getElementById('wrapper_anniversaire_jour1')) {

                    left_add = document.getElementById('bloc_erreur').offsetWidth;
                    left_add -= 35;
                }

                if (document.getElementById('formAnniversaire')) {

                    left_add = $('#erreur_msg').width() - 10;
                    $(".bottom_tooltip").attr('align', 'right');
                }

                document.getElementById('bloc_erreur').style.left = ((pos.x + (elem.offsetWidth - 10) - pos_left_margin - left_add) + 1) + 'px';

                if (is_vp === 'cmpid_adwords') {

                    var clone = $('#bloc_erreur').clone(),
                        c_pos = {
                            top: $(elem).offset().top - $(elem).offsetParent().offset().top - $('#bloc_erreur').height() + 4,
                            left: $(elem).offset().left - $(elem).offsetParent().offset().left - $('#bloc_erreur').innerWidth() + ($(elem).width() * 2) + 6
                        };

                    $('#bloc_erreur').remove();

                    $(elem).after(clone);

                    $('#bloc_erreur').find('td:nth-child(2)').children('img').css({
                        float: 'right',
                        margin: '0 7px'
                    });

                    $('#bloc_erreur').css({
                        position: 'absolute',
                        top: c_pos.top,
                        left: c_pos.left,
                        zIndex: 99999,
                        opacity: 1
                    });
                } else {

                    document.getElementById('bloc_erreur').style.top = (pos.y - (document.getElementById('bloc_erreur').offsetHeight + topHeight)) + 'px';
                }

                if (elem.id == "departement_naissance") {

                    $('#bloc_erreur').css({
                        width: 180,
                        marginTop: 63,
                        marginLeft: -310
                    });
                }

                document.getElementById('bloc_erreur').style.zIndex = 99999;
            }
        }
    } else {

        if (typeof is_vp !== 'undefined' && is_vp !== 'cmpid_adwords') {

            document.getElementById('bloc_erreur_vp').style.display = 'none';
            document.getElementById('bloc_erreur_vp').style.zIndex = 0;
        } else {

            document.getElementById('bloc_erreur').style.display = 'none';
            document.getElementById('bloc_erreur').style.zIndex = 0;
        }
    }

    console.groupEnd();
}

function getXY(obj) {
    var curleft = 0;
    var curtop = obj.offsetHeight + 5;
    var border;

    if (obj.offsetParent) {
        do {
            if (getStyle(obj, 'position') == 'relative') {

                if (border = _pub.getStyle(obj, 'border-top-width')) curtop += parseInt(border);
                if (border = _pub.getStyle(obj, 'border-left-width')) curleft += parseInt(border);
            }
            curleft += obj.offsetLeft;
            curtop += obj.offsetTop;
        }
        while (obj = obj.offsetParent)
    } else if (obj.x) {
        curleft += obj.x;
        curtop += obj.y;
    }
    return {
        'x': curleft,
        'y': curtop
    };
}

/**
 * Returns the specified computed style on an object.
 * @param {HTMLObject} obj HTML Object
 * @param {String} styleProp Property name.
 * @return {Mixed} Computed style on object.
 */
function getStyle(obj, styleProp) {
    if (obj.currentStyle)
        return obj.currentStyle[styleProp];
    else if (window.getComputedStyle)
        return document.defaultView.getComputedStyle(obj, null).getPropertyValue(styleProp);
}

/* pour les questions du panier panier */
function fadePanier(id, nbelem) {
    for (var i = 1; i <= nbelem; i++) {
        if (id != i && $("#texte_question_" + i).css('display') == 'block') $("#texte_question_" + i).slideUp(400);
    }
    if ($("#texte_question_" + id).css('display') != 'block') $("#texte_question_" + id).slideDown(400);
    else $("#texte_question_" + id).slideUp(400);
}

function fadeHelp(id, nbelem) {
    for (var i = 1; i <= nbelem; i++) {
        if (id != i && $("#answer_" + i).css('display') == 'block') $("#answer_" + i).slideUp(400);
    }
    if ($("#answer_" + id).css('display') != 'block') $("#answer_" + id).slideDown(400);
    else $("#answer_" + id).slideUp(400);
}

/********showblocform********/
function showBlocForm(id, type) {
    clearTimeout(timer);

    if ($('#bloc_erreur').css('display') == 'table' || $('#bloc_erreur').css('display') == 'block') $('#bloc_erreur').css('display', 'none');

    if (id == 'is_not_client') {

        $("#is_client_form").fadeOut(600);
        $(".wrapper_login_left").addClass('actif');
        $(".wrapper_login_right").removeClass('actif');
        $("#notclient").addClass('actif');
        $("#is_not_client").addClass('actif');
        $("#client").removeClass('actif');
        $("#is_client").removeClass('actif');
        if ($("#bloc_lost_password").css('display') == 'table-row' || $("#bloc_lost_password").css('display') == 'block') setTimeout("reloadBlocLogin()", 100);
    } else if (id == 'is_client') {

        $("#is_not_client_form").fadeOut(600);
        $(".wrapper_login_left").removeClass('actif');
        $(".wrapper_login_right").addClass('actif');
        $("#notclient").removeClass('actif');
        $("#is_not_client").removeClass('actif');
        $("#client").addClass('actif');
        $("#is_client").addClass('actif');
    }

    $("#" + id + "_form").fadeIn(600, function () {});
}
/*********/
/********affichelostpassword********/
var afficheLostPassword = function (evt) {

    var signIn = $('#auth');
    var passWd = $('#resetPass');

    evt.preventDefault();

    signIn.animate({
        opacity: 0
    }, 600, function () {

        signIn.css('display', 'none');

        passWd.css('display', '').animate({
            opacity: 1
        }, 600);
    });
};

/*********/
/********reloadbloclogin********/
var reloadBlocLogin = function (evt) {

    var signIn = $('#auth');
    var passWd = $('#resetPass');

    evt.preventDefault();

    passWd.animate({
        opacity: 0
    }, 600, function () {

        passWd.css('display', 'none');

        signIn.css('display', '').animate({
            opacity: 1
        }, 600);
    });
};

/*********/

function afficheLostPasswordTop() {

    if ($('#bloc_erreur').is(':visible')) {

        $('#bloc_erreur').css('display', 'none');
    }

    $("#returning_customer_top").hide();
    $("#lost_password_top").fadeIn(600);
}

function reloadBlocLoginTop() {

    if ($('#bloc_erreur').is(':visible')) {

        $('#bloc_erreur').css('display', 'none');
    }

    $("#lost_password_top").hide();
    $("#returning_customer_top").fadeIn(600);
}

function openConnexionTop() {
    if (document.getElementById('topMenuConnexion').style.display == 'none') {
        document.getElementById('topMenuConnexion').style.display = 'block';
        var bouton = document.getElementById('topMenuConnexionButtonInside');
        bouton.style.height = '18px';
        bouton.style.borderBottom = 'none';
        bouton.style.MozBorderRadius = '5px 5px 0px 0px';
        bouton.style.WebkitBorderRadius = '5px 5px 0px 0px';
        if (jQuery.browser.msie) {
            document.getElementById('topMenuConnexionButton').style.width = '294px';
        }
    }
}

function closeConnexionTop() {
    if (document.activeElement != undefined) {
        if (document.activeElement.type != 'text' && document.activeElement.type != 'password')
            document.getElementById('wrapper_infos_client').style.display = 'none';
    } else { // ff2 et opera 3
        document.getElementById('wrapper_infos_client').style.display = 'none';
    }
}

var cpt_over = 0;

function initBlocCheckBox(id) {
    if (cpt_over == 0) {
        $("#select_" + id).css('display', 'block');
        $("#recherche_" + id).css('backgroundPosition', 'left bottom');
        cpt_over++;
    }
}

function closeCheckBox(id) {
    cpt_over = 0;
    $("#select_" + id).css('display', 'none');
    $("#recherche_" + id).css('backgroundPosition', 'left top');
}

function display_choix_relais(action) {
    if (action == 'show') {
        $("#kiala").slideUp("slow");
        $("#kiala2").slideDown("slow");
        $("#choix_relais_kiala").slideDown("slow");
        $("#btn_another_relay").hide();
        $("#btn_close_another_relay").show();
    } else {
        $("#kiala").slideDown("slow");
        $("#kiala2").slideUp("slow");
        $("#choix_relais_kiala").slideUp("slow");
        $("#btn_another_relay").show();
        $("#btn_close_another_relay").hide();
    }
}

function display_parrainage_succes() {
    document.getElementsByName('amiPrenom1')[0].value = '';
    document.getElementsByName('amiPrenom2')[0].value = '';
    document.getElementsByName('amiPrenom3')[0].value = '';
    document.getElementsByName('amiPrenom4')[0].value = '';
    document.getElementsByName('amiPrenom5')[0].value = '';
    document.getElementsByName('amiMail1')[0].value = '';
    document.getElementsByName('amiMail2')[0].value = '';
    document.getElementsByName('amiMail3')[0].value = '';
    document.getElementsByName('amiMail4')[0].value = '';
    document.getElementsByName('amiMail5')[0].value = '';
    var middle_grandjeu = document.getElementById('middle_grandjeu');
    var grandjeu_03 = document.getElementById('grandjeu_03');
    var grandjeu_04 = document.getElementById('grandjeu_04');
    middle_grandjeu.style.display = 'none';
    grandjeu_03.style.display = 'none';
    grandjeu_04.style.display = 'none';
    $("#middle_felicitations").slideUp("slow");
    $("#middle_parrainage").slideDown("slow");
    $("#felicitations_01").slideUp("slow");
    $("#parrainage_01").slideDown("slow");
    $("#felicitations_02").slideUp("slow");
    $("#parrainage_02").slideDown("slow");
}

function show_popup(elem) {
    openMultiShad(elem);
}

function toggleFullScreen() {
    var video = document.getElementById("video_spa");
    if (video.requestFullScreen) {
        video.requestFullScreen();
    } else if (video.webkitRequestFullScreen) {
        video.webkitRequestFullScreen();
    } else if (video.mozRequestFullScreen) {
        video.mozRequestFullScreen();
    }
}

function close_popup(elem) {
    var shad = document.getElementById('shad');
    // with algolia search we clone footer and we have the element 2 time with same ID
    if (elem == "mention_legales_popup") {
        $("." + elem).each(function() {
            var modbox = $(this);
            modbox.hide();
        });
    } else {
        var modbox = document.getElementById(elem);

        if (modbox.classList.contains('sidebox')) {
            modbox.classList.remove('actif');

            if ($('body').hasClass('noScroll')) {
                $('body').removeClass('noScroll');
            }
        } else {
            modbox.style.display = "none";
        }
    }

    var startShadOpacity = Number($('#shad').css('opacity'));
    $('#shad').css({
        opacity: startShadOpacity
    }).animate({
        opacity: 0
    }, 350, function () {
        $('#shad').css({
            display: 'none',
            opacity: ''
        });
        if ($('html').hasClass('ie7')) {
            $('#shad')[0].style.removeAttribute('filter');
            $('html, body').css({
                overflow: ''
            });
        }
    });
    $("#shad").attr('onclick', '');
}

function flou(type, indice) {
    for (var i = 1; i <= 4; i++) {
        if (i != indice && type == 'over') {
            $("#img_look" + i).css('opacity', '0.2');
            $("#img_look" + i).css('margin', '40px 0 0 0');
            $("#img_look" + i).height(460);
        } else {
            $("#img_look" + i).css('opacity', '1');
            $("#img_look" + i).css('margin', '0');
            $("#img_look" + i).height(559);
        }
    }
}

function clearFieldParrain() {
    for (var i = 1; i <= 5; i++) {
        $("#amiPrenom" + i).val('');
        $("#amiMail" + i).val('');
    }
}

function hoverSscategory(id, subcategory_id) {
    fleche = document.getElementById('fleche_' + id);
    title = document.getElementById('title_' + id);
    ul = document.getElementById('ul_' + id);
    if (fleche) {
        fleche.style.backgroundPosition = 'left bottom';
    }
    if (id != subcategory_id && title) {
        title.style.color = '#ea6195';
        title.style.fontWeight = 'bold';
    }
    if (ul) {
        ul.style.display = 'block';
    }
}

function outSscategory(id, subcategory_id) {
    fleche = document.getElementById('fleche_' + id);
    title = document.getElementById('title_' + id);
    ul = document.getElementById('ul_' + id);
    if (fleche) {
        fleche.style.backgroundPosition = 'left top';
    }
    if (id != subcategory_id && title) {
        title.style.color = '#333333';
        title.style.fontWeight = 'normal';
    }
    if (ul) {
        ul.style.display = 'none';
    }
}

$(document).ready(function () {
    $(".bg_autres_rayons").each(function () {
        $(this).hover(function () {
            $(this).children("div").children(".wrapper_ul_autres_rayons").show();
            $(this).children("div").children("div.wrapper_title_autre_rayon").children("div.fleche_autres_rayons").addClass("selected");
            $(this).children("div").children("div.wrapper_title_autre_rayon").children("div.title_autre_rayon").addClass("gras_rose");
        }, function () {
            $(this).children("div").children(".wrapper_ul_autres_rayons").hide();
            $(this).children("div").children("div.wrapper_title_autre_rayon").children("div.fleche_autres_rayons").removeClass('selected noClass').addClass('');
            $(this).children("div").children("div.wrapper_title_autre_rayon").children("div.title_autre_rayon").removeClass('gras_rose noClass').addClass('');
        });
    });

    $("#bg_liste_ariane").hover(function () {
        $(this).children(".wrapper_liste_ariane").show();
        $(this).children("div.wrapper_title_liste_ariane").children("div.fleche_autres_rayons").addClass("selected");
    }, function () {
        $(this).children(".wrapper_liste_ariane").hide();
        $(this).children("div.wrapper_title_liste_ariane").children("div.fleche_autres_rayons").removeClass('selected noClass').addClass('');
    });

    //closing pop up when clicking on the algolia shade
    $("body").click(function(event) {
        if ($("body").hasClass("algolia_displayed") && event.target.id == "algolia_shade") {
            $("#mention_legales_pop_up").css("display", "none");
            $('#shad').css("display", "none");
        }
    });

});

function show_lightbox_inscrit(popup_confirm_inscription) {
    $('#shad_popup_confirm_inscription').fadeTo("slow", 0.8).queue(function () {
        $(this).css('display', 'block');
        $('#' + popup_confirm_inscription).slideDown("slow");
        $(this).dequeue();
    });
}

function close_lightbox_inscrit(popup_confirm_inscription) {
    $('#' + popup_confirm_inscription).slideUp("slow").queue(function () {
        $('#shad_popup_confirm_inscription').fadeTo("slow", 0, function () {
            $(this).css('display', 'none');
        });
        $(this).dequeue();
    });
}

var old_onclick_shad = 'popup_confirm_reinitialisation_mdp';

function show_lightbox(popup_confirm_reinitialisation_mdp) {
    $('#shad_popup_confirm_reinitialisation_mdp').fadeTo("slow", 0.8).queue(function () {
        $(this).css('display', 'block');
        $('#' + popup_confirm_reinitialisation_mdp).slideDown("slow");
        $(this).dequeue();
    });
    $("#shad_popup_confirm_reinitialisation_mdp").click(function () {

        close_lightbox(popup_confirm_reinitialisation_mdp);

    });
}

function close_lightbox(popup_confirm_reinitialisation_mdp) {

    $('#' + popup_confirm_reinitialisation_mdp).slideUp("slow").queue(function () {
        $('#shad_popup_confirm_reinitialisation_mdp').fadeTo("slow", 0, function () {
            document.getElementById(popup_confirm_reinitialisation_mdp).style.display = 'none';
        });
        document.getElementById('shad_popup_confirm_reinitialisation_mdp').style.display = 'none';
        $('#' + popup_confirm_reinitialisation_mdp).dequeue();
    });
    $("#shad_popup_confirm_reinitialisation_mdp").click(function () {
        close_lightbox(old_onclick_shad);
    });
}

function show_lightbox_3xcb() {
    $('#shad').fadeTo("slow", 0.8).queue(function () {
        $(this).css('display', 'block');
        $('#popup_3xcb').slideDown("slow");
        $(this).bind('click', close_lightbox_3xcb);
        $(this).dequeue();
    });
}

function close_lightbox_3xcb() {
    $('#popup_3xcb').hide();
    $('#shad').hide();
    $(this).unbind('click', close_lightbox_3xcb);
}

function in_array(needle, haystack, strict) {
    var rKey = -1,
        strict = !!strict;
    if (haystack.length > 0) {
        for (key in haystack) {
            if ((strict && haystack[key] === needle) || (!strict && haystack[key] == needle)) {
                rKey = key;
                break;
            }
        }
    }
    return rKey;
}

function in_array_js(needle, haystack) {
    var rKey = -1;
    if (haystack.length > 0) {
        for (key in haystack) {
            if (haystack[key] == needle) {
                rKey = key;
                break;
            }
        }
    }
    return rKey > -1 ? true : false;
}

var elmt;
var loader;
var prev_display;

/**
 * Express buy
 * @param product_id : id du produit
 * @param regroup_ref_id : id du regroupement (si existant)
 * use in : app/views/_components/product/achat_express.php
 */
function achatExpressV2(product_id, regroup_ref_id) {
    var btn = $(this);

    // Loader
    btn.parent('.form_submit').addClass('loading');

    var elem = regroup_ref_id ? '_' + product_id + regroup_ref_id : '_' + product_id;
    var form = document.getElementById('prod_info' + elem);

    var color_id = form.elements['itm_color'].value;
    var size_id = form.elements['itm_size'].value;
    // Vérification si la taille sélectionnée n'est pas désactivée ce qui permet d'afficher l'alerte stock
    var tailleProdCrossSell = $('#qteProd_' + product_id + regroup_ref_id);
    if (($('#size_' + product_id + "_" + size_id + regroup_ref_id, $('#prod_info' + elem)) != undefined && $('#size_' + product_id + "_" + size_id + regroup_ref_id, $('#prod_info' + elem)).length == 1 && $('#size_' + product_id + "_" + size_id + regroup_ref_id, $('#prod_info' + elem)).hasClass('disabled')) || ($('#tailleProd_' + product_id + '_' + regroup_ref_id).value == '0') || (typeof tailleProdCrossSell[0] != "undefined" && tailleProdCrossSell[0].value == "0")) {
        $(this).parent().hide();
        $('#bloc_add_alert' + elem).show();
    } else { // On ajoute bien le produit en faisant les vérifications sur la taille et la couleur
        // On regarde si la personne a sélectionenr la taille et la couleur
        if (color_id !== '' && size_id !== '') {
            if ($('.wrapperCartAssociations').length) {

                waitingAddToBasket(event, form, 'basket', regroup_ref_id);
            } else {

                waitingAddToBasket(event, form, '', regroup_ref_id);
            }

            setTimeout(function () {
                btn.parent('.form_submit').removeClass('loading');
            }, 2000);
        } else if (color_id === '' && size_id === '') {
            alert(Translator.translate('choose_a_size_and_a_color'));
            // Loader
            btn.parent('.form_submit').removeClass('loading');
        } else if (color_id === '') {
            alert(Translator.translate('choose_a_color'));
            // Loader
            btn.parent('.form_submit').removeClass('loading');
        } else if (size_id === '') {
            alert(Translator.translate('choose_a_size'));
            // Loader
            btn.parent('.form_submit').removeClass('loading');
        }
    }
}

function waitingProcess(src, target_id, type, id_form, tab_erreur) {
    if (src) {
        elmt = $("#" + $(src).attr('id'));
    }
    if (target_id) {
        loader = $("#" + target_id);
    }
    if (elmt.css('display') != 'none') {
        prev_display = elmt.css('display');
        elmt.css('display', 'none');
        loader.css('display', 'block');
        switch (type) {
            case 0:
                window.setTimeout('ajax_checkAllForm(tabAuthTop, 1, path_relative_root)', 1000);
                break;
            case 1:
                window.setTimeout('ajax_checkAllForm(tabLostPwdTop, 1, path_relative_root)', 1000);
                break;
            case 2:
                window.setTimeout('ajaxDeconnect(path_relative_root);', 1000);
                break;
            case 3:
                window.setTimeout('checkAllForm(tabError,0,path_relative_root,\'' + id_form + '\');', 1000);
                break;
            case 4:
                window.setTimeout('ajax_checkAllForm(tabAuth, 1, path_relative_root)', 1000);
                break;
            case 5:
                window.setTimeout('ajax_checkAllForm(tabLostPwd, 1, path_relative_root)', 1000);
                break;
            case 6:
                window.setTimeout('checkFormParrainage()', 1000);
                break;
            case 7:
                window.setTimeout('if (checkAllForm(tabErrorGrattez,0,path_relative_root,\'' + id_form + '\')) processGrattezGagnez();', 1000);
                break;

        }
    }
}

function hideLoader() {
    if (loader) {
        loader.css('display', 'none');
    }
    if (elmt) {
        elmt.css('display', prev_display);
    }
}

$.fn.recalImg = function (img) {
    var obj = $(this);
    var img;
    if (img == "" || img == null) {
        img = $("img", obj);
    } else {
        img = $(img, obj);
    }
    img.css({
        position: "",
        height: "",
        width: "",
        left: "",
        top: "",
        marginTop: "",
        marginLeft: ""
    });
    var widthObj = obj.outerWidth(true);
    var heightObj = obj.outerHeight(true);
    var widthImg = img.width();
    var heightImg = img.width();
    obj.css({
        position: "relative"
    });
    img.css({
        position: "absolute"
    });
    if ((heightImg > heightObj) || (widthImg > widthObj)) {
        if (widthImg > heightImg) {
            img.css({
                height: heightObj
            });
        } else if (widthImg <= heightImg) {
            img.css({
                width: widthObj
            });
        }
        var widthImgNow = img.outerWidth(true);
        var heightImgNow = img.outerHeight(true);
        var difHeight = (heightObj - heightImgNow) / 2;
        var difWidth = (widthObj - widthImgNow) / 2;
        if (widthImg > heightImg) {
            img.css({
                left: 0,
                top: 0
            });
        } else if (widthImg <= heightImg) {
            img.css({
                top: 0,
                left: 0
            });
        }
    } else {
        img.css({
            top: "0",
            left: "0"
        });
    }
    img.show();
};

function activeChange(p, c, s) {
    if ($(p).attr("value") == "") {
        $(c).css({
            color: "#aaa",
            fontStyle: "italic"
        });
    } else {
        $(c).css({
            color: "#000",
            fontStyle: "normal"
        });
    }
    c.text($('option:selected', s).text());
    if ($('option:selected', s).val() == "") {
        $(c).css({
            color: "#aaa",
            fontStyle: "italic"
        });
    }
}

/********select_styled********/
$.fn.selectStyled = function () {
    var p = $(this);

    if (!$(".clone", p).length) {
        p.prepend("<div class='clone'></div>");
    }
    if ((p.hasClass('selectStyledProduct') && (!$(".arrow", p).length))) {
        p.prepend("<div class='arrow'></div>");
    }
    var c = $('.clone', p);
    var s = $('select', p);
    var ws = s.outerWidth();
    var hs = s.outerHeight();
    var ts = 2;
    var ls = 5;

    if (!p.is(':visible') && p.css('width') != undefined) {
        ws = Number(p.css('width').replace('px', ''));
    }

    s.ready(function () {
        s.css({
            "display": "block",
            "opacity": "0",
            "position": "absolute",
            "top": "0",
            "left": "0"
        });
        c.css({
            width: ws
        });
        c.text($('option:selected', s).text());
    });

    if ($(s).prop("value") == "" || $(s).prop("value") == undefined) {
        $(c).css({
            color: "#aaa",
            fontStyle: "italic"
        });
    }
    $(s).change(function () {
        if ($(this).prop("value") == "" || $(this).prop("value") == undefined || $(this).prop("value") == null) {
            $(c).css({
                color: "#aaa",
                fontStyle: "italic"
            });
        } else {
            $(c).css({
                color: "",
                fontStyle: ""
            });
        }
    });

    $(s).keypress(function () {
        $(this).blur();
        $(this).focus();
    });

    s.change(function () {
        c.text($('option:selected', s).text());
    });
};

$(function () {
    $('.selectStyledFaq, .selectStyled, .selectStyledProduct').each(function () {
        $(this).selectStyled();
    });
});

$.fn.cbForm = function () {
    var t = $(this);
    var fs = $('.form_select', t);
    fs.css({
        opacity: "1"
    });
    fs.hover(function () {
        if (!$(this).hasClass("activ") && t.hasClass('active_bloc')) {
            $(this).css({
                opacity: "0.5"
            });
        }
    }, function () {
        if (!$(this).hasClass("activ") && t.hasClass('active_bloc')) {
            $(this).css({
                opacity: "1"
            });
        }
    });
    fs.click(function () {
        if (t.hasClass('active_bloc')) {
            fs.removeClass("activ");
            fs.css({
                opacity: "1"
            });
            $(this).addClass("activ");
            $(this).css({
                opacity: "0.5"
            });
            $(".type_carte option", t).removeAttr("selected");
            var typeCarte = $(this).attr("id");
            $("#type_carte_" + typeCarte, t).attr('selected', 'selected');
            p = $('.type_carte option:selected', t);
            c = $('.clone', $('.type_carte', t).parent('.selectStyled'));
            c.text(p.text());
            if ($(p).attr("value") == "") {
                $(c).css({
                    color: "#aaa",
                    fontStyle: "italic"
                });
            } else {
                $(c).css({
                    color: "#000",
                    fontStyle: "normal"
                });
            }
        }
    });
    $('.type_carte', t).change(function () {
        tcv = $('option:selected', this);
        var ctcv = tcv.val();
        fs.removeClass("activ");
        fs.css({
            opacity: "1"
        });
        if (ctcv != "") {
            $('.form_select#' + ctcv).addClass("activ");
            $('.form_select#' + ctcv).css({
                opacity: "1"
            });
        }
    });
}

function showerrormessage() {
    var elname = 'modboxpromo';
    $('#shad').fadeTo("slow", 0.8).queue(function () {
        $('#' + elname).slideDown("slow");
        $(this).dequeue();
    });
    addEventPromo('click', closeerrormessage);
    if (typeof (actualposition) == 'undefined') {
        actualposition = document.documentElement.scrollTop;
    }
    window.scrollTo(0, actualposition);
}

function closeerrormessage() {
    $('#modboxpromo').slideUp("slow").queue(function () {
        $('#shad').fadeTo("slow", 0);
        $(this).dequeue();
        $('#shad').hide();
        $('#modboxpromo').hide();
    });
    delEventPromo('click', closeerrormessage);

}

function showrecuppanierlightbox() {
    var elname = 'recuppanier';
    var actualposition = document.documentElement.scrollTop;
    var modbox = document.getElementById(elname);
    var topconnexion = document.getElementById('topMenuConnexionButtonInside');
    var shad = document.getElementById('shad2');
    shad.style.display = "block";
    modbox.style.display = "block";
    if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('7.') != -1) {
        topconnexion.style.zIndex = -1;
    }
    addEventPromo(shad, 'click', closerecuppanier);
    window.scrollTo(0, actualposition);
}

function closerecuppanier() {
    var modbox = document.getElementById('recuppanier');
    var shad = document.getElementById('shad2');
    modbox.style.marginLeft = '';
    shad.style.display = "none";
    modbox.style.display = "none";
    document.body.style.overflow = '';
    document.getElementsByTagName("html")[0].style.overflow = "";
    delEventPromo(shad, 'click', closerecuppanier);
}

function addEventPromo(event, name_function) {
    if (document.all) document.getElementById('shad').attachEvent("on" + event, name_function);
    else document.getElementById('shad').addEventListener(event, name_function, false);
}

function delEventPromo(event, name_function) {
    if (document.all) document.getElementById('shad').detachEvent("on" + event, name_function);
    else document.getElementById('shad').removeEventListener(event, name_function, false);
}

function addFileInput(id_input, string) {

    var field = (typeof (string) != 'undefined') ? string : 'contactProduitPhoto';
    var parent = $('#' + field + id_input).parent();

    // Get file
    var file = $('#' + field + id_input)[0].files[0];
    var error = false;

    var input = field == 'contactProduitPhoto' ? $('#photoProduit_display') : $('#photoProduitEndommage_display');

    if (file !== undefined) {
        size = file.size;
        type = file.type;
        var allowedType = new Array('image/jpeg', 'image/png', 'application/pdf'); // Type de fichiers autoris�s
        if (size > 4000000) {
            alert('Poids Max : 4Mo');
            error = true;
        } else if (jQuery.inArray(type, allowedType) == -1) {
            alert('Format autorisé : jpg, png, pdf');
            error = true;
        }
    } else {
        error = true;
    }

    if (error) {
        $('#' + field + id_input).val('');
    }

    if (field != 'filepj') {
        var params = (typeof (string) != 'undefined') ? ',"' + string + '"' : '';
        var style = (typeof (string) != 'undefined' && string != 'contactProduitPhotoEndommage') ? 'style="float: left; padding-left: 32px;padding-top:-2px;"' : '';

        // Add empty input
        var next_file = 1;
        parent.find('.product_file').each(function () {
            if ($(this)[0].files[0] == undefined) {
                $(this).remove();
            } else {
                $(this).attr('name', field + next_file);
                $(this).attr('id', field + next_file);
                $(this).attr('onchange', 'addFileInput(' + next_file + params + ')');
                next_file++;
            }
        });

        parent.find('.uploadBoxContent').before("<input " + style + " class='product_file' id='" + field + next_file + "' value='' type='file' name='" + field + next_file + "' onchange='addFileInput(" + next_file + params + ")' /> ");
        $(input).attr('value', next_file);
    }

    checkShowSendButton();
}

function checkShowSendButton() {

    var hasFile = [];
    var contactMessage = $('#contactMessage').val().trim();
    var j = 1;
    $('.file-wrapper1').each(function () {
        hasFile[j] = 0;
        $(this).find('.product_file').each(function () {
            if ($(this)[0].files[0] != undefined) {
                hasFile[j]++;
            }
        });
        j++;
    });

    $('#field_send').addClass('cache');
    if (contactMessage != '' && hasFile.indexOf(0) == -1) {
        $('#field_send').removeClass('cache');
    }

}

function uploadfile() { //fonction pour valider le fichier en pj dans le formulaire message
    var error = false;
    var fname = $('#message_file').val();
    var fsize = $('#message_file')[0].files[0].size; //get file size
    var ftype = $('#message_file')[0].files[0].type; // get file type
    var allowedType = new Array('image/jpeg', 'image/png', 'application/pdf'); // Type de fichiers autoris�s
    if (fsize > 4000000) {
        alert('Poids Max : 4Mo');
        error = true;
    } else if (jQuery.inArray(ftype, allowedType) == -1) {
        alert('Format autorisé : jpg, png, pdf');
        error = true;
    }
    if (error) {
        $('#message_file').val('');
    } else {

    }
}

function checkDateContact() {
    var jour = $('#contactDateCommandeJ').val();
    var mois = $('#contactDateCommandeM').val();
    var annee = $('#contactDateCommandeA').val();
    var field_error = $('#field_error_date_garantie td');
    field_error.empty();
    if (jour != "" && mois != "" && annee != "") {
        var date_selec = new Date(annee + '/' + mois + '/' + jour);
        date_selec = Math.round(date_selec.getTime() / 1000);
        var date = new Date();
        date = Math.round(date.getTime() / 1000);
        var deux_ans = 63072000; // 2 ans en timestamp
        var diff_deuxans = date - date_selec; // Duree date auj et date choisie
        if (diff_deuxans > deux_ans) {
            field_error.append(ajax_file('ajax_sprintf.php?arg1=contact_field_error_garantie'));
            $('input[name="contactGarantieProduit"]').removeAttr('checked')
            $('#field_error_garantieProduit td').empty();
            $('#field_garantieproduit').addClass('cache');
            $('#field_error_garantieProduit').addClass('cache');
            $('#field_garantieNomProduit').addClass('cache');
            $('#field_message').addClass('cache');
            $('#field_photo').addClass('cache');
            $('#field_send').addClass('cache');
            $('#field_error_date_garantie').removeClass('cache');
        } else {
            $('#field_garantieproduit').removeClass('cache');
            $('#field_error_garantieProduit').removeClass('cache');
            $('#field_error_date_garantie').addClass('cache');
        }
    } else {
        $('#field_garantieproduit').addClass('cache');
        $('#field_error_garantieProduit').addClass('cache');
        $('#field_preuveAchat').addClass('cache');
        $('#field_garantieNomProduit').addClass('cache');
        $('#field_message').addClass('cache');
        $('#field_photo').addClass('cache');
        $('#field_send').addClass('cache');
        $('#field_error_garantieProduit td').empty();
    }
}

function checkProduitContact(idProduit) {
    $('#contactMessage').empty();
    $('#contactProduitPhoto1').val("");
    $('#contactProduitPhoto2').remove();
    $('#contactProduitPhoto3').remove();
    $('#field_send').addClass('cache');
    if (idProduit != "") {
        tab = new Array();
        tab[0] = new Array('alpha', 'contactNom', 'contact_field_nom');
        tab[1] = new Array('alpha', 'contactPrenom', 'contact_field_prenom');
        tab[2] = new Array('mail', 'contactMail', 'contact_field_mail');
        tab[3] = new Array('no_empty', 'contactMessage', 'contact_field_message');
        $('#field_message').removeClass('cache');
        $('#field_photo').removeClass('cache');
    } else {
        $('#field_message').addClass('cache');
        $('#field_photo').addClass('cache');
    }
}

function checkField(id, type, value) {
    var current_val = $("#" + id).val();
    if (type == 'focus') {
        if (current_val == value)
            $("#" + id).val('');
    } else {
        if (current_val == '')
            $("#" + id).val(value);
    }
}

function autocomplete_nomgarantie() {
    var value = $('#garantieNomProduit').val();
    var values = {
        search: value
    };
    if (value.length > 2) {
        $.ajax({
            'type': 'POST',
            'data': values,
            'url': path_relative_root + 'ajax_get_produit.php',
            'async': false,
            'success': function (response) {
                autoCompleteAll(response, 'produit', value);
            }
        });
    }
}

function openPopup(popup_id, shad_id, opacity) {
    if (shad_id == undefined) {
        shad_id = 'shad';
    }
    if (opacity == undefined) {
        opacity = 0.8;
    }
    var $popup = $('#' + popup_id);
    var $shad = $('#' + shad_id);
    if ($shad.length == 0) {
        $popup.before('<div id="' + shad_id + '"></div>');
        $shad = $('#' + shad_id);
        $shad.hide();
    }
    $shad.bind('click.popup', function () {
        closePopup(popup_id, shad_id);
    });
    $shad.add($popup).appendTo('body');
    $popup.css({
        opacity: 0,
        display: 'block'
    });
    $shad.fadeTo('slow', opacity, function () {
        $popup.animate({
            opacity: 1
        }, function () {
            $popup.css({
                opacity: '',
                display: 'block'
            });
        });
    });
    $shad.css({
        display: 'block',
        opacity: 0
    }).animate({
        opacity: 0.8
    }, function () {
        $('#wrapper_sendwishlist').css({
            opacity: ''
        });
        $popup.slideDown('slow');
    });
}

function closePopup(popup_id, shad_id) {
    if (shad_id == undefined) {
        shad_id = 'shad';
    }
    var $popup = $('#' + popup_id);
    var $shad = $('#' + shad_id);
    $shad.unbind('click.popup');
    $popup.fadeOut(function () {
        $shad.fadeOut('slow');
    });
}

function CartShow(added = false) {

    var shadMenu = $('#shad_menu');
    var mainMenu = $('#wrapper_top_menu');
    var cartTop = $('#right_part_cart');

    shadMenu.addClass('actif');
    mainMenu.addClass('shaded');
    cartTop.addClass('hover');
    cartTop.addClass('hasItem');

    if (added) {
        $('#show_top_cart .added_to_basket').show();
    }

    shadMenu.on('click', function() {
        CartHide()
    });

    setTimeout(function () {

        if (cartTop.hasClass('hover')) {

            CartHide();

            $('#show_top_cart .titre_panier').show();
            $('#show_top_cart .added_to_basket').hide();
        }
    }, 3000);
}

function CartHide() {
    var shadMenu = $('#shad_menu');
    var mainMenu = $('#wrapper_top_menu');
    var cartTop = $('#right_part_cart');

    cartTop.removeClass('hover');

    setTimeout(function(){
        if (!isVisible("#right_part_cart .cart_box")) {
            shadMenu.removeClass('actif');
            mainMenu.removeClass('shaded');
        }
    }, 300);
}

function isVisible(selector) {
    const element = document.querySelector(selector);
    if (!element) return false; // If doesn't exist, return false

    const rect = element.getBoundingClientRect();
    const viewportHeight = window.innerHeight;
    const viewportWidth = window.innerWidth;

    return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= viewportHeight &&
        rect.right <= viewportWidth
    );
}

function ToggleCollapsible() {
    var element = $(this);
    var content = element.siblings();
    if (element.hasClass("collapsible-closed")) {
        content.slideDown();
        element.removeClass("collapsible-closed").addClass("select_btn");
    } else {
        content.slideUp();
        element.addClass("collapsible-closed").removeClass("select_btn");
    }
}

function openMultiShad(id, fixed) {
    // Simpler multishad behaviour (optin via shad id)
    if (id == 'nrc_comment') {
        document.getElementById(id).style.zIndex = 1000;

        $("#" + id ).add('#shad').fadeIn(200, function () {
            $('#shad').unbind('click').on('click', function () {
                closeMultiShad(id);
            })
        });

    } else {
        var $lbId = $('#' + id);

        if ($lbId.length) {

            if ($lbId.hasClass('sidebox')) {
                $lbId.addClass('actif');

                if ($('body').hasClass('algolia_on') && (id == 'mention_legales_pop_up')) {
                    $('.algolia_footer #mention_legales_pop_up').addClass('actif').css('top', '14vh');
                    $('#algolia_shade').addClass('displayed');
                    $('#shad').css('z-index', -1);
                }

                if (!$('body').hasClass('noScroll')) {
                    $('body').addClass('noScroll');
                }
            } else {
                $lbId.css({
                        opacity: 0,
                        display: 'block',
                        zIndex: 10000
                    })
                    .animate({
                        opacity: 1
                    }, 350, function () {
                        $lbId.css({
                            opacity: ''
                        });
                    });
                if (fixed == 'fixed') {
                    var calculTop = $(window).scrollTop() + (($(window).height() / 2) - ($lbId.height() / 4));
                    $lbId.css({
                        position: 'absolute',
                        top: calculTop
                    });
                }
            }

            $('#shad').unbind('click')
                .on('click', function () {
                    closeMultiShad();
                }).css({
                    opacity: 0,
                    display: 'block',
                    cursor: 'pointer'
                })
                .animate({
                    opacity: 0.6
                }, 350);

                if ($('body').hasClass('algolia_on') && $('.algolia_footer #mention_legales_pop_up').hasClass('actif')) {
                    $('#algolia_shade.displayed, #content_mention_legales_pop_up span.close_pop').on('click', function () {
                        $('.algolia_footer #mention_legales_pop_up').removeClass('actif');
                        $('#algolia_shade').removeClass('displayed');

                        if ($('body').hasClass('noScroll')) {
                            $('body').removeClass('noScroll');
                        }
                    });
                }

            $('.ais-SearchBox-input').val('');

            if ($('#shad').data('opened_lb')) {
                $('#shad').data()['opened_lb'] += 1;
            } else {
                $('#shad').data()['opened_lb'] = 1;
            }
            if (navigator.userAgent.indexOf('MSIE') != -1 && navigator.userAgent.indexOf('7.') != -1) {
                $('#topMenuConnexionButtonInside').css({
                    zIndex: 1
                });
            }

            if (id == 'desc_pic_zoom') {
                if ($('.pic_holder').length) {
                    let swiper = new Swiper('.pic_holder', {
                        slidesPerView: 'auto',
                        allowSlidePrev: false,
                        allowSlideNext: false,
                        zoom: {
                            maxRatio: 2,
                        },
                    });
                }
            }
        }
    }
}

function closeMultiShad(id) {
    // Simpler multishad behaviour (optin via shad id)
    if (id == 'nrc_comment') {
        $("#" + id).add('#shad').fadeOut(200, function () {
            $('#shad').unbind('click')
        });
    } else {

        var array_id_popup = new Array('corona_notices_pop_up', 'delay_popup', 'corona_pop_up', 'mention_legales_pop_up', 'modbox', 'modbox_parrainage', 'modbox_error', 'popup_savoir_plus', 'sizeguidebox', 'sizeguideboxbague', 'alertbox', 'alertbox1', 'alertboxlogin', 'sendfriendbox', 'popup_inscription_home', 'errbox', 'popup_prix_reference', 'modboxpromo', 'gravurePopup', 'gravurePopupAssoc', 'selectionOffertePopup', 'sendwishlistbox', 'popup_garantie_img', 'popup_batchnbr_img', 'lightbox_stock_alert', 'popup_howto_img', 'lightbox_achat_express', 'configurator_modal', 'selection-offer', 'zoomBox', 'desc_pic_zoom');
        var cnt_id_popup = array_id_popup.length;

        if (!$('#site_global_wrap #product_selects.bundle').length) {
            $('#site_global_wrap #product_selects').show();
        }

        if ($.inArray(id, array_id_popup) == -1 && id != '' && id != undefined) {

            var $lbId = $('#' + id);
            var startIdOpacity = Number($lbId.css('opacity'));

            if ($lbId.hasClass('sidebox')) {
                $lbId.removeClass('actif');

                if ($('body').hasClass('noScroll')) {
                    $('body').removeClass('noScroll');
                }
            } else {
                $lbId.css({
                    opacity: startIdOpacity
                }).animate({
                    opacity: 0
                }, 350, function () {
                    $lbId.css({
                        display: 'none',
                        opacity: ''
                    }).stop().clearQueue();
                });
            }
        } else {
            for (var i = 0; i < cnt_id_popup; i++) {

                if ($('#' + array_id_popup[i]).length && $('#' + array_id_popup[i]).is(':visible')) {

                    var $lbId = $('#' + array_id_popup[i]);

                    var startIdOpacity = Number($lbId.css('opacity'));

                    if ($lbId.hasClass('sidebox')) {
                        $lbId.removeClass('actif');
                    } else {
                        $lbId.css({
                            opacity: startIdOpacity
                        }).animate({
                            opacity: 0
                        }, 350, function () {
                            $lbId.css({
                                display: 'none',
                                opacity: ''
                            }).stop().clearQueue();
                        });
                    }
                }
            }
        }

        var startShadOpacity = Number($('#shade').css('opacity'));
        $('#shade').css({
            opacity: startShadOpacity
        }).animate({
            opacity: 0
        }, 350, function () {
            $('#shade').css({
                display: 'none',
                opacity: ''
            });
            if ($('html').hasClass('ie7')) {
                $('#shade')[0].style.removeAttribute('filter');
                $('html, body').css({
                    overflow: ''
                });
            }
        });

        if ($('body').hasClass('noScroll')) {
            $('body').removeClass('noScroll');
        }

        $('#shad').css('display', 'none');
    }
}

function deplieMsg(id) {
    $('.btn-deplier').removeClass('deplier-opened');
    $('.detail_message').slideUp();
    $('#conversation_msg_' + id).slideDown().parent().find('.btn-deplier').addClass('deplier-opened');
}

$(document).ready(function () {
    $("#connexion_top").click(function (e) {
        if ($(e.target).attr('id') == 'connexion_top')
            window.location = window.ROOT_PATH + create_link('client_index');
    });
});

function showDetailOrder(order_id, order_type) {
    if (show_orders_by_type('orders_by_type', order_type, ROOT_PATH))
        $('#order_' + order_id).click();
    $('html, body').animate({
        scrollTop: ($('[data-btn-name="order_section"]').offset().top - 100)
    }, 500);
}

(function () {
    'use strict';

    function parseArgs(args) {
        var obj = {},
            inc = 0,
            len = args.length;
        for (inc; inc < len; inc += 1) {
            obj['arg' + inc] = args[inc];
        }
        return obj;
    }

    function translate() {
        var o_data = parseArgs(arguments),
            s_resp = arguments[0];
        $.ajax({
            url: path_relative_root + 'ajax_sprintf.php',
            type: 'get',
            data: o_data,
            async: false,
            success: function (res) {
                s_resp = res
            }
        });
        return s_resp;
    }
    window.translate = translate;
}());

function changeLocale(locale, uri, site) {

    $.ajax({
        url: path_relative_root + 'ajax_get_uri_by_lang.php',
        type: 'post',
        data: {
            lang: locale,
            url: uri,
            site : site
        },
        success: function (data) {
            if (data != "") {
                var force = "";
                if (uri == "/") {
                    force = "?force";
                }

                location.href = data + force;
            }
        }
    });

}

// Action BIND de la pop-up car Cl? present dans l'API Google Maps
$(document).ready(function () {
    $(".btn_compte").each(function () {
        var obj = $(this);
        if (!$(this).hasClass('actif')) {
            obj.mouseenter(function () {
                obj.addClass('actif');
            });
            obj.mouseleave(function () {
                obj.removeClass('actif');
            });
        }
    });
});

function actionKeyCacheCache() {
    $('.key_cache_cache').bind('click', function () {
        var $objId = $(this).attr('id');
        openPopupCacheCache($objId);
    });
}

$.fn.new_styling_form = function (option) {
    var $obj = $(this);
    var param = $.extend({
        target_type: 'multi',
        type_class_sufix: 'styled',
        localName_new_elem: 'div',
        new_elem_id: true,
        height: 'auto',
        use_class: 'false',
        select_noValue_color: '#aaa'
    }, option);

    function animeElem(action, styled, height) {
        var useClass = param.use_class == true;
        styled.removeClass('unselected selected btndown');
        if (action == 'inActive') {
            styled.css({
                backgroundPosition: '0 0'
            }).addClass('unselected')
        }
        if (action == 'active') {
            styled.css({
                backgroundPosition: '0 -' + (height * 2) + 'px'
            }).addClass('selected');
        }
        if (action == 'btnDownToActive' || action == 'btnDownToInActive') {
            styled.addClass('btndown');
            if (action == 'btnDownToActive') {
                styled.css({
                    backgroundPosition: '0 -' + (height * 1) + 'px'
                })
            }
            if (action == 'btnDownToInActive') {
                styled.css({
                    backgroundPosition: '0 -' + (height * 3) + 'px'
                })
            }
        }
    }

    $obj.each(function () {
        var $objEach = $(this);
        var eachLocalName = $objEach[0].tagName.toLowerCase();
        var localNameValid = new Array('input', 'select');
        var flag = true;
        var eachType = $objEach[0].type;
        var isInputForm = $.inArray(eachLocalName, localNameValid) >= 0

        var acceptedType = new Array('radio', 'checkbox');
        var isAccepted = $.inArray(eachType, acceptedType) >= 0;
        var idElem = $objEach.prop('id');

        if (!isInputForm || (isInputForm == 'input' && !isAccepted)) {
            flag = false;
        }

        var elemType = param.target_type;
        if (elemType = 'multi') {
            elemType = eachType;
        } else if (elemType != eachType) {
            flag = false;
        }
        if ($objEach.data('isStyled')) {
            flag = false;
        }

        if (flag) {
            var prefix = (eachLocalName == 'input') ? eachType : eachLocalName;
            var styledClass = prefix + '_' + param.type_class_sufix
            if (param.type_class_sufix == '') {
                styledClass = prefix;
            }

            if (!$objEach.prev().is(param.localName_new_elem + '.' + styledClass)) {
                var $styledElem = $(document.createElement(param.localName_new_elem));
                $objEach.before($styledElem);
                $styledElem.addClass(styledClass).css({
                    cursor: 'pointer'
                });
                if (eachLocalName == 'input') {
                    $objEach.css({
                        display: 'none'
                    });
                }
                if (eachLocalName == 'select') {
                    $objEach.css({
                        position: 'relative',
                        opacity: 0,
                        zIndex: 5
                    });
                }
            } else {
                var $styledElem = $(param.localName_new_elem + '.' + styledClass);
            }

            if (param.new_elem_id) {
                $styledElem.prop('id', eachLocalName + $objEach.prop('id'))
            }

            var objHeight = param.height;
            if (objHeight == 'auto') {
                objHeight = $styledElem.height();
            }
            $objEach.data('isStyled', true);
            var objChecked = $objEach.is(':checked');

            if (eachLocalName == 'input') {
                if (objChecked) {
                    animeElem('active', $styledElem, objHeight);
                } else {
                    animeElem('inActive', $styledElem, objHeight);
                }
            }

            if (eachLocalName == 'input') {
                if (eachType == 'checkbox') {
                    $styledElem.on({
                        'mousedown': function (e) {
                            var objChecked = $objEach.is(':checked');
                            if (e.which == 1) {
                                if (objChecked) {
                                    animeElem('btnDownToInActive', $styledElem, objHeight);
                                } else {
                                    animeElem('btnDownToActive', $styledElem, objHeight);
                                }
                            }
                        },
                        'mouseup': function (e) {
                            var objChecked = $objEach.is(':checked');
                            if (e.which == 1) {
                                if (objChecked) {
                                    $objEach.prop('checked', null);
                                } else {
                                    $objEach.prop('checked', 'checked');
                                }
                                $objEach.trigger('change');
                            }
                        }
                    });
                    $objEach.on({
                        'change': function () {
                            var objChecked = $objEach.is(':checked');

                            if (objChecked) {
                                animeElem('active', $styledElem, objHeight);
                            } else {
                                animeElem('inActive', $styledElem, objHeight);
                            }
                        }
                    });

                }
                if (eachType == 'radio') {
                    var eachName = $objEach[0].name;
                    var $otherRadio = $obj.not($objEach).filter('[type=radio][name=' + eachName + ']');

                    $styledElem.on({
                        'mousedown': function (e) {
                            var objChecked = $objEach.is(':checked');
                            if (e.which == 1) {
                                if (!objChecked) {
                                    animeElem('btnDownToActive', $styledElem, objHeight);
                                }
                            }
                        },
                        'mouseup': function (e) {
                            var objChecked = $objEach.is(':checked');
                            if (e.which == 1) {
                                if (!objChecked) {
                                    $otherRadio.prop('checked', null);
                                    $objEach.prop('checked', 'checked');
                                    animeElem('active', $styledElem, objHeight);
                                    $otherRadio.each(function () {
                                        var otherEach = $(this);
                                        animeElem('inActive', otherEach.prev('.' + styledClass), objHeight);
                                    })
                                }
                                $objEach.trigger('change');
                            }
                        }
                    });
                    $objEach.on({
                        'change': function () {
                            var objChecked = $objEach.is(':checked');
                            if (objChecked) {
                                animeElem('active', $styledElem, objHeight);
                                $otherRadio.each(function () {
                                    var otherEach = $(this);
                                    animeElem('inActive', otherEach.prev('.' + styledClass), objHeight);
                                });
                            }
                        }
                    });
                }
            }
            if (eachLocalName == 'select') {
                if ($styledElem.text() == '') {
                    $styledElem.text($('option:selected', $objEach).text());
                }
                if ($('option:selected', $objEach).val() == '' || $('option:selected', $objEach).val() == undefined) {
                    $styledElem.css({
                        color: param.select_noValue_color,
                        fontStyle: 'italic'
                    });
                } else {
                    $styledElem.css({
                        color: '',
                        fontStyle: ''
                    });
                }
                $objEach.on({
                    'change': function () {
                        if ($('option:selected', $objEach).val() == '' || $('option:selected', $objEach).val() == undefined) {
                            $styledElem.css({
                                color: param.select_noValue_color,
                                fontStyle: 'italic'
                            });
                        } else {
                            $styledElem.css({
                                color: '',
                                fontStyle: ''
                            });
                        }
                        $styledElem.text($('option:selected', $objEach).text());
                    }
                })
            }
        }

    });
};

function deplieMsg(id) {
    $('.btn-deplier').removeClass('deplier-opened');
    $('.detail_message').slideUp();
    $('#conversation_msg_' + id).slideDown().parent().find('.btn-deplier').addClass('deplier-opened');
}

$(document).ready(function () {
    $("#connexion_top").click(function (e) {
        if ($(e.target).attr('id') == 'connexion_top')
            window.location = window.ROOT_PATH + create_link('client_index');
    });
});

$(function () {
    if($("body.product_page #content_pdvBox_new").length == 0) {
        $('.styled').new_styling_form({
            type_class_sufix: '',
            localName_new_elem: 'span'
        });
    }
});

$.fn.openSlideTrans = function (target, action, callback) {
    var $obj = $(this);
    var $container = $obj.closest('.bloc_livraison');
    var dataAlt = $obj.data('alt');
    var currentTitle = $obj.data('current');
    var $zoneTxt = $('span', $obj)
    var $target = $(target, $container);
    var callbackInit = function () {
        if (callback && typeof (callback) === 'function') {
            return callback();
        }
    }

    if (action != 'hide') {
        reinitialise();
    }
    if (!$target.is('.open_slide') && action != 'hide') {
        $obj.addClass('alt');
        $target.addClass('open_slide').slideDown(600, function () {
            callbackInit();
        });
        $zoneTxt.html(dataAlt);
    } else {
        $zoneTxt.html(currentTitle);
        $obj.removeClass('alt');
        $target.removeClass('open_slide').slideUp(600, function () {
            reinitialise();
            callbackInit();
        });
    }

    function reinitialise() {
        var $otherTarget = $('.open_slide').not($target);
        if ($otherTarget.length > 0) {
            $otherTarget.removeClass('open_slide').slideUp(600, function () {
                callbackInit();
            });
        }
        var $otherAlt = $('.btn_tunnel.alt').not($obj)
        if ($otherAlt.length > 0) {
            var $otherZoneTxt = $('span', $otherAlt);
            var otherCurrentTitle = $otherAlt.data('current');
            $otherZoneTxt.html(otherCurrentTitle);
        }
    }
}

$.fn.selectChangeAdr = function () {
    var $obj = $(this);
    var obj_val = $obj.val();
    var id_transp = $obj.attr('id');
    var data = {};
    var $changeContainer = $('.wrapper_form_adresse', $obj.closest('.adresse_change'));
    data.act = 'ajout';
    data.id_transp = id_transp;
    data.no_encode_ajax = true;
    data.modif_type = 'ajax';

    if (obj_val != '') {

        if (obj_val != 0) {
            data.idAdresse = obj_val;
            data.act = 'modif';
        }
        if ($changeContainer.is('.show')) {
            $changeContainer.removeClass('show').slideUp().html('');
        }
        $.ajax({
            type: 'get',
            data: data,
            url: path_relative_root + 'livraison-ajax.php',
            success: function (response) {
                var $responce = $(response);
                var min_form = $('.min_form', $responce).html();
                var $form = $('#adresseForm', $responce);
                var all_form;

                $changeContainer.html($form.html(min_form)).css({
                    display: 'none'
                }).slideDown();
                $('.styled').new_styling_form({
                    type_class_sufix: '',
                    localName_new_elem: 'span'
                });
                if (!$changeContainer.is('.show')) {
                    $changeContainer.addClass('show').slideDown()
                }
                try {
                    new DeliveryPage().init();
                } catch (e) {
                    console.log(e);
                }
                initialize_google_place('delivery')
                $('.type_adresse', '.choix_type_adresse.delivery').on('click', function () {
                    $(this).show_adresse_title($('.type_adresse', '.choix_type_adresse.delivery'), '#adresseTitre');
                });

                var country_select = '';

                if (typeof $("#pays").val() != 'undefined') {

                    country_select = $("#pays").find(":selected").data('role').toLowerCase();

                    $("#pays").change(function () {

                        if (typeof $(this).find(":selected").data('role') != 'undefined') {

                            country_select = $(this).find(":selected").data('role').toLowerCase();

                        }

                        if (typeof $("#telephone").val() != 'undefined') {

                            $("#telephone").intlTelInput("setCountry", country_select);

                        }

                        if (typeof $("#mobile").val() != 'undefined') {

                            $("#mobile").intlTelInput("setCountry", country_select);

                        }

                    });

                } else {

                    country_select = "fr";

                }

                if (typeof $("#telephone").val() != 'undefined') {

                    $("#telephone").intlTelInput({
                        utilsScript: path_relative_root + 'js/intltelinput/utils.js',
                        initialCountry: country_select
                    });
                }
                if (typeof $("#mobile").val() != 'undefined') {

                    $("#mobile").intlTelInput({
                        utilsScript: path_relative_root + 'js/intltelinput/utils.js',
                        numberType: "MOBILE",
                        initialCountry: country_select
                    });
                }
            }
        });

    }
};

$.fn.show_adresse_title = function (listObj, target) {
    var $obj = $(this);
    var $listObj = $(listObj);
    var $target = $(target);
    var $wrapperTarget = $target.closest('.address_title');
    var dataVal = $obj.data('value');

    $listObj.removeClass('actif');
    $obj.addClass('actif');

    if ($wrapperTarget.data('height') == null || $wrapperTarget.data('height') == 0) {
        if ($obj.is('.autre') && !$wrapperTarget.is('.show')) {
            $wrapperTarget.css({
                display: 'block',
                opacity: 0,
                position: 'absolute'
            });
            $wrapperTarget.data('height', $wrapperTarget.height());
            $wrapperTarget.css({
                display: 'none',
                opacity: '',
                position: ''
            });

        }

    }
    var adressTitleH = $wrapperTarget.data('height');

    if ($obj.is('.autre')) {
        if (!$wrapperTarget.is('.show'))
            $target.val('');
        if (!$wrapperTarget.is('.show')) {
            $wrapperTarget.addClass('show').css({
                    display: 'block',
                    height: 0,
                    overflow: 'hidden'
                })
                .animate({
                    height: adressTitleH
                }, function () {
                    $wrapperTarget.css({
                        opacity: '',
                        overflow: '',
                        height: ''
                    });
                });
        }
    } else {
        if ($wrapperTarget.is('.show')) {
            $wrapperTarget.removeClass('show').css({
                    display: 'block',
                    height: adressTitleH,
                    overflow: 'hidden'
                })
                .animate({
                    height: 0
                }, function () {
                    $wrapperTarget.css({
                        display: 'none',
                        opacity: '',
                        overflow: '',
                        height: ''
                    });
                    $target.val(dataVal);
                });
        } else {
            $target.val(dataVal);
        }
    }

};

var inAnime = false;
$.fn.advanceSlide = function (to, callback) {

    var $obj = $(this);
    var isVisible = $obj.is(':visible');
    var callbackfn = function () {
        if (callback && typeof callback === 'function') {
            return callback();
        }
        return false;
    }
    if ($obj.data('height') == undefined || $obj.data('height') == 0) {
        if (!isVisible) {
            $obj.css({
                display: 'block',
                opacity: 0,
                position: 'absolute'
            });
        }
        $obj.data('height', $obj.height());
        if (!isVisible) {
            $obj.css({
                display: 'none',
                opacity: '',
                position: ''
            });
        }
    }

    var objH = $obj.data('height');
    if (inAnime == false) {
        inAnime = true;
        if (to == 'show' && !isVisible) {
            $obj.stop().css({
                display: 'block',
                overflow: 'hidden',
                height: 0
            }).animate({
                height: objH
            }, function () {
                $obj.css({
                    overflow: '',
                    height: ''
                });
                inAnime = false;
                callbackfn();
            });
        } else if (to == 'hide' && isVisible) {
            $obj.stop().css({
                display: 'block',
                overflow: 'hidden',
                height: objH
            }).animate({
                height: 0
            }, function () {
                $obj.css({
                    display: 'none',
                    overflow: '',
                    height: ''
                });
                inAnime = false;
                callbackfn();
            });
        } else {
            inAnime = false;
        }
    }
};

function billDisplay(that) {
    var $obj = $('#wrapper_bill');
    var $this = $(that);
    if (!$this.is(':checked')) {
        $obj.stop().slideDown();
    } else {
        $obj.stop().slideUp();
    }
}

function move(element, positionTop) {

    $(element).css('position', 'absolute');
    $(element).stop();
    $(element).css('top', positionTop);

}

$(function () {

    var windowHeight = $(window).height();

    if (windowHeight >= 900) {

        $(window).on('scroll', function () {

            var scrollingItem = $('.scrolling_item'),
                amountScrolled = $(window).scrollTop() + 30, // 30 l'espace entre le haut de la page et scrolling_element
                headerHeight = $('#top_wrapper').height(),
                headerOffsetTop = $('#top_wrapper').offset().top,
                sidebarHeight = scrollingItem.height(),
                leftMenuHeight = $('#left_menu_cat').height(),
                footerPositionTop = $('#copyright').position().top,
                margin = 0,
                positionInBetween = headerOffsetTop;
            positionOnBottom = footerPositionTop - (sidebarHeight + headerHeight + 60); // 60 l'espace entre le bas de la page et le bas de scrolling_item

            if (typeof $('#left_menu_cat').offset() != 'undefined') {
                var leftMenuOffsetTop = $('#left_menu_cat').offset().top;
            }

            // Si on a une class scrolling alors on calcule la hauteur à partir de laquel on stick le scrolling_item
            $('.scrolling_margin').each(function () {
                margin += $(this).height();
            })

            // La valeur par defaut
            if (margin == 0) {
                margin = 60;
            }

            if ($('.wrapper_bandeau').length > 0) {

                var headerHeight = $('#top_wrapper').height() + $('.wrapper_bandeau').height();
                var headerOffsetTop = $('#top_wrapper').offset().top - $('.wrapper_bandeau').height();
                var positionInBetween = headerOffsetTop + $('.wrapper_bandeau').height();
                var positionOnBottom = footerPositionTop - (sidebarHeight + headerHeight + $('.wrapper_bandeau').height() + margin);

            }

            if (amountScrolled >= headerHeight + margin && amountScrolled <= positionOnBottom) {

                move(scrollingItem, positionInBetween);

            } else if (amountScrolled >= positionOnBottom) {

                move(scrollingItem, positionOnBottom);

            } else {

                $('.scrolling_item').css({
                    'position': 'relative',
                    'top': 'auto'
                });

            }

        });

    }

    // page satellite menu sticky aside left

    $(window).scroll(function () {
        if ($(window).scrollTop() > 365) {
            $('#content_wrapper.lamarque #filter_sticky').css('margin-top', '10rem');
        } else {
            $('#content_wrapper.lamarque #filter_sticky').css('margin-top', '0');
        }

                // Récupérer la hauteur totale de la page
        var documentHeight = $(document).height();

        // Récupérer la hauteur de la fenêtre visible
        var windowHeight = $(window).height();

        // Vérifier si on est arrivé au bas de la page
        if ($(window).scrollTop() + windowHeight >= documentHeight) {
            $('#content_wrapper.lamarque #filter_sticky').css('margin-top', '8rem');
        }
    });

    if ($('.wrapper_bloc_banque.sofort .bloc_bank_contents #inputSOFORT').length) {
        $('.wrapper_bloc_banque.sofort .bloc_bank_contents #inputSOFORT').insertAfter('.wrapper_bloc_banque.sofort .bloc_bank_contents .title_wrapper .bank_title span');
    }

    $('.sofort .bank_subtitle').click(function () {
        $('.sofort .bank_subtitle').addClass('loader_sofort');
    });

    $('.giropay .bank_subtitle').click(function () {
        $('#hipay_issuer_bank_id, #btn_cmd_valid').addClass('displayInputGiro');
    });

    $('.giropay #btn_cmd_valid').click(function () {
        $('.giropay #btn_cmd_valid').addClass('loader_giropay');
    });
});

function getOffTop(elm) {

    var out = 0;

    do {

        elm = elm.offsetParent;
        out += elm.offsetTop;
    } while (elm.offsetParent !== null);

    return out;
}

function onAltImgClick(evt) {

    var new_img = this.src.replace('_mini', ''),
        prv_img = document.getElementById('img_large').src.replace('.jpg', '_mini.jpg');

    this.src = prv_img;
    document.getElementById('img_large').src = new_img;
}

function onLookScrollClick() {

    var top = document.getElementById('slide_look_detail').offsetTop;

    $('html, body').animate({
        scrollTop: top
    }, Math.abs(top - $(document).scrollTop()) * 0.8);
}

$(function () {

    $('.main_categ > a', '#menu_wrapper').on('touchend', function (evt) {

        var $this = $(this),
            ssmenu = $this.siblings('.ssmenu'),
            obj = $this.parent();

        if (!ssmenu.is(':visible')) {

            evt.preventDefault();

            obj.siblings('.main_categ').each(function () {

                var sobj = $(this);

                if (sobj.hasClass('actif')) {

                    sobj.removeClass('actif');
                }

                sobj.children('.ssmenu').stop(true, true).css({
                    zIndex: 'auto'
                }).fadeOut(200);
            });

            if (!obj.hasClass('current')) {

                obj.addClass('actif');
            }

            if (!obj.hasClass('out')) {

                ssmenu.stop(true, true).css({
                    zIndex: 110
                }).fadeIn(300);
            } else {

                ssmenu.stop(true, true).css({
                    zIndex: 110
                }).show();
            }

            $('.content_ssmenu', obj).each(function () {

                if ($('div.lazypic', this).length > 0) {

                    if ($('div.lazypic', this).children('img').length <= 0) {

                        loadLazyPic.call($('div.lazypic', this).get(0));
                    }
                }

                $('.ss_cat', this).css({
                    minHeight: $(this).height() - 20
                });
            });
        }
    });

});

function motifChange(motif) {
    var motifValue = motif.value;
    var divBtnPrintPdf = $("#btnPrintPdf");

    if (motifValue == '0') {
        divBtnPrintPdf.css('display', 'none');
    } else {
        divBtnPrintPdf.css('display', 'block');
    }
}

function motifChangeDiv(motif) {
    var motifValue = motif.value;
    var divBtnPrintPdf = $("#divBtnPrintPdf");

    if (motifValue == '0') {
        divBtnPrintPdf.css('display', 'none');
    } else {
        divBtnPrintPdf.css('display', 'block');
    }
}

// Ajoute l'id de la couleur du produit en ancre du lien
function addAncreColor(color_id) {

    var a = $(this).closest("a"),
        tab_href = a.attr("href").split(".html"),
        new_href;

    if (typeof a !== 'undefined' && typeof color_id !== 'undefined') {

        new_href = tab_href[0] + ".html#c=" + color_id;
        a.attr("href", new_href);
    }
}

function removeAncreColor() {

    var parent_bloc = $(this).closest("a.block_lnk"),
        href = parent_bloc.attr("href");

    //S'il y a une ancre couleur on la supprime
    if ((/#c=[0-9]+/).test(href)) {
        parent_bloc.attr("href", href.replace(/#c=[0-9]+/, ""));
    }
}

function displayShade() {
    $('#shade').css('z-index', '50');
    $('#shade').fadeIn();
}

function hideShade() {
    $('#shade').fadeOut();
    $('#shade').css('z-index', '100');
}

function animate_faq_title(el) {
    $('html, body').animate({
        scrollTop: $(el).offset().top - $('#site_head_wrap').height() - 5
    }, 200);
}

/**
 * Display lightbox contact store
 */
function showLightboxContactStore() {

    $('.w-input-element').attr('value','');

    var shad = document.getElementById('shad');
    var modbox = document.getElementById('pdvStoreBox');

    $('html, body').animate({
        scrollTop: 0
    }, 500);

    if ($(modbox).hasClass('sidebox')) {
        if (!$('body').hasClass('noScroll')) {
            $('body').addClass('noScroll');
        }
    }

    $(modbox).addClass('actif');
    $(shad).addClass('actif');
    $(shad).on('click', closeLightboxContactStore);

    setTimeout(function () {
        $('.inUse').attr('value','');
        $('.clientPhone').intlTelInput();
        $('.clientPhone').attr('placeholder', '');
    }, 100);
}

/**
 * Close lightbox contact store
 */
function closeLightboxContactStore() {

    var shad = document.getElementById('shad');
    var modbox = document.getElementById('pdvStoreBox');

    if ($('body').hasClass('noScroll')) {
        $('body').removeClass('noScroll');
    }

    $(modbox).removeClass('actif');
    $(shad).removeClass('actif');
}

/**
 * Send request contact to store
 */

function sendFormContactByStore(formulaire) {
    formulaire == undefined ? formulaire = document.querySelector('.w-contact-form') : formulaire = formulaire;

    var optin_g = ($("input[name='optin']").val());

    if (checkRequiredInputs(formulaire)) {
        var data = {
            'produit_id': formulaire.produit_id.value,
            'produit_url': formulaire.produit_url.value,
            'client_nom': formulaire.nom.value,
            'client_prenom': formulaire.prenom.value,
            'client_email': formulaire.email.value,
            'client_telephone': formulaire.tel.value,
            'client_message': formulaire.message.value,
            'magasin_id': formulaire.magasin_id.value,
            'optin_general': optin_g,
        };

        $.ajax({
            url: path_relative_root + create_link('ajax_contactbystore'),
            type: 'post',
            data: data,
            async: false,
            success: function (res) {
                $('#content_pdvBox_new').html(JSON.parse(res));
                $('#pdvStoreBox, #pdvBox').addClass('thanks');
            }
        });
    }

    return false; //prevent submit
}

// Function to check if required inputs in form are filled, otherwise add class error
function checkRequiredInputs (el) {
    el != undefined ? el = $(el) : el = $('.w-contact-form');

    var requiredFields = el.find('[required]');
    var isValid = true;

    requiredFields.each(function () {
        var input = $(this);

        if (input.val() === '') {
            input.addClass('inputErr');
            isValid = false;
        } else {
            if (input.hasClass('inputError')) {
                input.removeClass('inputErr');
            }
        }
    });

    return isValid;
}

function form_animatePlaceholder(form) {
    if ($(form).length) {
        var inputFields = $(form).find('input, textarea');

        inputFields.each(function () {
            var input = $(this);
            var placeholder = input.parent().find('.placeholderTxt');

            input.on('focus', function () {
                input.addClass('inUse');
                placeholder.addClass('inUse');

                if (input.hasClass('clientPhone') || input.hasClass('numTelephone')) {
                    input.parent().next(placeholder).addClass('inUse');
                }
            });

            input.on('blur', function () {
                if (input.val() === '') {
                    placeholder.removeClass('inUse');

                    if (input.hasClass('clientPhone') || input.hasClass('numTelephone')) {
                        input.parent().next(placeholder).removeClass('inUse');
                    }
                }
            });
        });

    }
}

$(function () {

    // focus label input text #pdvStoreBox
    if ($('.w-contact-form').length) {

        form_animatePlaceholder('.w-contact-form');
    }

    if ($('#sliderBandoHeader .swiper-slide').length > 1) {

        var bandoHeader = new Swiper('#sliderBandoHeader', {
            centeredSlides: true,
            loop: true,
            slidesPerView: 'auto',
            speed: 2200,
            autoplay: {
                delay: 4500,
                disableOnInteraction: false,
            },
            autoplayDisableOnInteraction: false
        });

    }

    //
    if ($('#descrip_store').length && $('#descrip_store').text().length > 500) {

        var description = $('#descrip_store');
        var trigger = $('#descrip_store .trigger');
        var originH = description.height();

        description.css('height', '120px');
        trigger.show();

        trigger.on('click touch', function(){
            if($(this).hasClass('open')){
                description.css('height', '120px');
                trigger.removeClass('open');
                trigger.text(translate('read_more'));
            } else {
                description.css('height', (originH + 30) + 'px');
                trigger.addClass('open');
                trigger.text(translate('read_less'));
            }
        });
    }

    //swiper storelocator gestion du parc - img de la boutique
    if ($('#store_slider .swiper-slide').length > 0) {

        var storeSliderWrapper = new Swiper(".store_slider_wrapper", {
            slidesPerView: 'auto',
            spaceBetween: 24,
            roundLengths: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });

    }

    // swiper module image + text + list
    if ($('#swiperImageTextList .swiper-slide').length > 0) {
        var swiperImageTextList = new Swiper("#swiperImageTextList", {
            slidesPerView: 1,
            roundLengths: true,
            loop: true,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
            pagination: {
                el: ".swiper-pagination",
                type: "progressbar",
            },
        });
        // When clicking on next/prev buttons, scroll to top of module
        $('#swiperImageTextList .swiper-button').on('click', function () {
            var headerTotalHeight = $('.promo_bar').height() + $('.background_topnav').height() + $('.main_menu_wrapper').height();

            $('html, body').animate({
                scrollTop: $('#swiperImageTextList').offset().top - headerTotalHeight
            }, 500);
        });
    }
});

$(function () {
    if ($('#caracPanel .swiper-slide').length > 0) {
        window.addEventListener("load", function(){
            var attributes_img = new Swiper('#caracPanel .attributes_img ', {
                slidesPerView: 8,
                speed: 600,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
            });
        })
    }
});

/*# FIX SIDE NAV #*/
$(document).ready(function () {
    fix_elements();
    anchor_smooth_scroll();
});

window.onscroll = function () {
    fix_elements();
};

function fix_elements() {
    fix_filter('.fix_product_info_wrapper', '#site_head_wrap', true, 94);
}

function fix_filter(s_element, s_offset, recalc, h) {
    if ($(s_element).length) {
        if (recalc) {
            $(s_element).stick_in_parent({
                inner_scrolling: false,
                offset_top: h,
                recalc_every: 1
            });
        } else {
            $(s_element).stick_in_parent({
                inner_scrolling: false,
                offset_top: h
            });
        }
    }
}

function anchor_smooth_scroll() {
    // Select all links with hashes
    $('a[href*="#"]')
        // Remove links that don't actually link to anything
        .not('[href="#"]')
        .not('[href="#0"]')
        .click(function (event) {
            // On-page links
            if (
                location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
                location.hostname == this.hostname
            ) {
                // Figure out element to scroll to
                var target = $(this.hash);
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                // Does a scroll target exist?
                if (target.length) {
                    // Only prevent default if animation is actually gonna happen
                    event.preventDefault();
                    $('html, body').animate({
                        scrollTop: target.offset().top
                    }, 1000, function () {
                        // Callback after animation
                        // Must change focus!
                        var $target = $(target);
                        $target.focus();

                        if ($target.is(":focus")) { // Checking if the target was focused
                            return false;
                        } else {
                            $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
                            $target.focus(); // Set focus again
                        };
                    });
                }
            }
        });
}

/* Menu top la marques */

$(document).ready(function () {

    $("#menu_1 #ssmenu_1 .submenu #nos_metiers").mouseover(function () {
        $('.img_display .img_01').addClass('nos_metier_1');
        $('.img_display .img_02').addClass('nos_metier_2');
    });
    $("#menu_1 #ssmenu_1 .submenu #nos_metiers").mouseout(function () {
        $('.img_display .img_01').removeClass('nos_metier_1');
        $('.img_display .img_02').removeClass('nos_metier_2');
    });

    $("#menu_1 #ssmenu_1 .submenu #nos_crea_hesp").mouseover(function () {
        $('.img_display .img_01').addClass('nos_creation_1');
        $('.img_display .img_02').addClass('nos_creation_2');
        $('.img_display .txt_img_menu').css('display', 'block');

    });
    $("#menu_1 #ssmenu_1 .submenu #nos_crea_hesp").mouseout(function () {
        $('.img_display .img_01').removeClass('nos_creation_1');
        $('.img_display .img_02').removeClass('nos_creation_2');
        $('.img_display .txt_img_menu').css('display', 'none');
    });

    $('.store_details_right .read_more').click(function () {
        $('.store_details_right .store_description').css({"height": "auto"});
        $(this).css({"display": "none"})
    });

    if ($('.cart_product_line').length) {
        getWishlistProducts();
    }
});

/* Active menu la marques*/
var loc = window.location.pathname;
$('.menu_ctn .dropdown-btn').find('a').each(function () {
    $(this).toggleClass('actived_link', $(this).attr('href') == loc);
});

$('.menu_ctn .dropdown-btn').each(function () {
    if ($(this).find('a').hasClass('actived_link')) {
        $(this).addClass('actived_link');
    }
    if ($(this).hasClass('actived_link')) {
        $(this).children().slideDown();
    }
});

$('.menu_ctn .dropdown-btn').find('a').each(function () {
    if ($(this).find('a').attr('href') == loc) {
        $(this).addClass('actived_link');
    }
});

/* menu gauche pages la marques */

var sPath = window.location.pathname;
var sPage = sPath.substring(sPath.lastIndexOf('/') + 1);
if (sPage === "designer.html") {
    $('#nos_metiers_01').slideDown();
}

$('.menu_ctn .sidenav .dropdown-btn').each(function () {
    $(this).click(function () {
        var elems = ["la_marque_01", "nos_metiers_01", "creations_hesperide_01", "marque_nos_produits", "marque_nos_lignes_phares"];
        var checkMenu = $(this).children().attr('id');
        $('#' + checkMenu).slideDown();
        for (var i = 0; i < elems.length; i++) {
            if (elems[i] !== checkMenu) {
                $('#' + elems[i]).slideUp();
            }
        }
    });
});

/* Slider pages nos creations*/
if ($('.swiper-container.nos_creations .swiper-slide').length > 1) {
    var swiper = new Swiper('.swiper-container.nos_creations', {
        speed: 600,
        parallax: true,
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
            renderBullet: function (index, className) {
                return '<span class="' + className + '">' + (index + 1) + '</span>';
            },
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });
} else {
    $('.swiper-container.nos_creations .paginations').css('display', 'none');
}

/* PAGE ENTRETIEN PRODUITS START */
function customDropdown(stringClassName) {
    var acc = document.getElementsByClassName(stringClassName);
    var i;

    for (i = 0; i < acc.length; i++) {
        acc[i].onclick = function () {
            var removeElemName = this.nextElementSibling.className;
            $('.' + removeElemName).removeClass('show');
            $('.' + stringClassName).removeClass('active');
            this.classList.toggle("active");
            this.nextElementSibling.classList.toggle("show");
            if ($(this).hasClass('active') && this.nextElementSibling.className.indexOf("show") == -1) {
                $(this).removeClass('active');
            }
        }
    }
}

function customDropdn(stringClassName) {
    var acc = document.getElementsByClassName(stringClassName);
    var i;

    for (i = 0; i < acc.length; i++) {
        acc[i].onclick = function () {
            var element_class = $(this).attr("class");
            var theclass = element_class.split(' ')[0];
            if ($(this).hasClass('open')) {
                $(this).removeClass('open');
                $('.faq_content.show li .' + theclass + '.elements .subcontenu').removeClass('open');
                $('.faq_content.show li.open .' + theclass + '.elements').removeClass('open');
                $(this).parent('li').removeClass('open');
            } else {
                $('.' + stringClassName).removeClass('open');
                $('.' + stringClassName).parent('li').children('.' + theclass + '.elements').removeClass('open');
                $('.' + stringClassName).parent('li').children('.' + theclass + '.elements .subcontenu').removeClass('open');
                $('.' + stringClassName).parent('li').removeClass('open');
                $(this).parent('li').addClass('open');
                this.classList.toggle("open");
                $('.faq_content.show li.open .' + theclass + '.elements').addClass('open');

                setTimeout(function () {
                    var topPos = $('.faq_content.show li.open').offset().top - 160;
                    $('html, body').animate({
                        scrollTop: topPos
                    }, 600);
                }, 500);
            }
        }
    }
}

$(function () {
    customDropdown('picto_faq');
    customDropdn('contenu');
    customDropdn('subcontenu');
    /* PAGE ENTRETIEN PRODUITS END */
    //Popup delais page garantie
    setTimeout(function(){
        if ($('body #delay_popup').length != 0) {
            openPopup('delay_popup', 'shade', 0.6);
        }
    }, 300)

    $('#search_home').keyup(function(){
        removeBGContact();
    });

    panier_tg_swiper();
    moveInfoTransporteur();
    moveModifBtn();
});

/***** */

function btnLoaderTrigger(evt) {

    "use strict";

    var element = $(this);
    var loader = $(this).next(".loader");

    if (loader.length > 0) {

        evt.preventDefault();

        loader.removeAttr("style").parent().addClass("loading");
        loader.removeClass("cache");
        loader.addClass("loading");

        setTimeout(function () {
            loader.parent().removeClass("loading");
            loader.addClass("cache");
            loader.removeClass("loading");
        }, 1200);

        if ($(this).attr("href")) {

            window.location.assign($(this).attr("href"));
        }

        // Dans le cas où il y a plusieurs bouton  en loading
        window.setTimeout(function () {
            $('.form_submit.loading').removeClass('loading')
        }, 5000);
        // Not sure what it's for but this triggers an infinite loop
    }
}

// Fonction qui va servir à stoper l'autoplay du swiper avec un écouteur d'événement pour être sûr que cela se produise. Il est nécéssaire de revoir l'initialisation du swiper qui se déroule en premier lieu dans le fichier order_basket de l'app.

function stopExistingSwiperAutoplay() {
    var swiperContainer = document.getElementById('cart_slider_wrapper');
    if (swiperContainer && swiperContainer.swiper) {
        var existingSwiper = swiperContainer.swiper;
        if (existingSwiper.autoplay) {
            existingSwiper.autoplay.stop();
        }

        existingSwiper.on('autoplayStart', function() {
            existingSwiper.autoplay.stop();
        });
    }
}

function panier_tg_swiper() {
    // Initialisation du slider si présent
    var swiperContainer = document.getElementById('cart_slider_wrapper');

    if (swiperContainer) {
        setTimeout(function(){
            homeSwiper = new Swiper('#cart_slider_wrapper', {
                slidesPerView: 3,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                breakpoints: {
                    1025: {
                        slidesPerView: 2,
                    }
                }
            });
            $('#cart_slider_wrapper').animate({opacity: 1},500);
        }, 1500);
    }
    stopExistingSwiperAutoplay();
}

// retire background image sur page contact
function removeBGContact() {
    var $search = $('#search_home');
    var $footerContact = $('body.footer_contact');

    if ($footerContact.length === 0) {
        return;
    }

    if ($search.val().length > 2) {
        $footerContact.addClass('sc-show');
    } else {
        if ($footerContact.hasClass('sc-show')) {
            $footerContact.removeClass('sc-show');
        }
    }
}

// Apparition switchlang wrapper

$(document).ready(function(){
    $('#switchLangTrig').click(function() {
        $('.switchLangWrapper').toggle(); // no animation.
        if ($('#switchLangTrig').hasClass('switchLang activeLangWrap')) {
            $('#switchLangTrig').removeClass('activeLangWrap');
        } else {
            $('#switchLangTrig').addClass('activeLangWrap');
        }
    });
});

// fermer la pop-in lorsqu'on clique en dehors
if ($('.switchLangWrapper').length) {
    $(document).mouseup(function (e) {
        var container_lang = $(".switchLangWrapper");
        var current_lang = $("#switchLangTrig");

        if (!container_lang.is(e.target) && container_lang.has(e.target).length === 0 && !current_lang.is(e.target) && current_lang.has(e.target).length === 0) {
            $('.switchLangWrapper').css("display", "none");
            $('#switchLangTrig').removeClass('activeLangWrap');
        }
    });
}

// deplace bloque "info_transporteur"
function moveInfoTransporteur() {
    var tunnelRightCol = $('body.in_tunnel #tunnel_right_col');
    var infoTrans = tunnelRightCol.find('.info_transporteur');

    if (infoTrans.length) {
        infoTrans.detach().prependTo(tunnelRightCol);
    }

    thisfunctiontest();
}

// deplace cta modifier en dehors du div "address"
function moveModifBtn() {
    var tunnelRightCol = $('body.checkout #tunnel_right_col');
    var addrWrapper = tunnelRightCol.find('.wrapper_payment .payment_addr_wrapper');

    if (addrWrapper.length) {

        addrWrapper.each(function(idx) {
            var btnModif = $(this).find('.address').children('.address_mod');

            if (btnModif.length) {
                btnModif.detach().appendTo($(this));
            }
        });
    }
}

(function () {
    "use strict";
    var stickyCnt = 0;
    function initStickies() {
        var stickies = document.getElementsByClassName("sticky");
        Array.prototype.slice.call(stickies).forEach(function (sticky) {
            if (sticky.id !== "site_head_wrap") {
                if (sticky.id === "") {
                    stickyCnt += 1;
                    sticky.id = "sticky-" + stickyCnt;
                }
                sticky = new ScrollLock(sticky.id);
            }
        });
    }

    $(initStickies);
}());

function productVisitedSwiper(slidePerView) {
    if ($('#productVisitedSwiper').length > 0) {
        var productVisitedSwiper = new Swiper('#productVisitedSwiper', {
            spaceBetween: 45,
            slidesPerView: slidePerView,
            loopedSlides: 0,
            navigation: {
                prevEl: '.prev_prod_visited_slider',
                nextEl: '.next_prod_visited_slider',
                hiddenClass: '.swiper-button-hidden'
            },
            on: {
                init: function () {
                    $('.assoc_prev, .assoc_next', '#gondole_derniers_articles_vus').removeClass('cache');
                },
            },
            breakpoints: {
                1400: {
                    slidesPerView: 4
                },
                1024: {
                    slidesPerView: 3
                }
            }
        });
    }
}

// Overide de la fonction de base pour corriger le bug des boutons de navigation
$(function () {
    if ($('.sliderCover').length) {
        $('.sliderCover').each(function () {
            if ($(this).find('.swiper-slide').length > 1) {
                var home_slider = new Swiper($(this), {
                    spaceBetween: 30,
                    slidesPerView: 1,
                    loopedSlides: 0,
                    loop: true,
                    autoplay: {
                        delay: 12000,
                    },
                    navigation: {
                        nextEl: $(this).find('.swiper-control-container .swiper-button-next'),
                        prevEl: $(this).find('.swiper-control-container .swiper-button-prev')
                    }
                });
            } else if ($(this).find('.swiper-slide').length < 2) {
                $(this).find('.swiper-control-container').css('display', 'none');
            }
        });
    }
});

$(function () {
    createSeeMoreSEO('.district_def');
    createSeeMoreSEO('.wrapper_bandeau.bandoBottom .txt_content');
})

function build_text_summary(text_el, max_characters) {
    var text = text_el.html();
    if (text.length > max_characters) {
        //slice text to defined length
        var summary = text.slice(0, max_characters);
        //check for slice through html tag
        var position = summary.lastIndexOf('<');
        if (position) {
            var check = summary.substring(position);
            //fix slicing if in middle on html tag
            if (check !== '' && check.indexOf('>') == -1) {
                //slice text to correct length
                summary = summary.slice(0,position);
                //remove <br> at summaryning and end of text
                summary = summary.replace(/^(\s*<br\s*\/?\s*>\s*)*|(\s*<br\s*\/?\s*>\s*)*\s*$/gm, '')
            }
        }
        summary = fix_unclosed_tags(summary, '...');
        return summary;
    }
    return text;
}

// Create a see more button for texte over 500 units for SEO texts
function createSeeMoreSEO(selector) {
    var text_element = $(selector);
    var max_characters = 500;

    if (text_element.length > 0) {
        text_element.each(function () {
            var text = $(this).html();

            if (text.length > max_characters) {
                var summary = build_text_summary($(this), max_characters);

                $(this).css('display','none');
                $('<div class="rayon_desc_summary">'+summary+' <a class="readmore">' + Translator.translate('know_more') + '</a></div>').insertBefore($(this));
            }
        });

        $(document).on('click', '.readmore', function () {
            $(this).parent().hide();
            $(this).parent().next().show().fadeIn(400).slideDown(400);
        });
    }
}

function fix_unclosed_tags(str, end_string) {
    var tags = ["a", "span", "div", "ul", "li", "h1", "h2", "h3", "h4", "h5", "h6", "p", "table", "tr", "td", "b", "i", "u", "strong"];
    var mismatches = [];
    var closing_tag = false;
    tags.forEach(function(tag) {
        var pattern_open = '<'+tag+'( |>)';
        var pattern_close = '</'+tag+'>';

        var diff_count = (str.match(new RegExp(pattern_open,'g')) || []).length - (str.match(new RegExp(pattern_close,'g')) || []).length;

        if (diff_count != 0) {
            closing_tag = true;
            str = str + end_string + '</'+tag+'>';
        }
    });

    if (closing_tag == false) {
        str = str + end_string;
    }

    return str;
}

$(function () {
    var bandeau_bloc = $(".promo_bar");
    if (bandeau_bloc.length > 0) {
        bandeau_bloc.find('.close_promo').on('click tap', bandeau_closed);
    }
    $("#sendfriend_prodinfos").overlayScrollbars({ /* your options */ });
});

function bandeau_go_away() {
    // On retire la classe au body car la barre promo a une certaine taille (menu fix)
    // Et quand on le supprime le padding du body doit être différent (en rapport au menu)
    $('body').removeClass('promo_on');
    $(".promo_bar").slideUp(500, function () {
        $(this).remove();
    });

    /**
     * On retire la classe 'hasBandeauHeader' au body
     * quand on retire l'élément 'promo_bar'
     *  */
    if ($('body').hasClass('hasBandeauHeader')) {
        $('body').removeClass('hasBandeauHeader');
    }
}

var bandeau_closed = function (evt) {
    var d = new Date();
    d.setTime(d.getTime() + (90 * 24 * 60 * 60 * 1000));
    var expires = "expires=" + d.toUTCString();

    document.cookie = "bandeau_status=closed;" + expires;

    if (evt.type === "click" || evt.type === "tap") {
        if (evt.currentTarget.classList.contains("close_promo")) {
            evt.preventDefault();
            bandeau_go_away();
            return false;
        }
    } else {
        setTimeout(bandeau_go_away, 10000);
    }
};

function changeToVideo(videoId){
    if ($('body.product_page  #zoom_div .fp_img_content .video-responsive').length < 1){
        $('body.product_page  #zoom_div #img_large').hide();
        $('<div class="video-responsive"><div class="popUp" onclick="zoomImg(this)"></div><iframe src="https://player.vimeo.com/video/'+videoId+'?autoplay=1&controls=0&loop=1&autopause=0&playsinline=1&fun=0&muted=1" class="otherViewImg" style="border: none;" width="355" height="532" border="0" allow="autoplay"></iframe></div>').appendTo('body.product_page  #zoom_div .fp_img_content');
    } else {
        $('body.product_page  #zoom_div #img_large').hide();
        $('body.product_page  #zoom_div .video-responsive').show();
    }
}
$('#btn_video_produit #btn_video_produit_overlay').each(function(){
    var dataId = $(this).attr("data-video-id");
    changeToVideo(dataId);
});

function updateToVideo(thiss){
    if ($('#wrapper_zoombox #bloc_photo_zoom div #fp_img_content').length < 1){
        $('#wrapper_zoombox #bloc_photo_zoom img.no_border').remove();
        $('#wrapper_zoombox #bloc_photo_zoom #img_tmp').remove();
        $(thiss).parent().clone().appendTo('#wrapper_zoombox #bloc_photo_zoom div + div');
        if ($('#wrapper_zoombox #bloc_photo_zoom div + div').length < 1){
            $(thiss).parent().clone().appendTo('#wrapper_zoombox #bloc_photo_zoom div');
        }
    } else {
        //Do nothing
    }
}

var mon_compte = Translator.translate('my_account');
var newlogin_title_1 = Translator.translate('newlogin_title_1');
var account_quick_access = Translator.translate('account_quick_access');

setTimeout(function(){
    $(".satellite_store_details  .footer_rassu_wrapper .rassurance .bloc .title").find("h2").contents().unwrap();
    $(".newlogin_page .login_bloc .title h2").replaceWith(('<h1 class="mon_compte">' + mon_compte + '</h1>'));
    $(".newlogin_page .login_bloc .title.my_account p.subtitle").wrapInner("<h2></h2>");
    $(".customer.body_login .footer_rassu_wrapper .rassurance .bloc .title").wrapInner("<h3></h3>");

    $('.order_cgv .content h4').each(function() {
        var order_cgvh4 = $(this);
        $(this).replaceWith('<div class="h4Title">' + order_cgvh4.text() + '</div>');
    });

    $('.footer_legalmentions  .wrapper_content_sat .content h3').each(function() {
        var footer_legalmentionsh3 = $(this);
        $(this).replaceWith('<div class="h3Title">' + footer_legalmentionsh3.text() + '</div>');
    });

    $('.footer_legalmentions  .wrapper_content_sat .content h4').each(function() {
        var footer_legalmentionsh4 = $(this);
        $(this).replaceWith('<div class="h3Title">' + footer_legalmentionsh4.text() + '</div>');
    });

    $('#cookiesLightbox section h4').each(function() {
            var cookiesh4 = $(this);
            $(this).replaceWith('<p class="h4style">' + cookiesh4.text() + '</p>');
    });

    fixarrow_height();

}, 1000 );

function livraisonXmas() {
    var now = Math.floor( Date.now() / 1000 );

    if ( $( '#timestamp_check' ).length > 0 ) {
        now = $( '#timestamp_check' ).attr('data-date');
    }

    if ( now >= 1667430000 && now < 1671231600 ) {
        $('.left_side.elem_tunnel .wording_choice_delivery:first-of-type').after('<p class="xmas_delivery">' + Translator.translate( 'livraison_garantie' ) + '</p>');

    //  17/12 à 20/12
    } else if ( now >= 1671231600 && now < 1671577200 ) {
        if ($("body").hasClass("fr")) {
            $('.left_side.elem_tunnel .wording_choice_delivery:first-of-type').after('<p class="xmas_delivery">' + Translator.translate( 'xmas_delivery' ) + '</p>');
        }
    }
}

if ($('body').hasClass('step_2') && $('.left_side.elem_tunnel').length) {
    livraisonXmas();
}

function newshowmodal(products, type, root, id_produit, is_not_produit) {
    topCartAppear();
    window.setTimeout(function () {
        topCartDesappear();
    }, 2000);
}

function topCartAppear() {
    var cart_top = $('#cart_top');
    cart_top.addClass('active_rollover')
    $('#show_top_cart #order_basket_list').overlayScrollbars({
        scrollbars: {
            autoHide: "leave",
            autoHideDelay: 0,
        }
    });

    $('#shad_menu').addClass("actif");
}

function topCartDesappear() {
    var cart_top = $('#cart_top');
    cart_top.removeClass('active_rollover')
    $('#shad_menu').removeClass("actif");
}

function triggerShad(shad, action) {
    shad = shad ? ('#shad_' + shad):'#shad' ;
    if (action && action == 'add') {
        $(shad).addClass('actif');
    } else if (action) {
        $(shad).removeClass('actif');
    } else {
        return;
    }
}

function btnLoaderUnloading(btn) {
    "use strict";
    var loader = btn.next(".loader");
    if (loader.length > 0) {
        setTimeout(function(){loader.removeAttr("style").parent().removeClass("loading")}, 200);
    }
}

function showCartDesappear() {
    $('#right_part_cart').removeClass('hover');
    $('#show_top_cart').css('left', '100%');
    setTimeout(function() {$('#show_top_cart').removeAttr('style')}, 500);
}

/**
 * @description Changes the numbers in the wishlist pad
 * @author  Stefane DR  <sdr@wshop.com>
 * @since   2023-01-25
 * @param   {integer}   nb
 */
function changeWishlistNb(nb) {
    nb = parseInt(nb);

    $('#right_part_wishlist .amounts_bubble.wishlist span').html(nb);
    if (nb == 0) {
        $('#right_part_wishlist .amounts_bubble.wishlist').addClass('cache');
    } else if ($('#right_part_wishlist .amounts_bubble.wishlist').hasClass('cache')) {
        $('#right_part_wishlist .amounts_bubble.wishlist').removeClass('cache').show();
    }

    // if we're on the "My Wishlist" page
    if ($('body.wishlist').length > 0) {
        if (nb == 0) {
            $('.wrapper_content_wishlist .intro').removeClass('cache').show();
        } else if ($('.wrapper_content_wishlist .intro').hasClass('cache')) {
            $('.wrapper_content_wishlist .intro').addClass('cache');
        }
    }
}

// Called upon size change in product page
function onItemSizeChange(form, is_regroup, itm_rr_id) {

    var control;
    var isWishList = $('#wishlist').length > 0;
    var isAchatExpressV2 = ($('#is_achat_express_v2').val() == 1 && !isWishList);
    var productId = form.getValue('produit_id');
    var elem = isAchatExpressV2 ? '_' + productId + itm_rr_id : '';
    var productRef = form.getValue('produit_ref');
    control = form.elements.namedItem('tailleProd');
    control.value = this.value.trim();
    var from  = ($('#is_from_lookbook').val() !== undefined) ? 'lookbook' : 'product';
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';

    if (isAchatExpressV2) {
        if (this.checked) {
            if (window.dataLayer !== undefined) {
                window.dataLayer.push({
                    'event': 'changeSize',
                    'produit': {
                        'size_value': ($(this).attr('data-nom')),
                    }
                });
                if (window.dataLayer[0].product) {
                    window.dataLayer[0].product.size = ($(this).attr('data-nom'));
                }
            }

            if ($('#sizeerror').is(':visible')) {
                $('#sizeerror').slideUp();
            }

            loadProduct.call(form, productId, 'taille', from, itm_rr_id, 'sizeChange');

            // Depending on the color we see if the product is available, do this only in product page
            // We check if the product is already in the wishlist
            checkProductInWishlist(productId, productRef, true);
        }
    } else {
        if (window.dataLayer !== undefined) {
            window.dataLayer.push({
                'event': 'changeSize',
                'produit': {
                    'size_value': ($(this).attr('data-nom')),
                }
            });
            if (window.dataLayer[0].product) {
                window.dataLayer[0].product.size = ($(this).attr('data-nom'));
            }
        }

        if ($('#sizeerror').is(':visible')) {
            $('#sizeerror').slideUp();
        }

        if (is_regroup) {
            loadProduct.call(form, productId, 'taille', from, itm_rr_id, 'sizeChange');
        }

        // DO NOT DELETE COMMENT IN CASE EVO REGROUP NOT DONE *************************************************************************************
        // Depending on the color we see if the product is available, do this only in product page
        // We check if the product is already in the wishlist
        checkProductInWishlist(productId, productRef, true);
    }
}

/**
 *
 * @param productId
 * @returns {boolean}
 */
function checkProductInWishlist(productId, productRef, enableBtnWishlist = false) {
    var isProductPage = $("#produit_id").val();
    var colorId = $("#couleurProd_" + productId).val();
    var sizeId = $("#tailleProd_" + productId).val();
    if (isProductPage > 0) {
        productId = $("#produit_id").val();
        colorId = $(".input_check.color:checked").val();
        sizeId = $('.size input[name=itm_size]:checked').val();
    }
    if (typeof colorId == "undefined") {
        colorId = 0;
    }
    if (typeof sizeId == "undefined") {
        sizeId = 0;
    }

    if (typeof productId != "undefined" && typeof colorId != "undefined" && typeof sizeId != "undefined") {
        $.ajax({
            type: 'get',
            url: path_relative_root + create_link("get_user_wishlist"),
            data: {'product_id': productId, 'size_id': sizeId, 'color_id': colorId},
            success: function (response) {
                var userWishlist = JSON.parse(response);

                var wishlistButton = $("#addToWishlistButton_" + productId);
                if (isProductPage > 0) {
                    wishlistButton = $("#addToWishlistButton");
                }
                if (typeof userWishlist !== undefined && userWishlist.length > 0) {

                    changeBtnAddWishlist(true, productRef, enableBtnWishlist);
                    $("#addToWishlistButtonTxt").attr("data-wishlistproductid", userWishlist[0].wishlist_product_id);
                } else {
                    changeBtnAddWishlist(false, productRef, enableBtnWishlist);
                }
            }
        });
    }
}

function triggerDropdown(elem, trigger, toDrop) {
    if (!$(elem).hasClass("actif")) {
        $(trigger).removeClass('actif');
        $(trigger).next(toDrop).removeClass('actif');
        $(elem).addClass('actif');
        $(elem).next(toDrop).addClass('actif');
    } else {
        $(trigger).removeClass('actif');
        $(elem).removeClass('actif');
        $(elem).next(toDrop).removeClass('actif');
    }

    $(".filter_wrapper_container").overlayScrollbars({});
}

$(document).on('click', '#filter_sticky .close_filter, #shad', function () {
    $("#filter_sticky").hide().removeClass("actif");
    $("#shad").removeClass("actif forfilter");
    $("#filter_sticky .selected_options, #filter_sticky .filter_name").removeClass("actif");
});

function filter_all_popup() {
    $("#filter_sticky").show().addClass("actif");
    $("#shad").addClass("actif forfilter");
}

function filterTrigger() {
    if ($('#filter_sticky').length) {
        var filterWrapper = $('#filter_sticky');

        if (filterWrapper.hasClass('actif')) {
            filterWrapper.hide().removeClass('actif');
            $('.filter_name').removeClass('actif');
            $('.selected_options').removeClass("actif");
            $("#shad").removeClass("actif forfilter");
        }
    }
}

if ($('.swiper_cate_filtre').length && $('.swiper_cate_filtre .swiper-slide').length > 5) {
    $('.swiper_n_btn_wrapper').addClass('has_swiper');
    var swiper_cate_filtre = new Swiper('.swiper_cate_filtre', {
        spaceBetween: 38,
        slidesPerView: 5,
        navigation: {
            nextEl: '.swiper_cate_filtre_button_next',
            prevEl: '.swiper_cate_filtre_button_prev',
        },
    });
}

var each_filter_name_wrapper = $('#catFiltersSwiper .filter_wrapper');
var each_filter_categ = $('#filter_sticky .filter_wrapper');
var v_filter_sticky = $('#filter_sticky .filter_wrapper_container');

$(document).ready(function () {
    if (!$('#filter_sticky').hasClass("actif")) {
        each_filter_name_wrapper.each(function () {
            $(this).find('.filter_name:not(.filter_all_popup)').on("click", function(){
                var clickerName = $(this).parent().data('filtername');
                each_filter_categ.each(function () {
                    if($(this).data('filtername') == clickerName && (!$('#filter_sticky').hasClass("actif"))) {
                        filter_all_popup();
                        var filterToactivate = $(this).find('.filter_name:not(.filter_all_popup)');
                        var filterToactivateContent = $(this).find('.selected_options');
                        filterToactivate.addClass('actif');
                        filterToactivateContent.addClass('actif');
                        window.setTimeout(function () {
                            var containerTop = $('.filter_wrapper_container').offset().top;
                            var targetTop = filterToactivate.offset().top;
                            v_filter_sticky.animate({scrollTop: targetTop-containerTop}, 500);
                        }, 200);
                    }
                });
            });
        });
    }
});

$(document).on('click', '.filter_all_popup', function () {
    filter_all_popup();
});

$("#search_home").on('input', function () {
    var value = $("#search_home").val();
    var result = $(".results_list");

    if (value.length >= 3) {
        $('body').addClass('search_in');
        $('#shad_menu').addClass('search_tophome');
        result.show();
        $('#flowbox-container, #banner_flowbox_title, #flowboxbtn_voirplus').hide();
    } else {
        $('body').removeClass('search_in');
        $('#shad_menu').removeClass('search_tophome');
        result.hide();
        $('#flowbox-container, #banner_flowbox_title, #flowboxbtn_voirplus').show();
    }

    $("#shad_menu, .search_wrapper .results_list").click(function() {
        result.hide();
        $('#shad_menu').removeClass('search_tophome');
    });

});

if ($('.dynasearchwrapper').length) {
    $('body').addClass('search_in');
}

function checkStockCases() {
    if ($('#case_ajax') !== 'undefined') {
        let case_ajax = $('#case_ajax').val();
        if (['5', '6', '7'].includes(case_ajax)) {
            // Case 5, 6, 7
            $(".eclat_nouveaute").removeClass('cache').show();
            $(".eclat_epuise").hide();
            $(".eclatProd").show().removeClass('cache');
            $("#bloc_add_alert").removeClass('cache').show();
            $("#bloc_add_basket").hide();
            $(".stock_delivery").css('display', 'flex');
            $(".stock_info.yes").addClass('cache');
            $(".stock_info.no").removeClass('cache');
            $(".productQuantityFieldset").addClass("without_stock");
            $(".qte_select_wrapper").addClass("without_stock");
            $(".chez_vous_entre").addClass('cache');
        } else if ($('#case_ajax').val() == '8') {
            // Case 8
            $(".eclat_epuise").removeClass('cache').show();
            $(".eclat_nouveaute").hide();
            $(".eclatProd").hide();
            $("#bloc_add_basket").show();

            if ($(window).width() <= 1024) {
                $("#bloc_dispo_mag .find_retailer").css({
                    'width': '115%',
                    'position': 'relative',
                    'right':'36px'
                })
            }

            $("#bloc_add_alert").hide();
            $(".stock_delivery").css('display', 'flex');
            $(".stock_info.yes").addClass('cache');
            $(".stock_info.no").removeClass('cache');
            $(".productQuantityFieldset").addClass("without_stock");
            $(".qte_select_wrapper").addClass("without_stock");
            $(".chez_vous_entre").addClass('cache');
        } else {
            // Case 1 / 2 / 3 / 4
            $(".eclatProd").show().removeClass('cache');
            $("#bloc_add_basket").show();
            $("#btn_add_cart").attr('disabled', false);
            $("#bloc_add_alert").hide();
            $(".stock_delivery").css('display', 'flex');
            $(".stock_info.yes").removeClass('cache');
            $(".stock_info.no").addClass('cache');
            $(".productQuantityFieldset").removeClass("without_stock");
            $(".qte_select_wrapper").removeClass("without_stock");
            $(".chez_vous_entre").removeClass('cache').show();
        }
    }
}

// count filters in rayon
function countItemFilter(input, translation) {
    var filterName = input.name;
    var filters = (filterName == 'charact') ? $('#filter_caract_' + translation + ' input:checked[name='+ filterName +']') : $('input:checked[name='+ filterName +']');
    var countFilters = filters.length

    if (countFilters > 0) {
        if ($("#catFiltersSwiper .filter_name_wrapper [data-filtername='" + filterName + "'] .filter_name").length) {
            if ($("#catFiltersSwiper .filter_name_wrapper [data-filtername='" + filterName + "'] .filter_name").find('.count_filters').length) {
                $("#catFiltersSwiper .filter_name_wrapper [data-filtername='" + filterName + "'] .filter_name .count_filters").html(' (' + countFilters + ')');
            } else {
                $("#catFiltersSwiper .filter_name_wrapper [data-filtername='" + filterName + "'] .filter_name").append('<span class="count_filters"> ('+ countFilters + ') </span>');
            }
        } else if ($("#catFiltersSwiper .filter_name_wrapper [data-filtername='" + translation + "'] .filter_name").length) {
            if ($("#catFiltersSwiper .filter_name_wrapper [data-filtername='" + translation + "'] .filter_name").find('.count_filters').length) {
                $("#catFiltersSwiper .filter_name_wrapper [data-filtername='" + translation + "'] .filter_name .count_filters").html(' (' + countFilters + ')');
            } else {
                $("#catFiltersSwiper .filter_name_wrapper [data-filtername='" + translation + "'] .filter_name").append('<span class="count_filters"> ('+ countFilters + ') </span>');
            }
        }
    } else {
        if ($("#catFiltersSwiper .filter_name_wrapper [data-filtername='" + filterName + "'] .filter_name").length) {
            if ($("#catFiltersSwiper .filter_name_wrapper [data-filtername='" + filterName + "'] .filter_name").find('.count_filters').length) {
                $("#catFiltersSwiper .filter_name_wrapper [data-filtername='" + filterName + "'] .filter_name .count_filters").html('');
            }
        } else if ($("#catFiltersSwiper .filter_name_wrapper [data-filtername='" + translation + "'] .filter_name").length) {
            if ($("#catFiltersSwiper .filter_name_wrapper [data-filtername='" + translation + "'] .filter_name").find('.count_filters').length) {
                $("#catFiltersSwiper .filter_name_wrapper [data-filtername='" + translation + "'] .filter_name .count_filters").html('');
            }
        }
    }
}

$(document).on('click', '.pager_wrapper .see_all_product_wrapper', function () {
    $(".pager_wrapper .pagination").hide();
});

function createCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie( name ) {
    var nameEQ = name + "=";
    var ca = document.cookie.split( ';' );
    for ( var i = 0; i < ca.length; i++ ) {
        var c = ca[ i ];
        while ( c.charAt( 0 ) == ' ' ) {
            c = c.substring( 1, c.length );
        }
        if ( c.indexOf( nameEQ ) == 0 ) {
            return c.substring( nameEQ.length, c.length );
        }
    }
    return null;
}

/* STORE LOC */
// Creation d'un bloc correspondant a un marqueur sur la liste laterale
function creerLigneListe(cpt, id, nom, adresse, ville, cp, pays, currently_open, horaire_today, distance, marqueur, picture_filtre, magasinId, stock, stockStatus, stock_quantity, clientId, favorite, beContacted, flag_available, url, shop_filter, telephone, query, lat, lng) {
    currently_open = parseFloat(currently_open);
    var li = document.createElement('li');
    li.id = id;

    li.className = 'elem-list-store';

    if (lat != "" && lng != "" && !$('body.product_page').length) {
        li.setAttribute("onclick", "geolocGoto('" + lat + "', '" + lng + "')");
    }

    /***** Product stock infos *****/
    if (stock_quantity == 'undefined' || stock_quantity == '') {
        stock_status_msg = 'unknow_stock';
    } else {
        var in_stock = (stock_quantity >= 6 && stock_quantity <= 9999);
        var limited_stock = (stock_quantity >= 1 && stock_quantity <= 5);
        var not_in_stock = stock_quantity == 0;
        var to_be_confirmed_stock = stock_quantity == 10000;
        var can_be_ordered_stock = stock_quantity == 11000;

        var stock_status_msg = '';
        if (in_stock) {
            stock_status_msg = 'in_stock';
        }
        if (limited_stock) {
            stock_status_msg = 'limited_stock';
        }
        if (not_in_stock) {
            stock_status_msg = 'not_in_stock';
        }
        if (to_be_confirmed_stock) {
            stock_status_msg = 'to_be_confirmed_stock';
        }
        if (can_be_ordered_stock) {
            stock_status_msg = 'can_be_ordered_stock';
        }
    }

    var info_horaire_today = !horaire_today ? Translator.translate("store_closed") : horaire_today;
    var class_horaire_today = currently_open ? 'store_open' : 'store_closed';
    var ligneListe =
        '<div class="elem_list_contents">' +
        '<div class="nom_store">';
        if (distance) {
            ligneListe += '<h2 class="title">' + nom +  '<p class="distance">' + '('+ distance + 'km)' + '</p></h2>';
        } else {
            ligneListe += '<h2 class="title">' + nom + '</h2>';
        }
        ligneListe += '</div>' +
        '<div class="content_store">'

        if ($('body.product_page').length) {
            ligneListe += `<div class="detail_store" onclick="location.href = '${path_relative_root + url}'">`
        } else {
            ligneListe += '<div class="detail_store">'
        }
        ligneListe += '<div class="col-1">' +
        '<span class="rue_store">' + adresse.toLowerCase() + '</span>' +
        '<span class="ville_store">' + cp + ' ' + ville.charAt(0).toUpperCase() + ville.slice(1).toLowerCase() + ' - ' + '<span class="pays_store">' + pays + '</span>' + '</span>' +
        '<span class="tel_store">' + telephone + '</span></div>';

    var is_page_product = $('body.product_page').length == 1;

    if ((info_horaire_today !== '' && currently_open) || (!currently_open && horaire_today)) {
        ligneListe += '<div class="col-2"><p class="horaire_today puce_' + class_horaire_today + '">' + info_horaire_today + '</p></div>';
    }

    ligneListe += '</div>';
    if( document.getElementById('produit_id') ) {

        /***** Product stock infos msg *****/
        if (stock != 'undefined') {
            ligneListe += '<p class="stock_status ' + stock_status_msg + '">' + Translator.translate(stock_status_msg + '_msg') + '</p>';
        }
    }
    if (is_page_product) {

        if (beContacted == 1) {
            var contactStoreTxt = Translator.translate('contact_store');
            if ($('body.product_page.mobile').length) {
                ligneListe += '<div class="availableTxt store_more" onclick="handleChangeMobile('+ id +')">' + '<span>' + contactStoreTxt + '</span>' + '</div>' + '</div>' + '</div>';
            } else {
                var nom_mag = nom.replace(/'/g, "\\'");
                ligneListe += '<div class="availableTxt store_more" onclick="handleChange(\''+ id +'\',\''+ nom_mag + '\')">' + '<span>' + contactStoreTxt + '</span>' + '</div>' + '</div>' + '</div>';
            }
        } else {
            ligneListe+= '</div>' + '</div>';
        }
    }

    if (!is_page_product) {
        ligneListe += '<div class="store_more"><a onclick="createDataLayer(' + id + ')" href="' + path_relative_root + url + '">' + Translator.translate('afficher_horaires') + '</a>'
        + '</div>';
    }

    ligneListe+= '</div>' + '</div>' + '</div>';

    li.innerHTML = ligneListe;

    li.addEventListener('click', function () {
        google.maps.event.trigger(marqueur, 'click');
    });

    return li;
}

function creerMarqueur(point, nom, adresse, ville, cp, pays, horaire, telephone, email, site, image_mag, type_mag, cpt, magasin_id, url, distance, horaire_today, currently_open) {

    var img = path_relative_root + "img/distrib_he.png";
    var http = new RegExp("^http://", "g");

    // Origins, anchor positions and coordinates of the marker
    // increase in the X direction to the right and in
    // the Y direction down.
    var image = new google.maps.MarkerImage(img,
        // This marker is 20 pixels wide by 32 pixels tall.
        new google.maps.Size(39, 53),
        // The origin for this image is 0,0.
        new google.maps.Point(0,0));

        // The anchor for this image is the base of the flagpole at 0,32.
        var shadow = new google.maps.MarkerImage(path_relative_root + 'img/indic_carte_ombre.png',
        // The shadow image is larger in the horizontal dimension
        // while the position and offset are the same as for the main image.
        new google.maps.Size(39, 53),
        new google.maps.Point(0,0),
        new google.maps.Point(10,0));
        // Shapes define the clickable region of the icon.
        // The type defines an HTML <area> element 'poly' which
        // traces out a polygon as a series of X,Y points. The final
        // coordinate closes the poly by connecting to the first
        // coordinate.

    var marqueur = new google.maps.Marker({
        position: point,
        icon: image
    });

    marqueur.set("magasin_id", magasin_id);

    nom = $.trim(nom);
    adresse = $.trim(adresse);
    ville = $.trim(ville);
    cp = $.trim(cp);
    pays = $.trim(pays);
    horaire = $.trim(horaire);
    telephone = $.trim(telephone);
    email = $.trim(email);
    site = $.trim(site);
    distance = Math.round(distance);
    var info_horaire_today = !horaire_today ? '' : horaire_today;
    var class_horaire_today = currently_open ? 'store_open' : 'store_close';
    var currently_open_info = currently_open ? Translator.translate('store_opened') : Translator.translate('store_closed');

    var cpt_recherche = ( typeof(cpt) != 'undefined' )? '('+cpt+') - ':'';

    if(distance) {
        var ligneListe = '<div class="info_popup"><div class="name"><a href="' + url + '">' + nom + '</a><span class="dist">(' + distance + ' km)</span>';
    } else {
        var ligneListe = '<div class="info_popup"><div class="name"><a href="' + url + '">' + nom + '</a></div>';
    }

    if( image_mag != '' )
        ligneListe += '<img src="' + image_mag + '" />';

    ligneListe += '<div class="adresse">' + adresse.toLowerCase() + '<br />' + cp + ' ' + ville.toLowerCase() + '<br />'+ pays.toLowerCase() +'</div>';

    if (telephone != '')
        ligneListe += '<div class="coord">' + telephone + '</div>';

    if (site != '')
        if(!http.test(site)){
            ligneListe += '<a href="http://' + site + '" class="site" target="_blank">' + site + '</a>';
        }else{
            ligneListe += '<a href="' + site + '" class="site" target="_blank">' + site + '</a>';
        }

    if (horaire != '')
        if(class_horaire_today == 'store_open'){
            ligneListe += '<div class="horaire_title"><span class = "'+class_horaire_today+'">'+ currently_open_info + '</span>' + info_horaire_today +'</div><div class="horaire_info">' + horaire + '</div>';
        } else {
            //If closed we don't show "info_horaire_today" since it displays "Fermer Fermé"
            ligneListe += '<div class="horaire_title"><span class = "'+class_horaire_today+'">'+ currently_open_info + '</span></div><div class="horaire_info">' + horaire + '</div>';
        }

        ligneListe += '<div class="btn_voir_magasin"><a href="' + url + '">' + Translator.translate('voir_magasin') + '</a></div></div>';

    google.maps.event.addListener(marqueur, "click", function() {
        if (!infowindow) {
            infowindow = new google.maps.InfoWindow();
            infowindow.setContent(ligneListe);
            infowindow.open(maCarte, marqueur);
        } else {
            infowindow.setContent(ligneListe);
            infowindow.open(maCarte, marqueur);
        }
    });

    var detail_store_panel = document.getElementById("store_id_dataLayer");
    detail_store_panel.textContent = magasin_id

    markersArray.push(marqueur);

    return marqueur;

}

//*********Verification Formulaire de Contact*********\\
function thisfunctiontest(){
    $("#field_phone_deliv").intlTelInput({
        utilsScript: path_relative_root + 'js/intltelinput/utils.js',
        initialCountry: "fr"
    });

    $("#field_phone_deliv").blur(function(){
        $('.intl-tel-input').find('#iconErrphone_deliv').remove();
        $('.intl-tel-input').find('#iconValidphone_deliv').remove();

        if (typeof $('#iconErrphone_deliv').attr('class') == 'undefined') {
            if ($(this).val() != '') {

                if ($("#field_phone_deliv").intlTelInput("isValidNumber") == false ) {
                    $( '.intl-tel-input' ).append( '<img class="img_error" id="iconErrphone_deliv" src="' + path_relative_root + 'img/picto_panneau.png" style="opacity: 1;" title="' + Translator.translate( "js_thanks_precise", Translator.translate( "login_telephone" ) ) + '">' );
                    $("#field_phone_deliv.inputForm").addClass('error');
                    $("#info_error_liv span").css('visibility','visible');
                    error_tel_commande = true;
                } else {
                    $("#phone_delivHidden").val($(this).intlTelInput("getNumber"));
                    document.getElementById('field_phone_deliv').style.border='';
                    $( '.intl-tel-input' ).append( '<img class="valid_form" id="iconValidphone_deliv" src="' + path_relative_root + 'img/picto_panneau_valid.png" style="opacity: 1;">' );
                    $("#field_phone_deliv.inputForm").removeClass('error');
                    $("#info_error_liv span").css('visibility','hidden');
                    error_tel_commande = false;
                }
            }
        }
    });
}

// Init swiper categorie page adv
$('body.cms_page_new .swiperCategories').each(function () {
    if ($(this).find('.swiper-slide').length) {
        swiperCategories = new Swiper('.swiperCategories', {
            preloadImages: false,
            slidesPerView: 5.54,
            slidesPerGroup : 5,
            spaceBetween: 20,
            loop: false,
            navigation: {
                nextEl: '.swiperCategories .swiper-button-next',
                prevEl: '.swiperCategories .swiper-button-prev',
            }
        });
    } else {
        $(this).addClass("no-arrow");
    }
});

function fixarrow_height() {
    var mainSlideFpId = $('.swiperCategories span.no_lazy img');
    var mainSlideFpHeight = $(mainSlideFpId).height();

    $('.swiperCategories .fixarrow').height(mainSlideFpHeight);
}

window.onresize = function() {
    fixarrow_height();
}

$(document).ready(function() {
    initContentPDVBoxNewForm(false);
});

// Override app function storelactor
function handleChange (id, nom) {
    var locator = document.getElementById('content_pdvBox');
    $(locator).hide();

    if(sessionStorage.pvdcontent) {
        $('#content_pdvBox_new').html(sessionStorage.pvdcontent);
        $('#content_pdvBox_new').addClass(sessionStorage.pvdclass);
    }

    var formulaire = document.getElementById('content_pdvBox_new');
    $(formulaire).find('#message').children('#nom_magasin').html(nom);
    document.getElementById('id_magasin_form').setAttribute('value',id);
    var modbox = document.getElementById('pdvBox');
    $(modbox).addClass('showForm');

    if (!$(modbox).hasClass('sidebox')) {
        $(modbox).animate({
            maxHeight : '100%',
            overflow : "auto"
        }, 1000);
    }

    if ($(modbox).hasClass('sidebox')) {
        $(formulaire).show("slide", { direction: "right" }, 300);
    } else {
        $(formulaire).show();
    }

    setTimeout(function() {
        if(!sessionStorage.pvdcontent) {
            $("#content_pdvBox_new").overlayScrollbars({});
            setTimeout(function() {
                sessionStorage.pvdcontent = $('#content_pdvBox_new').html();
                sessionStorage.pvdclass = $('#content_pdvBox_new').attr("class");
            }, 100);
        }
    }, 100);

    if (typeof initContentPDVBoxNewForm === 'function') {
        setTimeout(function() {
            initContentPDVBoxNewForm(true);
        }, 400);
    }
}

function initContentPDVBoxNewForm(runStyling) {
    if ($("body.satellite_store_details #content_pdvBox_new").length || $("body.product_page #content_pdvBox_new").length) {
        var el = $("#content_pdvBox_new #w-contact-form, #content_pdvBox_new .w-contact-form, #content_pdvBox_new .formulaire");

        if(!checkContactFormOnBlur(el)) {
            return false;
        }

        el.find(".btn_form_contact").click(function() {
            $('.btn_form_contact').next('.loader').addClass('loading');

            setTimeout(function() {
                sendFormContactByStore();
            }, 500)

            if (checkContactFormOnClick (el)) {
                return true;
            } else {
                setTimeout(function() {
                    $('.btn_form_contact').next('.loader').removeClass('loading');
                }, 800);

                return false;
            }
        });

        if (runStyling && $("body.product_page #content_pdvBox_new").length) {
            $('.styled').new_styling_form({
                type_class_sufix: '',
                localName_new_elem: 'span'
            });
        }
    }
}

// Checks whether email adresss valid
function validateEmail(email) {
    var rex = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;

    return rex.test(email);
}

/* To Allow Only Numbers While Writing Phone Numbers */
function forceNumOnly( elmt ) {
    elmt.value = elmt.value.replace( /[^0-9. ]/g, '' ).replace( /(\..*)\./g, '$1' );
}

function ajaxLoadCp(type, id) {
    $.ajax({
        type: 'post',
        url: path_relative_root + 'ajax_get_list_cms_page.php',
        data: 'type=' + type + '&id=' + id,
        success: function(result) {
            result = JSON.parse(result);

            // generate cms page preview
            if (result.success) {
                $('#cms_subcategory_menu .item.selected').removeClass('selected');

                if (type == "cms_category") {
                    $('#cms_subcategory_menu .see_all').hide();
                } else {
                    $('#cms_subcategory_menu .see_all').show();
                    $('#csc_menu_' + id).addClass('selected');
                }

                $('#cms_subcategory_menu item').removeClass('selected');
                $('#cms_subcategory_menu item.see_all').show();
                $('#content_previsu_cms_page').html('');
                $('#content_previsu_cms_page').html(result.html);
            }
        }
    });
}

$(function () {
        var coverSliderElement = $('.cms-page-module.tg-module .swiper-container');
        if (coverSliderElement.length) {
            coverSliderElement.each(function () {
                if ($(this).find('.swiper-slide').length > 1) {
                    new Swiper("#" + $(this).attr('id'), {
                        preloadImages: false,
                        slidesPerView: 4,
                        spaceBetween: 48,
                        lazy: true,
                        loop: false,
                        navigation: {
                            nextEl: ".swiper-module-next",
                            prevEl: ".swiper-module-prev",
                        },
                    });
                }
            });
        }

    /**
     * HOME SLIDER 3
     */

    var homeSliderElement = $('.module-slider_image .swiper-container');
    if (homeSliderElement.length) {
        homeSliderElement.each(function () {
            if ($(this).find('.swiper-slide').length > 1) {
                new Swiper("#" + $(this).attr('id'), {

                    spaceBetween: 30,
                    slidesPerView: 1,
                    autoplay: {
                        delay: 12000,
                    },
                    pagination: {
                        el: ".swiper-pagination_module_home",
                        type: 'bullets',
                        clickable: true,
                    },
                });
            }
        });
    }

    $('#site_head_wrap').on('mouseenter touchstart', '#right_part_cart', function () {
        if (!$('#cart_top .amounts_bubble').hasClass('basketEmpty')) {
            $("#shad_menu").addClass("actif panier_hover").show();
        }
    });

    $('#site_head_wrap').on('mouseleave', '#right_part_cart', function () {
        $("#shad_menu").removeClass("actif panier_hover").hide();

        if ($('#right_part_cart').hasClass('hover')) {
            $('#right_part_cart').removeClass('hover');
        }
    });

    $('#site_head_wrap').on('mouseenter touchstart', '#right_part_wishlist', function () {
        if (!$('#wishlist_top .amounts_bubble').hasClass('basketEmpty')) {
            $("#shad_menu").addClass("actif panier_hover").show();
        }
    });

    $('#site_head_wrap').on('mouseleave', '#right_part_wishlist', function () {
        $("#shad_menu").removeClass("actif panier_hover").hide();

        if ($('#right_part_wishlist').hasClass('hover')) {
            $('#right_part_wishlist').removeClass('hover');
        }
    });

    $('#cart_top').on('click','.go_to_cart',function(){
        $(this).parent('.cart_btm_btn').addClass('loading');
    });

    $('#btn_cmd_valid span').click(function () {
        $(this).parent().addClass('loading');

        setTimeout(function () {
            $('#btn_cmd_valid').removeClass('loading');
        }, 2000);
    });
});

/* Comportement preHeader au scroll */
if ($('.preheader_bandeau').length) {
    var isTop = true;

    $(document).scroll(function () {
        if ($(window).scrollTop() === 0 && !isTop) {
            isTop = true;
            $(".preheader_bandeau").fadeTo("fast", 1).css('pointer-events', 'auto');
        } else if ($(window).scrollTop() > 1 && isTop) {
            isTop = false;
            $(".preheader_bandeau").fadeTo("fast", 0).css('pointer-events', 'none');
        }
    });
}

/* Function used on Product Qty selector */
function updateProductQuantity(action, prod_id) {
    var qte_stock,
        qte_input,
        plus_selector,
        minus_selector;
    var is_wishlist = $('#wishlist').length > 0;

    // Recover html selectors
    plus_selector = $("#ligne_qte .qte_plus");
    minus_selector = $("#ligne_qte .qte_minus");

    // Get product stock quantity and input quantity selected values
    qte_stock = $('#product_stock_counter').val();
    qte_stock = (qte_stock != '') ? qte_stock : 0;
    qte_input = $('#ligne_qte .qte_selected').val();
    qte_input = (qte_input != '') ? qte_input : 0;

    if (action == 'plus') {
        qte_input++;

        // Remove disabled classes if necessary
        if ((qte_input > 1) && (minus_selector.hasClass('selector_disabled'))) {
            minus_selector.removeClass('selector_disabled');
        }

        // Update form
        if (qte_input <= qte_stock) {
            $('#qteProd').val(qte_input);
        } else {
            qte_input--;
            plus_selector.addClass('selector_disabled')
        }

    } else if (action == 'minus') {
        qte_input--;

        // If qte stored in input can be decremented
        if ((qte_input > 1) || (qte_input == 1)) {
            $('#qteProd').val(qte_input);
        }

        // add diabled class if needed
        if (qte_input == 1) {
            if (!minus_selector.hasClass('selector_disabled')) {
                minus_selector.addClass('selector_disabled');
            }
        }
    }
}

/**
 * Retrieves wishlist products and updates the UI accordingly.
 */
function getWishlistProducts() {
    let array_elements = document.querySelectorAll('a[data-productid]');

    let array_ids = [];
    if (typeof array_elements != 'undefined' && array_elements.length > 0) {
        for (el of array_elements) {
            var id = el.getAttribute('data-productid');

            if (id !== '' && id !== 'undefined') {
                array_ids.push(id);
            }
        }
        let data = JSON.parse((JSON.stringify(Object.assign({}, array_ids))));

        $.ajax({
            type: 'get',
            url: path_relative_root + create_link('get_user_all_wishlist'),
            data: data,
            success: function (response) {
                if (response) {
                    if (typeof response != 'undefined' && response.length > 0 && response !== 'Array') {
                        let array_product_id = JSON.parse(response);
                        for (product_wishlist in array_product_id) {
                            $('a[data-productid = "' + array_product_id[product_wishlist].fk_produit_id + '"]').addClass("existToWishlistButton");
                            $('a[data-productid = "' + array_product_id[product_wishlist].fk_produit_id + '"]').attr('data-wishlistproductid', array_product_id[product_wishlist].wishlist_product_id);
                        }
                    }
                }
            }
        });
    }
}

//Function to create a store div infos personalised for woosmap
function createLineWoosmap(store, MapsProviderService ){
    let div = '';
        let storeDetailsUrl = 'store_details.php?id=' + store.properties.store_id;
        let zipcodeAndCity = '';
        if (zipcode = store.properties.address.zipcode) {
            zipcodeAndCity = zipcode;
        }
        if (city = store.properties.address.city) {
            zipcodeAndCity += ' ' + city;
        }
        zipcodeAndCity = zipcodeAndCity.trim();
        if (MapsProviderService.device != null && MapsProviderService.device == 'mobile') {
            div = `<div class="bloc_marker_info">
                <p style="font-size:12px">
                    <span class="marker_store_name">${store.properties.name}</span>
                    <p style="font-weight:bold;margin-top:10px;">Adresse</p>
                    <span class="marker_store_adr">${store.properties.address.lines.join('<br>')}</span>
                    <span class="marker_store_cp">${zipcodeAndCity}</span>
                </p>
                <div class="cta_marker_info">
                    <span class="learn_more"><a href="${storeDetailsUrl}">${Translator.translate('learn_more')}</a></span>
                    <a class="marker_go_map" href="">${Translator.translate('go_to_store')}</a>
            </div>`;
        } else {
            div = '<div class="info_popup">';

            div += '<div class="name"><a href="' + storeDetailsUrl + '">' + store.properties.name + '</a></div>';

            div += '<div class="adresse"><p style="font-weight:bold;margin-top:10px;">' + Translator.translate('adresse') + '</p>';
            div += store.properties.address.lines.join('<br>');

            if (zipcodeAndCity.length > 0) {
                div += '<br>' + zipcodeAndCity
            }
            if (country = store.properties.address.countryCode) {
                div += '<br>' + country;
            }
            div += '</div>';

            if(store.properties.contact.phone){
            div += '<div class="store_infos">' + formatPhone(store.properties.contact.phone) + '</div>';
            }

            if (store.properties.open.open_now) {
                div += '<div class="statut_mag">' + '<p class="currently_open">' + Translator.translate('open') + '</p>' + '</div>';
            } else {
                div += '<div class="statut_mag">' + '<p class="currently_closed">' + Translator.translate('closed') + '</p>' + '</div>';
            }

            div += '<div class="horaire_info"><p class="horaire_title" style="font-weight:bold;margin-top:10px;">' + Translator.translate('horaires_map') + '</p>';
            let openingTimes = [];
            let today = new Date().getDay();
            for (weekday = 1; weekday <= 7; weekday++) {
                if (store.properties.weekly_opening) {
                    let hours = store.properties.weekly_opening[weekday].hours;
                    let dayOpeningTimes = [];
                    if (store.properties.weekly_opening[weekday].hours.length > 0) {
                        for (let i in hours) {
                            if (hours[i].start.length > 0 && hours[i].end.length > 0) {
                                dayOpeningTimes.push(hours[i].start + '-' + hours[i].end);
                            }
                        }
                    } else {
                        dayOpeningTimes.push(Translator.translate('delivery_store_closed'));
                    }
                    dayOpeningTimes = Translator.translate('day_0' + weekday) + ' : ' + dayOpeningTimes.join(', ')
                    if (today === weekday && today >= 1 && today <= 7) {
                        let classToApply = store.properties.open.open_now ? 'currently_open' : 'currently_closed';
                        dayOpeningTimes = `<span class="${classToApply}">${dayOpeningTimes}</span>`;
                    }
                    openingTimes.push(dayOpeningTimes);
                }
            }

            div += openingTimes.join('<br>');
            div += '</div>';
            if ( store.properties.magImgDisplay){
            div += '<div class="store_infos_img">' + store.properties.magImgDisplay + '</div>';
            }
            div += '<br><a class="savoir_plus" href="' + storeDetailsUrl + '">' + Translator.translate('popup_shop_info_woos') + '</a>';

            div += '</div>';
        }

        return div;
}

//function to format adress
function formatAddress (address) {
    return address.charAt(0).toUpperCase() + address.slice(1).toLowerCase();
}

//function to format adress
function formatPhone (phone) {
    return phone.replace("+33", "0").replace(/(.{2})(.{2})(.{2})(.{2})(.{2})/, "$1 $2 $3 $4 $5");
}

// popup PDV to show store with product available on product sheet
function showpdv() {
    var shade, modbox;
    $('.find_retailer').addClass("loading");

    $.ajax({
        type: "post",
        url: path_relative_root + "ajax-distributeurs.php",
        success: function (res) {
            if (res) {
                shade = document.querySelector("#shad.modal_shade");
                modbox = document.getElementById("pdvBox");

                // Make sure modbox will show on top of shad
                $(shade).after(modbox);

                // Inject store locator into modbox
                $(".store_locator").html(res);

                if (typeof MapsProviderService === 'undefined' || MapsProviderService.provider.name == 'googlemaps') {

                    initialize();
                }

                // Show modbox and make sure closing routine won't fire more than once

                $(shade).off('click', closepdv)
                $(modbox).addClass('deployed actif')
                $(shade).addClass('actif');
                $(shade).on("click", closepdv);
                $("html, body").animate({scrollTop: 0}, 500);
                if (!$('body').hasClass('noScroll') && $(modbox).hasClass('sidebox')) {
                    $('body').addClass('noScroll');
                }
                $("#pdv_load").removeClass("loading");
                $('.find_retailer').removeClass("loading");
            }
        },
    });
}

function closepdv(from) {

    var shade = document.querySelector("#shad.modal_shade");
    var modbox = document.getElementById('pdvBox');

    $(modbox).removeClass('deployed actif');
    $(shade).removeClass('actif');

    $(shade).off('click', closepdv);

    if (from == 'fiche produit' || $("body.product_page").length) {
        setTimeout(function () {
            $('#content_pdvBox_new').hide();
            $('#content_pdvBox').show();
            $(modbox).removeClass('showForm');
            $(modbox).removeClass('thanks');
        }, 200);
    }

    if ($('body').hasClass('noScroll')) {
        $('body').removeClass('noScroll');
    }
}

document.addEventListener('DOMContentLoaded', function() {
    var checkboxes = document.querySelectorAll('.filtre-checkbox');
    checkboxes.forEach(function(checkbox) {
        checkbox.addEventListener('click', masquerElements);
    });
});

// function to add specifique type of filter on the store locatare page
function masquerElements() {
    // Check the desired checkbox and uncheck all others
    $('.filtre-checkbox[type="checkbox"]').not(this).prop('checked', false);

    // Reset Pagination
    if (typeof WOOSMAP_CURRENT_PAGE != "undefined") {
        WOOSMAP_CURRENT_PAGE = 1;
    }
    MapsProviderService.initSearchShopPage();
}

// on the login form, mirror the "no" radio button to the separated 'checkbox' at the bottom
// observe the radio button and update the checkbox accordingly, and vice versa
function mirrorOptoutCheckbox() {
    var fakeOptoutCheckbox = '#deny-optin input[type="radio"]';
    var fakeOptoutCheckboxContainer = '#deny-optin';
    var trueOptoutCheckboxSignup = '#js-signUpForm input[type="radio"][value="0"]';
    var trueOptoutCheckboxLogin ='#auth input[type="radio"][value="0"]';
    var trueOptinCheckboxSignup = '#js-signUpForm input[type="radio"][value="1"]';
    var trueOptinCheckboxLogin ='#auth input[type="radio"][value="1"]';

    // bind the event to the body, so it works even if the form is loaded after the script
    // if any radio button is changed, check if it corresponds to any of the 'checkboxes'
    document.addEventListener('change', function(e) {
        if (e.target.matches(fakeOptoutCheckbox)) {
            try {
                document.querySelector(trueOptoutCheckboxSignup).checked = e.target.checked;
            } catch (error) {
                console.warn('Error occurred while handling trueOptoutCheckboxSignup:', error);
            }

            try {
                document.querySelector(trueOptoutCheckboxLogin).checked = e.target.checked;
            } catch (error) {
                console.warn('Error occurred while handling trueOptoutCheckboxLogin:', error);
            }
        }

        if (e.target.matches(trueOptinCheckboxSignup) || e.target.matches(trueOptinCheckboxLogin)) {
            document.querySelector(fakeOptoutCheckbox).checked = !e.target.checked;
        }
    });

    // we need to add an observer to the body, if at any point the radio button is changed, we need to make sure the user isn't stuck

    var observer = new MutationObserver(function(mutations) {
        let fakeCheckboxStatus = "neutral"; // neutral, toHide, toShow

        mutations.forEach(function(mutation) {
            // if at any point one of the true elements is removed from the dom, hide the fake checkbox
            if (mutation.removedNodes) {
                if (!document.querySelector(trueOptinCheckboxSignup) && !document.querySelector(trueOptinCheckboxLogin)) {
                    // if the true optin checkbox is removed, fade out the fake checkbox
                    fakeCheckboxStatus = "toHide";
                }
            }

            // if at any point one of the true elements is added to the dom, check its state and update the fake checkbox accordingly
            if (mutation.addedNodes) {

                if (document.querySelector(trueOptinCheckboxSignup) || document.querySelector(trueOptinCheckboxLogin)) {
                    // if the true optin checkbox is added, fade in the fake checkbox
                    fakeCheckboxStatus = "toShow";
                }

                // if the true optout checkbox is added, check its state and update the fake checkbox accordingly
                try {
                    if (document.querySelector(trueOptoutCheckboxSignup)) {
                        if (document.querySelector(trueOptoutCheckboxSignup).checked) {
                            // if the true optout checkbox is checked, check the fake checkbox
                            document.querySelector(fakeOptoutCheckbox).checked = true;
                        } else {
                            // if the true optout checkbox is unchecked, uncheck the fake checkbox
                            document.querySelector(fakeOptoutCheckbox).checked = false;
                        }
                    }
                } catch (error) {
                    console.warn('Error occurred while handling trueOptoutCheckboxSignup:', error);
                }

                try {
                    if (document.querySelector(trueOptoutCheckboxLogin)) {
                        if (document.querySelector(trueOptoutCheckboxLogin).checked) {
                            // if the true optout checkbox is checked, check the fake checkbox
                            document.querySelector(fakeOptoutCheckbox).checked = true;
                        } else {
                            // if the true optout checkbox is unchecked, uncheck the fake checkbox
                            document.querySelector(fakeOptoutCheckbox).checked = false;
                        }
                    }
                } catch (error) {
                    console.warn('Error occurred while handling trueOptoutCheckboxLogin:', error);
                }
            }
        });

        // update the fake checkbox status depending on the state of the fakeCheckboxStatus variable
        if (fakeCheckboxStatus === "toHide") {
           $(fakeOptoutCheckboxContainer).fadeOut();
        } else if (fakeCheckboxStatus === "toShow") {
            $(fakeOptoutCheckboxContainer).fadeIn();
        }
    });

    observer.observe(document.body, {
        childList: true,
        subtree: true
    });
}

document.addEventListener('DOMContentLoaded', function() {
   mirrorOptoutCheckbox();
});
$(document).ready(function () {
    var swiper = new Swiper('.footer_products_sections .swiper-container', {
        slidesPerView: "auto",
        spaceBetween: 50,
        // offset after to avoid words getting cut off by the 30px padding
        slidesOffsetAfter: 30,
        breakpoints: {
            768: {
                spaceBetween: 20,
            },
        },
    });

    // VERIFIED REVIEWS START
    // grab all .nrc_date elements and format them
    var nrc_date = document.querySelectorAll('.nrc_date');
    for (var i = 0; i < nrc_date.length; i++) {
        var date = new Date(nrc_date[i].textContent);
        var lang_iso = typeof lang_iso != "undefined" ? lang_iso : "fr";
        var strMonth = date.toLocaleString(lang_iso, { month: 'short' });

        nrc_date[i].textContent = date.getDate() + ' ' + strMonth + ' ' + date.getFullYear();
    }

    // STATIC NETREVIEWS SWIPER
    var swiperNetreviews = new Swiper('.swiperNetreviews', {
        'slidesOffsetBefore': 64,
        'slidesPerView': '2.3',
        'watchSlidesVisibility': true,
        'spaceBetween': 10,
        'navigation': {
            'nextEl': '.nextSlideFp',
            'prevEl': '.prevSlideFp',
        },
        'pagination': {
            'el': '.swiper-scrollbar',
            'type': 'progressbar',
            'clickable': true
        },
        'breakpoints': {
            '1290': {
                'slidesPerView': 2.3,
                'spaceBetween': 22,
            }
        }
    });
    // add it to the global scope
    window.swiperNetreviews = swiperNetreviews;
    // check the slides if there's a "see more" span, if so hide the full length text, init the modal to show more on click

    // when clicking progress bar with number of reviews for a category:
    //  move all reviews that arent in that category to #swiper-storage, order them by data-id and reinit swiper (category is set via the data-rate attribute)
    // .total_review displays all
    // .nrc_chart_single displays only their category (if they have a data-count higher than 0)

    if ($('#w-netreviews-comments .swiper-wrapper .swiper-slide').length > 1) {
        $('.total_reviews').on('click', function () {
            // move all reviews from the storage div to the main swiper if they exist
            // order them by data-id and reinit swiper

            $('#w-netreviews-comments #swiper-storage .swiper-slide').each(function () {
                $('#w-netreviews-comments .swiper-wrapper').append(this);
            });

            $('#w-netreviews-comments .swiper-wrapper .swiper-slide').sort(function (a, b) {
                return $(a).data('id') - $(b).data('id');
            }).appendTo('#w-netreviews-comments .swiper-wrapper');

            swiperNetreviews.update().slideTo(0);
        });

        $('.nrc_chart_single').on('click', function () {
            if ($(this).data('count') == 0) {
                return;
            }

            // move all reviews to the storage div
            // add reviews with the same category to the main swiper
            // order them by data-id and reinit swiper

            $('#w-netreviews-comments .swiper-wrapper .swiper-slide').each(function () {
                // append, if given a jQuery object, will move the element
                $('#swiper-storage').append($(this));
            });

            let category = $(this).data('rate');
            $('#w-netreviews-comments #swiper-storage .swiper-slide').each(function () {
                if ($(this).data('rate') == category) {
                    // append, if given a jQuery object, will move the element
                    $('#w-netreviews-comments .swiper-wrapper').append($(this));
                }
            });

            $('#w-netreviews-comments .swiper-wrapper .swiper-slide').sort(function (a, b) {
                return $(a).data('id') - $(b).data('id');
            }).appendTo('#w-netreviews-comments .swiper-wrapper');

            swiperNetreviews.update().slideTo(0);
        });
    } else {
        // don't change the cursor if there's only one review
        $('.nrc_chart_single').css('cursor', 'default');
        $('.total_reviews').css('cursor', 'default');

        // un initialize the swiper if there's only one review
        if ($('.swiperNetreviews').length) {
            swiperNetreviews.destroy();
        }

        // hide the progressbar & arrows
        if ($('#w-netreviews-comments').length) {
            $('#w-netreviews-comments .prevSlideFp, #w-netreviews-comments .nextSlideFp').hide();
            $('#w-netreviews-comments .swiper-scrollbar').hide();
        }
    }

    // lightbox : on a .nrc_comment_see_more click, grab all the contents of the review and put it in #nrc_comment
    // then display it with a fade in, bind the close button to fade out, remove content...
    $('.nrc_comment_see_more').on('click', function () {
        var content = $(this).parents('.swiper-slide');

        var name_date = content.find('.name_date').html();
        var rate = content.find('.nrc_rate').html();
        var comment = content.find('.nrc_comment').html();
        var disclaimer = content.find('.nrc_comment_disclaimer').html();
        var order_date = content.find('.nrc_comment_order_date').html();

        $('#nrc_comment .name_date').html(name_date);
        $('#nrc_comment .nrc_rate').html(rate);
        $('#nrc_comment .nrc_comment').html(comment);
        $('#nrc_comment .nrc_comment_disclaimer').html(disclaimer);
        $('#nrc_comment .nrc_comment_order_date').html(order_date);

        // find the text content (.nrc_comment) and remove the see more span and the truncated content to only keep the full text (.nrc_comment_full)
        $('#nrc_comment').find('.nrc_comment_see_more').remove();
        $('#nrc_comment').find('.nrc_comment_truncated').remove();

        $('#nrc_comment, #shade').fadeIn(200);

        $('#nrc_comment .close, #shade').on('click', function () {
            $('#nrc_comment, #shade').fadeOut(200);
            $('#nrc_comment .nrc_comment_content').html('');
            $('#nrc_comment .close, #shade').off('click');
        });
    });
});

// Handle adding items from wishlist to basket
$(document).on("click","#btn_top_add_wishlist", function (e) {
    e.preventDefault();
    selectedProducts = [];
    $(".remove_top_wishlist").each(function(index) {
        let element = $(this);
        selectedProducts.push({
            id: element.attr("data-id"),
            productId: element.attr("data-produit-id"),
            colorId: element.attr("data-color-id"),
            sizeId: element.attr("data-size-id"),
        });
    });
    moveProductFromWishlistToCart(false, selectedProducts);
});

function filterBoxesToggle(evt) {
    if (!$(this).parent().hasClass("bloc_obj")) {
        var element = $(this);
        var element_rayon = element.parent().parent().eq(0);
        evt.stopImmediatePropagation();
        if (element.hasClass('btn_select_off')) {
            element.removeClass('btn_select_off').addClass('btn_select');
        } else {
            element.removeClass('btn_select').addClass('btn_select_off');
        }
        filterBoxes();
    }
}

function UpdatePriceSlider() {
    var input1 = $("#amount1");
    var input2 = $("#amount2");
    var amount1 = parseInt(input1.val(), 10);
    var amount2 = parseInt(input2.val(), 10);
    var range = $("#slider-range");
    var max = range.slider('option', 'max');
    var min = range.slider('option', 'min');
    var values = range.slider('option', 'values');

    if (amount1 < min) {
        amount1 = values[0];
        input1.val(amount1);
    }

    if (amount2 > max) {
        amount2 = values[1];
        input2.val(amount2);
    }

    range.slider('values', [amount1, amount2]);
}

function changeProduitPanier(pI, pProduitId, pPanierVirtuelId) {
    updatePanier(pProduitId, pI, pPanierVirtuelId);
    showQteListePanier('liste_qte_panier' + pI, pProduitId, pI, pPanierVirtuelId);
    showMontanTotalProduit('montantTotalProduit' + pI, pI);
    showMontanTotalPanier('montantPanier');
}

function mySubmit(pType) {
    var form = document.getElementById("formPanier");
    form.action = create_link('order_basket');
    setTimeout(function() {
        form.submit();
    });
}

function notEmptyPromo() {
    if ($('#input_codePromo').val().trim() == '') {
        $('#modboxpromo .texte').html(translate('promo_error_16'));
        openMultiShad('modboxpromo');
        return false;
    }
}

function elemGlobalShow() {
    $(".elem_global.delivery").show();
}