/*global
    ScrollLock, Swiper
*/

$(function () {

    "use strict";

    var sticky_elm = $('form.product_info', '#site_global_wrap').get(0);
    var is_config = ($(sticky_elm).closest('.config').length > 0);


    var more_vis = $('.more_vis');

    more_vis.each(function () {

        var jqzoom = $(this).next('#jqzoom');

        if (jqzoom.length > 0) {

            setTimeout(function() {

                more_vis.height(jqzoom.height());
            }, 500);
        }
    });

    $(window).on('resize', function () {

        more_vis.each(function () {

            var jqzoom = $(this).next('#jqzoom');

            if (jqzoom.length > 0) {

                $(this).height(jqzoom.height());
            }
        });
    });

    $('.netreviews_stars').on('click', function () {
        // Check if the element exists after the animation is complete
        if ($('#w-netreviews-comments').length > 0) {
            $('html, body').animate({scrollTop: $('#w-netreviews-comments').offset().top-$("#site_head_wrap").height()}, 1000);
        } else {
            console.warn('Netreviews element not found!');
        }
    });


}());
