/**
 *  Smart Sticky Element
 *
 *  Emulates css3 sticky position behavior with a twist of custom easing
 */
(function () {

    // Class Constants (more like an alias)
    var dom = window.document.documentElement;

    // Class Private Vars
    var methods, method_name, method;

    // Polyfill for window.scrollY
    function computeScroll() {

        var scrollY;

        if (window.pageYOffset) {

            scrollY = window.pageYOffset;
        } else {

            scrollY = dom.scrollTop - dom.clientTop;
        }

        return scrollY;
    }

    // Gets element's total offset to top
    function getTotalOffsetTop(elm) {

        var output = elm.offsetTop, last = output;

        while (elm.id !== 'site_global_wrap') {

            if (elm.parentElement.offsetTop !== last) {

                last = elm.offsetTop;
                output += last;
            }

            elm = elm.parentElement;
        }

        return output;
    }

    // Gets element's "real" offset to top
    // Actually gets offset to top minus header height
    function getRealOffsetTop(elm) {

        return getTotalOffsetTop(elm);
    }

    // Helper method to compute scroll limit
    function computeLimit() {

        var elm = this.element,
            par = elm.parentElement,
            out = par.offsetHeight - elm.offsetHeight;

        if (this.elm_self_offset > 0) {

            out -= this.elm_self_offset;
        }

        return out;
    }

    // Computes target position
    function computeTargetPos() {

        var head_height, pos_target, pos_limits;

        // Get scroll distance
        this.scrollY = computeScroll();

        // Get current header height
        head_height = document.getElementById('site_head_wrap').offsetHeight;

        if (this.scrollY >= (this.threshold - head_height)) {

            pos_target = this.scrollY - (this.threshold - head_height);
            pos_limits = computeLimit.call(this);

            pos_target = Math.min(pos_target, pos_limits);
        } else {

            pos_target = 0;
        }

        return pos_target;
    }

    // Object Constructor
    function ScrollLock(id) {

        var element = window.document.getElementById(id),
            output = false;

        // Element bearing given ID exists?
        if (element) {

            this.element = element;

            this.threshold = document.getElementById('site_global_wrap').offsetTop;

            if (this.element.parentElement.offsetTop > this.threshold) {

                this.threshold += (this.element.parentElement.offsetTop - this.threshold);
            }

            this.elm_self_offset = 0;

            if (this.element.offsetTop > this.element.parentElement.offsetTop) {

                this.elm_self_offset = this.element.offsetTop;
                this.threshold += this.elm_self_offset;
            }

            this.scrollY = computeScroll();
            this.targetPos = computeTargetPos.call(this);
            this.is_scrolling = false;

            this.element.style.top = this.targetPos + "px";

            // This will call inititation routine
            output = ScrollLock.prototype.init;
        }

        // Return either false or init
        return (output !== false) ? output.call(this) : output;
    }

    // Gets element's "top" property in px
    function getElmPos() {

        return parseFloat(this.element.style.top);
    }

    // Animation main loop
    function onWinRaf() {

        var elm_pos = getElmPos.call(this),
            dir, pos;

        window.requestAnimationFrame(onWinRaf.bind(this));

        this.targetPos = computeTargetPos.call(this);

        // If animation is not over...
        if (elm_pos !== this.targetPos) {

            // Is it going down or up?
            dir = (elm_pos < this.targetPos) ? 1 : -1;

            // Compute easing and resulting position then apply
            pos = elm_pos + ((this.targetPos - elm_pos) * 0.25);
            pos = (dir > 0) ? Math.ceil(pos) : Math.floor(pos);

            elm_pos = pos;

            this.element.style.top = elm_pos + "px";
        }
    }

    // Prototype Methods
    methods = {

        // Initialization Routine
        init : function () {

            // Computes sticky element position on every frame
            window.requestAnimationFrame(onWinRaf.bind(this));

            return this;
        }
    };

    // Build Prototype
    // It's easier to configure methods accessibility this way
    for (method_name in methods) {

        // If method is user defined
        if (methods.hasOwnProperty(method_name)) {

            // Get the method object
            method = methods[method_name];

            // Get the method
            if (method.constructor === Function) {

                method = { definition : (method || false) };
            }

            // Add method to prototype
            Object.defineProperty(ScrollLock.prototype, method_name, {
                value : method.definition,
                writable : method.is_writable || false,
                enumerable : method.is_enumerable || false,
                configurable : method.is_configurable || false
            });
        }
    }

    // Add Constructor to Window Object, it is read-only
	if(!Object.ScrollLock){
		Object.defineProperty(window, "ScrollLock", {
			value : ScrollLock,
			writable : false,
			enumerable : true
		});
	}
}());
