var menuVisible = null;
$(function () {

	"use strict";

    var header = document.getElementById('site_head_wrap'),
        limit = header.offsetHeight,
        last_itm;

    $('.main_menu_itm').each(function () {

        var submenu = $(this).children('.submenu_wrapper');

        if (submenu.length > 0) {

            $(this).on('touchend', function (evt) {

                if (last_itm !== this) {

                    evt.preventDefault();

                    $(this).trigger('mouseenter');

                    last_itm = this;
                }
            });
        }

        var timer;
        var nbr = 0;

        $(this).not('#menu_roland-garros, #menu_soldes, #menu_summer-sales, #menu_sales, #menu_saldi-estivi, #menu_hot-sales, #menu_promociones, #menu_coups-de-coeur, .hasOnlyOneSubcatAndObject').on({
            mouseenter: function() {
                nbr++;
                if ($('#wrapper_top_menu').hasClass('hovering')) {
                    submenu.show()
                } else {
                    $('#wrapper_top_menu').addClass('hovering');
                    timer = setTimeout(function() {
                        $('#shade').stop(true, true).fadeIn(300);
                        submenu.fadeIn(300);
                    }, 300);
                }

                if (menuVisible !== submenu[0] && menuVisible) {
                    menuVisible.style.display = "none"
                }
                menuVisible = submenu[0]
                var target_push = $(this).find($('.push_rayon'))
                var target_push_length = target_push.length;

                if ($(this).hasClass('has_slider')) {
                    if (target_push_length > 3) {
                        setTimeout(function() {
                            var swiper_push_rayon = new Swiper(".menu_push_container", {
                                slidesPerView: 3,
                                loopedSlides: 0,
                                spaceBetween: 60,
                                scrollbar: {
                                    el: '.swiper-scrollbar',
                                },
                            });
                        }, 500);
                    }
                }
            },
        });

        $('#wrapper_top_menu').on('mouseleave', function() {
            clearTimeout(timer);
            submenu.hide();
            $('#shade').stop(true, true).fadeOut(300);
            $(this).removeClass('hovering');
        });
    });

    var provenance = '',
        sous_provenance = '';

    if (typeof $('#newsletter_email').attr('data-provenance') != 'undefined')
        provenance = $('#newsletter_email').attr('data-provenance');

    if (typeof $('#newsletter_email').attr('data-sousprovenance') != 'undefined')
        sous_provenance = $('#newsletter_email').attr('data-sousprovenance');

	$('input#newsletter_home').click(function() {

		$.ajax({
            url : path_relative_root + 'ajax_newsletter_email.php',
            type : 'post',
            data : {
                email : $('#newsletter_email').val(),
                provenance : provenance,
                sous_provenance : sous_provenance
            },
            cache : false,
            success : function (res) {
                var resultat = JSON.parse(res);
                if (resultat.valid == false) {
                    $('#newsletter_email').addClass('inputErr');
                    $('div.media_news p#infos').addClass('labelErr');
                    $('div.media_news p#infos').css('margin', '0px !important');

                } else {
                    $('#newsletter_email').removeClass('inputErr');
                    $('div.media_news p#infos').removeClass('labelErr');
                }

                $('div.media_news p#infos').text(Translator.translate(resultat.message));
            }
        });
	});

    $(window).on('scroll', function () {
        var scrollTop = $(document).scrollTop();
        if (header.classList.contains('sticky')) {
            if (scrollTop <= 0) {
                header.classList.remove('on');
                setTimeout(function () {
                    header.classList.remove('sticky');
                    $('body').removeClass('sticky_h');
                }, 50);
            }
        } else {
            if (scrollTop >= 10) {
                header.classList.add('sticky');
                $('body').addClass('sticky_h');
                setTimeout(function () {
                    header.classList.add('on')
                }, 50);
            }
        }
    });

    if ($('.search_gondole_swiper').length) {

        var search_gondole = new Swiper('.search_gondole_swiper', {
            slidesPerView: 4,
            // centeredSlides: true,
            navigation: {
                nextEl: '.gondole-next',
                prevEl: '.gondole-prev',
            },
        });
    }


    /** OUR CHOICE HOME MODULE SLIDER LOGIC ********************************************************************/
    /***********************************************************************************************************/
    var choices_sldr = $('.our_choice_container');

    if (choices_sldr) {

        if (choices_sldr.length > 1) {
            choices_sldr.each(function() {
                var current_swiper = $(this);
                setTimeout(function(){
                    // Set two variables here to access them later
                    var to_replace_index_visible,
                        to_keep_index_visible,
                        interval_counter = 0;

                    // Repeat the code at every specific interval
                    setInterval(function () {
                        if ($('.item_tg', current_swiper).length) {
                            // Set slides / visible slides == 4 / not_visible_slides == the rest
                            var slides = $('.item_tg', current_swiper),
                                visible_slides = slides.slice(0, 4),
                                not_visible_slides = slides.slice(4);

                            var to_replace_pic,
                                replacing_pic;

                            // Get a random item in the visible slides to replace
                            to_replace_index_visible = Math.floor(Math.random() * visible_slides.length);
                            to_replace_pic = visible_slides[to_replace_index_visible];
                            if (interval_counter != 0) {
                                // If the global index we store in the variable == the random index settled here
                                while (to_replace_index_visible == to_keep_index_visible) {
                                    // Redefine new pic to replace
                                    to_replace_index_visible = Math.floor(Math.random() * visible_slides.length);
                                    to_replace_pic = visible_slides[Math.floor(Math.random() * visible_slides.length)];
                                }
                            }

                            // Get a random item in the not_visible_slides array
                            replacing_pic = not_visible_slides[Math.floor(Math.random() * not_visible_slides.length)];

                            // Add classes
                            !$(to_replace_pic).hasClass('replacing') ? $(to_replace_pic).addClass('replacing') : null;
                            !$(replacing_pic).hasClass('replacing') ? $(replacing_pic).addClass('replacing') : null;

                            // Swap the two items
                            swap(to_replace_pic, replacing_pic);

                            // Once the swap is done store active to_replace_pic index for next interval + remove classes
                            to_keep_index_visible = to_replace_index_visible;

                            setTimeout(function() {
                                $(to_replace_pic).hasClass('replacing') ? $(to_replace_pic).removeClass('replacing') : null;
                                $(replacing_pic).hasClass('replacing') ? $(replacing_pic).removeClass('replacing') : null;
                            }, 300)
                        }

                        interval_counter++
                    }, 4000);
                }, 1000);
            });
        }
    }

    /***********************************************************************************************************/
});

function hideBrand(params) {
    const brand = document.querySelector("#menu_1 > a > h2 > span");
}


// Swap function used in "our choice" home module logic
function swap(node1, node2) {
    const afterNode2 = node2.nextElementSibling;
    const parent = node2.parentNode;
    node1.replaceWith(node2);
    parent.insertBefore(node1, afterNode2);
}